
import React, { useState, useEffect } from 'react'
import './NodeEditable.css'
import RemoveCircleOutlineTwoToneIcon from '@material-ui/icons/RemoveCircleOutline'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Select from '@material-ui/core/Select'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import { isEmpty } from '../utils'
import useStyles from './EditStyles'


const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const getStyles = (item, items, theme) => ({
  fontWeight:
      items.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
})

const DeviceTypes = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const {
    selectedDeviceTypes,
    deviceTypes,
    handleAddDeviceType,
    handleChangeDeviceTypes,
    handleRemoveDeviceType,
    readOnly,
  } = props
  const [editDeviceTypes, setEditDeviceTypes] = useState(false)
  const [newDeviceTypeValue, setNewDeviceTypeValue] = useState('')
  const [devicesSelected, setDeviceTypesSelected] = useState([])
  const [allDeviceTypes, setAllDeviceTypes] = useState([])

  const addDevice = () => {
    if (!allDeviceTypes.includes(newDeviceTypeValue)) {
      setAllDeviceTypes([...allDeviceTypes, newDeviceTypeValue])
      setNewDeviceTypeValue('')
      // Add to model
      handleAddDeviceType(newDeviceTypeValue)
    }
  }

  const handleMultiDeviceChange = (event) => {
    setDeviceTypesSelected(event.target.value)
    handleChangeDeviceTypes(event.target.value)
  }

  const removeDevice = (device) => {
    if (allDeviceTypes.includes(device)) {
      const index = allDeviceTypes.indexOf(device)
      // Remove from selectable items
      setAllDeviceTypes([
        ...allDeviceTypes.slice(0, index),
        ...allDeviceTypes.slice(index + 1),
      ])
      // Remove from selected items
      if (devicesSelected.includes(device)) {
        const indexInSelected = devicesSelected.indexOf(device)
        setDeviceTypesSelected([
          ...devicesSelected.slice(0, indexInSelected),
          ...devicesSelected.slice(indexInSelected + 1),
        ])
        console.log(devicesSelected)
      }
      // Remove from model
      handleRemoveDeviceType(device)
    }
  }

  useEffect(() => {
    if (!isEmpty(selectedDeviceTypes)) {
      setDeviceTypesSelected(selectedDeviceTypes)
    }
  }, [selectedDeviceTypes])

  useEffect(() => {
    if (!isEmpty(deviceTypes)) {
      setAllDeviceTypes(deviceTypes)
    }
  }, [deviceTypes])

  return (
    <div className={classes.editArea}>
      <FormControl component="fieldset">
        {editDeviceTypes && (
          <Link
            component="button"
            variant="body2"
            key="device-label"
            onClick={(event) => setEditDeviceTypes(false)}
          >
            <ExpandLessTwoToneIcon className="EditableItem-Icon EditableItem-Link-Icon" />
            {t('Edit DeviceTypes')}

          </Link>
        )}
        {editDeviceTypes && (
          <List key="device-edit-list" component="div" disablePadding className={classes.list}>
            {
              allDeviceTypes.map((device) => (
                <ListItem button key={`list-item-${device}`} className={classes.item}>
                  {device}
                  <IconButton
                    className={classes.iconButton}
                    aria-label="Remove"
                    onClick={() => removeDevice(device)}
                  >
                    <RemoveCircleOutlineTwoToneIcon className="EditableItem-Icon" />
                  </IconButton>
                </ListItem>
              ))
            }
          </List>
        )}
        {editDeviceTypes && (
          <Paper component="form" onSubmit={addDevice} className={classes.inlineListButtton}>
            <Input
              inputProps={{ 'aria-label': 'Name' }}
              // className="EditableItem-Text"
              onChange={(e) => { setNewDeviceTypeValue(e.target.value) }}
              value={newDeviceTypeValue}
            />
            <Button
              type="submit"
              // className="EditableItem-Button EditableItem-Button_save"
              onClick={addDevice}
              variant="contained"
              color="secondary"
              size="small"
              disabled={isEmpty(newDeviceTypeValue)}
              className={classes.button}
            >
              {` ${t('Add')}`}
            </Button>
          </Paper>
        )}


        {(!editDeviceTypes && !readOnly) && (
          <Link
            component="button"
            variant="body2"
            key="device-label"
            onClick={(event) => setEditDeviceTypes(true)}
          >
            <EditTwoToneIcon className="EditableItem-Icon EditableItem-Link-Icon" />
            {t('DeviceTypes')}

          </Link>
        )}
        {(!editDeviceTypes && readOnly) && (
          <Typography className={classes.infoOn}>
            {t('DeviceTypes')}
          </Typography>
        )}
        {!editDeviceTypes && (
          <FormGroup key="device-group" row>
            <Select
              disabled={readOnly}
              labelId="devices"
              id="devices-chip"
              multiple
              value={devicesSelected}
              onChange={handleMultiDeviceChange}
              inputProps={{
                id: 'select-multiple-native',
              }}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected && selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              { allDeviceTypes.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, allDeviceTypes, theme)}>
                  {name}
                </MenuItem>
              ))}


            </Select>
          </FormGroup>
        )}
      </FormControl>

    </div>
  )
}

export default DeviceTypes
