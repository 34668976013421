import axios from "axios";
import { showError } from "./notifications";
import { isEmpty } from "../utils";

const reuestTimeout = 30000;
const sitesURL = `${process.env.REACT_APP_DIRECTORY_URL}/directory/sites`;

const connectUrl = `${process.env.REACT_APP_DIRECTORY_URL}/`;
const getAllsURL = (siteId) =>
  `${process.env.REACT_APP_DIRECTORY_URL}/directory/site/${siteId}/*`;
const getApartmentsURL = (siteId, level = 8) =>
  `${process.env.REACT_APP_DIRECTORY_URL}/directory/site/${siteId}/building/*/area/*`;
const getDevicesURL = (siteId, level = 10) =>
  `${process.env.REACT_APP_DIRECTORY_URL}/directory/site/${siteId}/building/*/device/*`;
const getOwnermappingURL = (siteId, path) =>
  `${process.env.REACT_APP_DIRECTORY_URL}/directory/device/site/${siteId}/hub/claim${path}`;
const getOwnerunmappingURL = (siteId, path, deviceId) =>
  `${process.env.REACT_APP_DIRECTORY_URL}/directory/site/${siteId}/${path}/device/${deviceId}`;
const grantURL = (siteId, hubId) =>
  `${process.env.REACT_APP_DIRECTORY_URL}/directory/device/site/${siteId}/hub/${hubId}/grant`;

let fetchedSites = [];
let fetchedHubs = [];
let fetchedHubsInfo = [];

async function getReq(token, url) {
  let retVal;
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: reuestTimeout,
    };
    try {
      const response = await axios.get(url, config);
      if (response && response.data) {
        retVal = response.data ? response.data.data : [];
      }
    } catch (err) {
      debugger;
      console.error("Error: Location or Network issues.", err);
      showError({ message: "Location fetch error" });
    }
  }
  return retVal;
}
async function getAllOfSite(token, locationId) {
  let retVal = [];
  retVal = await getReq(token, getAllsURL(locationId));
  return retVal;
}
async function getApartments(token, locationId) {
  let retVal = [];
  retVal = await getReq(token, getApartmentsURL(locationId));
  return retVal;
}
async function getDevices(token, locationId) {
  let retVal = [];
  retVal = await getReq(token, getDevicesURL(locationId, 10));
  return retVal;
}
async function getSites(token) {
  let retVal;
  if (token) {
    if (isEmpty(fetchedSites)) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: reuestTimeout,
      };

      try {
        const response = await axios.get(sitesURL, config);
        if (response && response.data) {
          retVal = fetchedSites = response.data ? response.data.data : [];

          /*
          const promises = sites.map(async (site) => {
            const data = await getSite(token, getBuildingsURL(site.id))
            return data
          })
          const data = await Promise.all(promises)

          debugger

          data.map((entry) => {
            switch (entry.type) {
            case 'building':
              return ({
                siteId: site.id,
                siteDescription: site.description,
                siteName: site.name,
                buildingId: entry.id,
                buildingDescription: entry.description,
                buildingPath: entry.path,
              })
              break
            default:
              console.error('unknown type')
            }
          }) */
        }
      } catch (err) {
        debugger;
        console.error("Error: Location or Network issues.", err);
        showError({ message: "Location fetch error" });
      }
    } else {
      retVal = [...fetchedSites];
    }
  }
  return retVal;
}

async function locationHubs(token) {
  let retVal = [];
  if (token) {
    if (isEmpty(fetchedHubs)) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: reuestTimeout,
        params: {},
      };
      try {
        const response = await axios.get(connectUrl.concat("hubs"), config);
        if (response && response.data) {
          retVal = response.data.hubDevices || [];
          fetchedHubs = [...retVal];
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Location hubs failed with error: '${err.message}' `);
      }
    } else {
      retVal = [...fetchedHubs];
    }
  }
  return retVal;
}
async function snToId(token, sn) {
  let retVal = null;
  if (token) {
    const config = {
      method: "GET",
      url: connectUrl.concat("cozify/hubs"),
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: reuestTimeout,
      params: {
        serialNo: sn,
      },
    };
    try {
      const response = await axios(config);
      if (response && response.data && response.data[0]) {
        retVal = response.data[0].hubId;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        `Serial number to id mapping failed with error: '${err.message}' `
      );
    }
  }
  return retVal;
}

async function hubsInfo(token) {
  let retVal = null;
  if (token) {
    if (isEmpty(fetchedHubsInfo)) {
      const config = {
        method: "GET",
        url: connectUrl.concat("cozify/hubs"),
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: reuestTimeout,
        params: {
          count: 1000,
        },
      };
      try {
        const response = await axios(config);
        if (response && response.data && response.data[0]) {
          retVal = response.data;
          fetchedHubsInfo = [...retVal];
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`HubInfo request failed with error: '${err.message}' `);
      }
    } else {
      retVal = [...fetchedHubsInfo];
    }
  }
  return retVal;
}

async function doOwnerMapping({ token, job, sn }) {
  let retVal = null;
  const URL = getOwnermappingURL(job.siteId, job.id);
  if (token) {
    const config = {
      method: "POST",
      url: URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: reuestTimeout,
      data: {
        name: `COZIFY HUB ${sn}`,
        serialNumber: sn
      },
    };

    try {
      console.info("doOwnerMapping config", config);
      const response = await axios(config);
      console.info("doOwnerMapping response", response);
      if (response && response.status === 201) {
        retVal = true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Owner mapping failed with error: '${err.message}' `);
    }
  }
  return retVal;
}
async function doOwnerUnMapping({ token, job }) {
  let retVal = null;
  if (job.hubId) {
    const URL = getOwnerunmappingURL(job.siteId, job.id, job.hubId);

    const config = {
      method: "DELETE",
      url: URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: reuestTimeout,
    };
    try {
      console.info("doOwnerUnMapping config", config);
      const response = await axios(config);
      console.info("doOwnerUnMapping reply", response);
      if (response && response.status === 204) {
        retVal = true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Owner unmapping failed with error: '${err.message}' `);
    }
  }
  return retVal;
}

async function addAdminAccess({ token, job }) {
  let retVal = null;
  if (job.hubId) {
    const URL = grantURL(job.siteId, job.hubId);
    const config = {
      method: "PUT",
      url: URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json;charset=UTF-8",
      },
      timeout: reuestTimeout,
      data: null,
    };
    try {
      console.info("addAdminAccess config", config);
      const response = await axios(config);
      console.info("addAdminAccess reply", response);
      if (response && response.status === 204) {
        retVal = true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Adding admin access failed with error: '${err.message}' `);
    }
  }
  return retVal;
}

async function removeAdminAccess({ token, job }) {
  let retVal = null;
  if (job.hubId) {
    const URL = grantURL(job.siteId, job.hubId);
    const config = {
      method: "DELETE",
      url: URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: reuestTimeout,
    };
    try {
      console.info("removeAdminAccess config", config);
      const response = await axios(config);
      console.info("removeAdminAccess reply", response);
      if (response && response.status === 204) {
        retVal = true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Admin access remove failed with error: '${err.message}' `);
    }
  }
  return retVal;
}

async function getLatestMeasurements(token, ids, job) {
  let retVal;
  if (token) {
    // TODO: Fix hardcoded /cc/1.14/
    const measurementUrl = `${process.env.REACT_APP_DIRECTORY_URL}/directory/device/site/${job.siteId}/remote/cc/1.14/hub/latest-measurement`;
    const config = {
      method: "POST",
      url: measurementUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: reuestTimeout,
      data: {
        deviceIds: ids,
      },
    };

    try {
      const response = await axios(config);
      if (response && response.data) {
        retVal = response.data;
      }
    } catch (err) {
      console.error("Error: Measurement issue.", err);
      // TODO remove comments showError({ message: 'Measurements fetch error' })
    }
  }
  return retVal;
}

export default {
  getSites,
  getAllOfSite,
  getApartments,
  getDevices,
  snToId,
  hubsInfo,
  locationHubs,
  doOwnerMapping,
  doOwnerUnMapping,
  addAdminAccess,
  removeAdminAccess,
  getLatestMeasurements,
};
