import appStore from '../appStore'

import { setUserAction, removeUserAction } from '../reducers/userReducer'


const removeUser = () => {
  appStore.dispatch(removeUserAction())
}


const setUser = (data) => {
  if (data.access_token) {
    // console.info(`Changing Cozify user state ${getUserState()}`)
    // changeLanguage(LANGUAGES.FI_FI)
    // const cozifyToken = await doPwLogin(cozyCredentials)
    // acceptEula()
    appStore.dispatch(setUserAction(data))
  }
}


export { setUser, removeUser }
