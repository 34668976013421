import { Log, UserManager } from 'oidc-client'
import { askConfirmation } from './notifications'

const {
  REACT_APP_COZIFY_AA_ISSUER,
  REACT_APP_COZIFY_AA_CLIENID,
  REACT_APP_COZIFY_AA_CLIENT_ROOT,
  REACT_APP_COZIFY_AA_SCOPE,
} = process.env;

const getParsedJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return undefined
  }
}


export default class AuthService {
  constructor() {
    const settings = {
      authority: REACT_APP_COZIFY_AA_ISSUER,
      client_id: REACT_APP_COZIFY_AA_CLIENID,
      redirect_uri: REACT_APP_COZIFY_AA_CLIENT_ROOT,
      silent_redirect_uri: REACT_APP_COZIFY_AA_CLIENT_ROOT,
      post_logout_redirect_uri: REACT_APP_COZIFY_AA_CLIENT_ROOT,
      response_type: 'code',
      scope: REACT_APP_COZIFY_AA_SCOPE,
    }
    this.userManager = new UserManager(settings)
    this.userManager.events.addAccessTokenExpiring(() => {
      console.log('Token expiring...')
    })
    this.userManager.events.addAccessTokenExpired(() => {
      console.log('Token expired...')
    })
    this.userManager.events.addUserSignedIn(() => {
      console.log('IN')
    })

    Log.logger = console
    Log.level = Log.INFO
  }

  getSigningExpired(callback) {
    // debugger
    if (callback) {
      this.userManager.events.addAccessTokenExpired(callback)
    }
  }

  getSigningExpiring(callback) {
    // debugger
    if (callback) {
      this.userManager.events.addAccessTokenExpiring(callback)
    }
  }

  addUserLoaded(updateUserData) {
    if (updateUserData) {
      this.userManager.events.addUserLoaded(updateUserData)
    }
  }

  removeUserLoaded(updateUserData) {
    if (updateUserData) {
      this.userManager.events.removeUserLoaded(updateUserData)
    }
  }

  async getUser() {
    // debugger
    return this.userManager.getUser()
  }

  async removeUser() {
    return this.userManager.removeUser()
  }

  async badLogin() {
    debugger
    await this.userManager.removeUser()
    askConfirmation({
      message: 'Login role failure',
      cancel: async () => { await this.userManager.signoutRedirect() },
      ok: async () => { await this.userManager.signoutRedirect() },
      timeout: -1,
    })
    return undefined
  }

  async signinCallback() {
    return new Promise((resolve, reject) => {
      this.userManager.signinCallback().then(async (user) => {
        if (user.access_token) {
          const token = getParsedJwt(user.access_token)
          if (token.roles.includes('installer')) {
            resolve(user)
          } else {
            this.badLogin()
          }
        } else {
          this.badogin()
        }
      }).catch((e) => {
        console.error('signinCallback failed ', e)
        throw(e)
        //this.badLogin()
      })
    })
  }

  async signinPopup() {
    //!
    return this.userManager.signinPopup()
  }

  async signinPopupCallback() {
    //!
    return this.userManager.signinPopupCallback()
  }

  async signinRedirect(args) {
    return this.userManager.signinRedirect(args)
  }

  async login(args) {
    return this.userManager.signinRedirect(args)
  }

  async renewToken() {
    this.userManager.signinSilent()
    return this.userManager.getUser()
  }

  async logout() {
    return this.userManager.signoutRedirect()
  }
}
