
import React, { useState, useEffect } from 'react'
import './NodeEditable.css'
import RemoveCircleOutlineTwoToneIcon from '@material-ui/icons/RemoveCircleOutline'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Select from '@material-ui/core/Select'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import { isEmpty } from '../utils'
import useStyles from './EditStyles'


const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const getStyles = (item, items, theme) => ({
  fontWeight:
      items.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
})

const Rooms = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const {
    selectedRooms,
    roomNames,
    handleAddRoomName,
    handleRemoveRoomName,
    readOnly,
    handleChangeRooms,
  } = props
  const [editRooms, setEditRooms] = useState(false)
  const [newRoomNameValue, setNewRoomNameValue] = useState('')
  const [roomsSelected, setRoomsSelected] = useState([])
  const [allRoomNames, setAllRoomNames] = useState([])

  const addRoom = () => {
    if (!allRoomNames.includes(newRoomNameValue)) {
      setAllRoomNames([...allRoomNames, newRoomNameValue])
      setNewRoomNameValue('')
      // Add to model
      handleAddRoomName(newRoomNameValue)
    }
  }

  const handleMultiRoomChange = (event) => {
    setRoomsSelected(event.target.value)
    handleChangeRooms(event.target.value)
  }

  const removeRoom = (room) => {
    if (allRoomNames.includes(room)) {
      const index = allRoomNames.indexOf(room)
      // Remove from selectable items
      setAllRoomNames([
        ...allRoomNames.slice(0, index),
        ...allRoomNames.slice(index + 1),
      ])
      // Remove from selected items
      if (roomsSelected.includes(room)) {
        const indexInSelected = roomsSelected.indexOf(room)
        setRoomsSelected([
          ...roomsSelected.slice(0, indexInSelected),
          ...roomsSelected.slice(indexInSelected + 1),
        ])
        console.log(roomsSelected)
      }
      // Remove from model
      handleRemoveRoomName(room)
    }
  }

  useEffect(() => {
    setRoomsSelected(selectedRooms)
  }, [selectedRooms])

  useEffect(() => {
    if (!isEmpty(roomNames)) {
      setAllRoomNames(roomNames)
    }
  }, [roomNames])

  return (
    <div className={classes.editArea}>
      <FormControl component="fieldset">
        {editRooms && (
          <Link
            component="button"
            variant="body2"
            key="room-label"
            onClick={(event) => setEditRooms(false)}
          >
            <ExpandLessTwoToneIcon className="EditableItem-Icon EditableItem-Link-Icon" />
            {t('Edit Rooms')}

          </Link>
        )}
        {editRooms && (
          <List key="room-edit-list" component="div" disablePadding className={classes.list}>
            {
              allRoomNames.map((room) => (
                <ListItem button key={`list-item-${room}`} className={classes.item}>
                  {room}
                  <IconButton
                    className={classes.iconButton}
                    aria-label="Remove"
                    onClick={() => removeRoom(room)}
                  >
                    <RemoveCircleOutlineTwoToneIcon className="EditableItem-Icon" />
                  </IconButton>
                </ListItem>
              ))
            }
          </List>
        )}
        {editRooms && (
          <Paper component="form" onSubmit={addRoom} className={classes.inlineListButtton}>
            <Input
              inputProps={{ 'aria-label': 'Name' }}
              // className="EditableItem-Text"
              onChange={(e) => { setNewRoomNameValue(e.target.value) }}
              value={newRoomNameValue}
            />
            <Button
              type="submit"
              // className="EditableItem-Button EditableItem-Button_save"
              onClick={addRoom}
              variant="contained"
              color="secondary"
              size="small"
              disabled={isEmpty(newRoomNameValue)}
              className={classes.button}
            >
              {` ${t('Add')}`}
            </Button>
          </Paper>
        )}


        {(!editRooms && !readOnly) && (
          <Link
            component="button"
            variant="body2"
            key="room-label"
            onClick={(event) => setEditRooms(true)}
          >
            <EditTwoToneIcon className="EditableItem-Icon EditableItem-Link-Icon" />
            {t('Rooms')}

          </Link>
        )}
        {(!editRooms && readOnly) && (
          <Typography className={classes.infoOn}>
            {t('Rooms')}
          </Typography>
        )}
        {!editRooms && (
          <FormGroup key="room-group" row>
            <Select
              disabled={readOnly}
              labelId="rooms"
              id="rooms-chip"
              multiple
              value={roomsSelected}
              onChange={handleMultiRoomChange}
              inputProps={{
                id: 'select-multiple-native',
              }}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected && selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              { allRoomNames.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, allRoomNames, theme)}>
                  {name}
                </MenuItem>
              ))}


            </Select>
          </FormGroup>
        )}
      </FormControl>

    </div>
  )
}

export default Rooms
