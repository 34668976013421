import React from 'react'
import { connect } from 'react-redux'
import './NodeEditable.css'
import RemoveCircleOutlineTwoToneIcon from '@material-ui/icons/RemoveCircleOutline'
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/TextField'
// import Button from '@material-ui/core/Button'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
import AddLocationTwoToneIcon from '@material-ui/icons/AddLocationTwoTone'
import RouterTwoToneIcon from '@material-ui/icons/RouterTwoTone'
// import { useTranslation } from 'react-i18next'
import { isEmpty } from '../utils'
import Rooms from './Rooms'
import DeviceTypes from './DeviceTypes'
import SceneTypes from './SceneTypes'
import RuleTypes from './RuleTypes'

const NodeEditable = (props) => {
  const {
    title,
    open,
    changeTitle,
    removeNode,
    addChild,
    parentId,
    toggle,
    childIds,
    nodeType,
    handleChangeRooms,
    handleChangeDeviceTypes,
    handleChangeSceneTypes,
    handleChangeRuleTypes,
    selectedRooms,
    selectedDeviceTypes,
    selectedSceneTypes,
    selectedRuleTypes,
    roomNames,
    deviceTypes,
    sceneTypes,
    ruleTypes,
    handleAddRoomName,
    handleAddDeviceType,
    handleAddSceneType,
    handleAddRuleType,
    handleRemoveRoomName,
    handleRemoveDeviceType,
    handleRemoveSceneType,
    handleRemoveRuleType,
  } = props

  // if (nodeType === 'HUB') debugger

  // const { t } = useTranslation()
  const [inputValue, setInputValue] = React.useState(title)

  return (
    <div className="EditableItem">
      {(!isEmpty(childIds) || nodeType === 'HUB')
      && (
        <IconButton
          aria-label="Expand"
          className="EditableItem-IconButton"
          onClick={toggle}
        >
          {!open && (
            <ExpandMoreTwoToneIcon className="EditableItem-Icon" />
          )}
          {open && (
            <ExpandLessTwoToneIcon className="EditableItem-Icon" />
          )}
        </IconButton>
      )}
      {isEmpty(childIds)
      && (
        <IconButton
          aria-label="Expand"
          className="EditableItem-IconButton"
        >
          {nodeType === 'HUB' && (
            <RouterTwoToneIcon className="EditableItem-Icon" />
          )}
          {nodeType !== 'HUB' && (
            <AddLocationTwoToneIcon className="EditableItem-Icon" />
          )}
        </IconButton>
      )}
      <Input
        // onClick={toggle}
        inputProps={{ 'aria-label': 'Name', style: { fontSize: 14 } }}
        className="EditableItem-Text"
        onChange={(e) => { setInputValue(e.target.value) }}
        onBlur={() => { changeTitle(inputValue) }}
        value={inputValue}
      />

      <IconButton
        aria-label="Add"
        className="EditableItem-IconButton"
        onClick={addChild}
      >
        <AddCircleOutlineTwoToneIcon className="EditableItem-Icon" />
      </IconButton>


      {typeof parentId !== 'undefined' && (

        <IconButton
          aria-label="Remove"
          className="EditableItem-IconButton "
          onClick={removeNode}
        >
          <RemoveCircleOutlineTwoToneIcon className="EditableItem-Icon EditableItem-Icon_remove" />
        </IconButton>
      )}
      {nodeType === 'HUB' && open && (
        <div>
          <Rooms
            selectedRooms={selectedRooms}
            roomNames={roomNames}
            handleAddRoomName={handleAddRoomName}
            handleChangeRooms={handleChangeRooms}
            handleRemoveRoomName={handleRemoveRoomName}
          />
          <DeviceTypes
            selectedDeviceTypes={selectedDeviceTypes}
            deviceTypes={deviceTypes}
            handleAddDeviceType={handleAddDeviceType}
            handleChangeDeviceTypes={handleChangeDeviceTypes}
            handleRemoveDeviceType={handleRemoveDeviceType}
          />

          <SceneTypes
            selectedSceneTypes={selectedSceneTypes}
            sceneTypes={sceneTypes}
            handleAddSceneType={handleAddSceneType}
            handleChangeSceneTypes={handleChangeSceneTypes}
            handleRemoveSceneType={handleRemoveSceneType}
          />

          <RuleTypes
            selectedRuleTypes={selectedRuleTypes}
            ruleTypes={ruleTypes}
            handleAddRuleType={handleAddRuleType}
            handleChangeRuleTypes={handleChangeRuleTypes}
            handleRemoveRuleType={handleRemoveRuleType}
          />
        </div>
      ) }

    </div>
  )
}

const mapStateToProps = (state) => ({
  // templates: Object.values(state.plans.templates),
  roomNames: state.plans.roomNames,
  deviceTypes: state.plans.deviceTypes,
  sceneTypes: state.plans.sceneTypes,
  ruleTypes: state.plans.ruleTypes,
})


const mapDispatchToProps = {
}


const ConnectedNodeEditable = connect(mapStateToProps, mapDispatchToProps)(NodeEditable)

export default ConnectedNodeEditable
