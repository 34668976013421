import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import SwapHorizTwoToneIcon from '@material-ui/icons/SwapHorizTwoTone'
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone'
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone'
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone'
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone'

import {
  setDeviceMeta,
  identifyDevice,
  sendDeviceStateCmd,
  ignorePairingByIds,
  stopPairingById,
  // startPairingById,
  setDeviceVisibility, setDeviceLocked,
  setDeviceHotWater,
} from 'cozify-sdk'
import { showError, showInfo, askConfirmation } from '../services/notifications'

import { isEmpty, pick } from '../utils'
// import locationService from '../services/locations'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    flexGrow: 1,
    width: '100%',
  },
  infoHeader: {
    margin: theme.spacing(1),
  },
  infoHeaderOn: {
    margin: theme.spacing(1),
    color: 'green',
  },
  info: {
    margin: theme.spacing(1),
  },
  infoOn: {
    margin: theme.spacing(1),
    color: 'green',
  },
  infoOff: {
    margin: theme.spacing(1),
    color: 'red',
  },
  icontext: {
    fontSize: '13px',
  },
  alert: {
    margin: theme.spacing(1),
    color: 'red',
  },
  input: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  flexRow: {
    flexDirection: 'column',
  },
  row: {
    flexBasis: '100%',
  },
  progress: {
    margin: theme.spacing(2),
  },
  checkbox: {
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
  formlabel: {
    fontSize: '13px',
  },
}))


export default function DeviceForm(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    hubId, device, rooms, locale, pairing,
    hubMeasurements,
    handleStopPairing,
    handleDeviceUnPair,
  } = props


  const [selectedRoom, setSelectedRoom] = useState('')
  const [selectableRooms, setSelectableRooms] = useState()
  const [deviceName, setDeviceName] = useState('')
  const [visibilityState, setVisibilityState] = useState(false)
  const [lockedState, setLockedState] = useState(false)
  const [inProgess, setInProgess] = useState(false)
  const [isIdentifiable, setIsIdentifiable] = useState(false)
  const [isTestable, setIsTestable] = useState(false)
  const [measurements, setMeasurements] = useState([])

  // Run once
  useEffect(() => {
    /*
    if (device.state.type === 'STATE_LIGHT') {
      debugger
    } */
  }, [])

  /*
  useEffect(() => {
    if (device.state.type === 'STATE_RELAY') {
      debugger
    }
  })
  */

  useEffect(() => {
    if (pairing && device.type === 'POWER_SOCKET' && !device.state.reachable) { debugger }

    if (device.capabilities && device.capabilities.values && device.capabilities.values.indexOf('IDENTIFY') !== -1) {
      setIsIdentifiable(true)
    }
    if (['LIGHT'].indexOf(device.type) !== -1) {
      setIsTestable(false) // Newer show as long dimmers dosn't work well
    }
    if (device && device.name && !isEmpty(device.name)) {
      setDeviceName(device.name)
    }
    if (device && device.room && !isEmpty(device.room)) {
      setSelectedRoom(device.room[0])
    }

    if (device.rwx >= 256) {
      setVisibilityState(true)
    } else {
      setVisibilityState(false)
    }
    // if (256 > device.rwx >= 128) or device.rwx >= 384
    if ((device.rwx >= 128 && device.rwx < 256) || device.rwx >= 384) {
      setLockedState(false)
    } else {
      setLockedState(true)
    }
    console.info(`Device RWX ${device.name} rwx: ${device.rwx} visible: ${device.rwx >= 256} locked: ${!((device.rwx >= 128 && device.rwx < 256) || device.rwx >= 384)}`)
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(device)
  ])

  useEffect(() => {
    if (!isEmpty(hubMeasurements) && isEmpty(measurements)) {
      const meas = hubMeasurements.find((measurement) => measurement.deviceId === device.id)
      if (meas && meas.measurementData) {
        const measures = pick(meas.measurementData, [
          'total_hot_water_consumption',
          'total_cold_water_consumption',
          // 'volumeYesterday',
          // 'volumeToday',
          // 'hotWater',
          'flow',
          // 'flowTemp',
          'temperature',
          // 'powerYesterday',
          // 'powerToday',
          'totalPower',
          'activePower',
          'brightness',
        ])
        if (!isEmpty(measures)) {
          setMeasurements(Object.entries(measures))
        }
      }
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(hubMeasurements),
    // eslint-disable-next-line
    JSON.stringify(measurements),
    device.id,
  ])

  // Run when rooms changed
  useEffect(() => {
    if (!isEmpty(rooms)) {
      const roomArr = Object.values(rooms).sort((a, b) => {
        const x = a.name.toLowerCase()
        const y = b.name.toLowerCase()
        // eslint-disable-next-line no-nested-ternary
        return x < y ? -1 : x > y ? 1 : 0
      })
      setSelectableRooms(roomArr)
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(rooms),
  ])


  const handleSave = async (event) => {
    if (device.added) {
      handleStopPairing()
    }
    if (event) event.preventDefault()
    let room = selectedRoom
    if (room === '') room = undefined
    try {
      await setDeviceMeta(hubId, device.id, deviceName, [room])
      showInfo({ message: 'Saving done' })
    } catch (error) {
      showError({ message: 'Saving failed' })
    }
  }

  const handleRoomChange = (event) => {
    if (event !== '' && rooms[event.target.value]) {
      setSelectedRoom(rooms[event.target.value].id)
    } else {
      setSelectedRoom('')
    }
  }

  const handleNameChange = (event) => {
    setDeviceName(event.target.value)
  }

  const handleSetVisibility = async (state) => {
    try {
      await setDeviceVisibility(hubId, device.id, state)
      setVisibilityState(state)
      // showInfo({ message: 'Change done' })
    } catch (e) {
      showError({ message: 'Visibility change failed' })
    }
  }

  const handleSetLock = async (state) => {
    try {
      await setDeviceLocked(hubId, device.id, state)
      setLockedState(state)
      // showInfo({ message: 'Change done' })
    } catch (e) {
      showError({ message: 'Lock change failed' })
    }
  }


  const handleSetHotWater = async (state) => {
    try {
      await setDeviceHotWater(hubId, device.id, state)
      showInfo({ message: 'Change done' })
    } catch (e) {
      showError({ message: 'Water change failed' })
    }
  }
  /*
  const makePoll = async () => {
    try {
      await doPoll(hubId, true)
    } catch (error) {
      showError({ message: 'Hub polling failed' })
      console.error(error)
    }
  }
  */
  /*
  const restartPairing = async () => {
    try {
      if (!inProgess) {
        setInProgess(true)
        await startPairingById(hubId, true)
      }
      console.log('Pairing restarted')
    } catch (error) {
      setInProgess(false)
      if (error.message !== 'pairing already in action') {
        showError({ message: 'Discovery restart failed' })
      }
      console.error(error)
    }
  }
  */


  const handleDeviceTestedFailure = async () => {
    console.log('handle device tested failure! ')
  }

  const handleDeviceTestedSuccess = async () => {
    console.log('handle device tested success! ')
  }


  const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const doDeviceIdentify = async () => {
    try {
      if (device.capabilities.values.indexOf('ON_OFF') !== -1) {
        const initialState = { ...device.state }

        const zeroState = { ...device.state }
        if (device.capabilities.values.indexOf('BRIGHTNESS') !== -1) {
          zeroState.isOn = false
        } else {
          zeroState.isOn = false
        }

        const firstState = { ...device.state }
        if (device.capabilities.values.indexOf('BRIGHTNESS') !== -1) {
          firstState.isOn = true
          firstState.brightness = 0.1
        } else {
          firstState.isOn = true
        }

        const secondState = { ...device.state }
        if (device.capabilities.values.indexOf('BRIGHTNESS') !== -1) {
          secondState.isOn = true
          secondState.brightness = 0.9
        } else {
          secondState.isOn = false
        }

        const thirdState = { ...device.state }
        if (device.capabilities.values.indexOf('BRIGHTNESS') !== -1) {
          thirdState.isOn = true
          thirdState.brightness = 0.5
        } else {
          thirdState.isOn = true
        }

        const fourthState = { ...device.state }
        if (device.capabilities.values.indexOf('BRIGHTNESS') !== -1) {
          fourthState.brightness = initialState.brightness
          fourthState.isOn = initialState.isOn
        } else {
          fourthState.isOn = initialState.isOn
        }

        await sendDeviceStateCmd(hubId, device.id, zeroState)
        await sleep(1000)
        await sendDeviceStateCmd(hubId, device.id, firstState)
        await sleep(1000)
        await sendDeviceStateCmd(hubId, device.id, secondState)
        await sleep(1000)
        await sendDeviceStateCmd(hubId, device.id, thirdState)
        await sleep(1000)
        await sendDeviceStateCmd(hubId, device.id, fourthState)
      } else {
        await identifyDevice(hubId, device.id)
      }
      return true
    } catch (error) {
      showError({ message: 'Device can not be identified' })
      return false
    }
  }
  const handleDeviceTest = async () => {
    if (isIdentifiable) {
      doDeviceIdentify()
      askConfirmation({
        message: 'Is device turning ON/OFF?',
        cancel: handleDeviceTestedFailure,
        ok: handleDeviceTestedSuccess,
        cancelText: 'No',
        okText: 'Yes',
        timeout: -1,
      })
    }
  }

  const handleDeviceToggle = async () => {
    if (!pairing) {
      const newState = { ...device.state }
      newState.isOn = !newState.isOn
      await sendDeviceStateCmd(hubId, device.id, newState)
    } else {
      showError({ message: 'Device can not set ON/OFF during pairing' })
    }
  }

  const handleDevicePairing = async () => {
    setInProgess(true)
    /*
    if (device.capabilities && device.capabilities.values && ((device.capabilities.values.indexOf('IDENTIFY') !== -1) || (device.capabilities.values.indexOf('ON_OFF') !== -1))) {
      const identified = doDeviceIdentify()
      if (!identified) {
        setInProgess(false)
        return
      }
    }
     */
    let room = selectedRoom
    if (room === '') {
      room = undefined
    }
    if (!room) {
      room = []
    } else {
      room = [room]
    }

    try {
      await setDeviceMeta(hubId, device.id, deviceName, room)
      await ignorePairingByIds(hubId, device.id, false)
      showInfo({ message: 'Paired', timeout: 10000 })
      // setTimeout(restartPairing, 500)
    } catch (error) {
      showError({ message: 'Pairing failed' })
    }
    // makePoll()
    // restartPairing()
    try {
      await stopPairingById(hubId)
      await handleStopPairing()
    } catch (error) {
      showError({ message: 'Pairing failed' })
    }
    setInProgess(false)
  }

  const formatDate = (ms) => {
    const [date, time] = new Date(ms).toLocaleString(locale).split(', ')
    if (date && time) return `${date} ${time}`
    if (date) return `${date}`
    return `${time}`
  }

  return (
    <form key={device.id} onSubmit={handleSave} className={classes.form} noValidate autoComplete="off">
      <Grid container>
        <Grid item xs={12}>

          <Typography className={device.state.isOn === true ? classes.infoHeaderOn : classes.infoHeader} variant="subtitle2" gutterBottom>
            {device.manufacturer && (
              <span>{`${device.manufacturer}`}</span>
            )}
            {!device.manufacturer && (
              <span>{`${t('Unknown manufacturer')}`}</span>
            )}
            {device.model && (
              <span>{`${device.model}`}</span>
            )}
            {device.state.reachable && (device.state.isOn === true) && (
              <Button className={device.state.isOn === true ? classes.infoHeaderOn : classes.infoHeader} onClick={() => handleDeviceToggle()}>{` (${t('ON')})`}</Button>
            )}
            {device.state.reachable && (device.state.isOn === false) && (
              <Button className={device.state.isOn === true ? classes.infoHeaderOn : classes.infoHeader} onClick={() => handleDeviceToggle()}>{` (${t('OFF')})`}</Button>
            )}
          </Typography>

          {device.state.reachable && (
            <Typography className={classes.infoOn}>
              {`${t('Reachable')}`}
            </Typography>
          )}

          {!device.state.reachable && (
            <Typography className={classes.infoOff}>
              {`${t('Unreachable')}`}
            </Typography>
          )}

          {!pairing && device.state && (device.type === 'WATER_METER' && device.state.hotWater === true) && (
            <Typography className={classes.info}>
              {`${t('Hot water')}`}
              <IconButton aria-label="delete" onClick={() => handleSetHotWater(false)}>
                <SwapHorizTwoToneIcon />
              </IconButton>
            </Typography>
          )}
          {!pairing && device.state && (device.type === 'WATER_METER' && (!device.state.hotWater || device.state.hotWater === false)) && (
            <Typography className={classes.info}>
              {`${t('Cold water')}`}
              <IconButton aria-label="delete" onClick={() => handleSetHotWater(true)}>
                <SwapHorizTwoToneIcon />
              </IconButton>
            </Typography>
          )}
          {!pairing && device.modbusInfo && device.modbusInfo.address && (
            <Typography className={classes.info}>
              {`${t('Modbus address')}: ${device.modbusInfo.address}`}
            </Typography>
          )}

          {!pairing && device.modbusInfo && device.modbusInfo.register && (
            <Typography className={classes.info}>
              {`${t('Modbus register')}: ${device.modbusInfo.register}`}
            </Typography>
          )}

          {!pairing && device.modbusInfo && device.modbusInfo.registerType && (
            <Typography className={classes.info}>
              {device.modbusInfo.registerType === 1 && (
                <span>
                  {`${t('Discretes Input')}`}
                </span>
              )}
              {device.modbusInfo.registerType === 2 && (
                <span>
                  {`${t('Coil')}`}
                </span>
              )}
              {device.modbusInfo.registerType === 3 && (
                <span>
                  {`${t('Input register')}`}
                </span>
              )}
              {device.modbusInfo.registerType === 4 && (
                <span>
                  {`${t('Holding register')}`}
                </span>
              )}
            </Typography>
          )}

          {!pairing && device.modbusInfo && device.modbusInfo.normallyOff === true && (
            <Typography className={classes.info}>
              {`${t('Modbus Normally Off')}`}
            </Typography>
          )}
          {!pairing && device.modbusInfo && device.modbusInfo.normallyOff === false && (
            <Typography className={classes.info}>
              {`${t('Modbus Reversed Off')}`}
            </Typography>
          )}

          {device.state.reachable && device.state.brightness && (
            <Typography className={classes.info}>
              {`${t('Brightness')}: ${Math.floor(device.state.brightness * 100)}%`}
            </Typography>
          )}

          {device.state.reachable
            && device.capabilities
            && device.capabilities.values
            && (device.capabilities.values.indexOf('TEMPERATURE') !== -1)
            && device.state.temperature && (
            <Typography className={classes.info}>
              {`${t('Temperature')}: ${device.state.temperature.toFixed(1)}°C`}
            </Typography>
          )}

          {device.state.reachable
            && Number.isFinite(device.state.flow)
            && device.state.hotWater === true && (
            <Typography className={classes.info}>
              {`${t('Flow')} (${t('hot water')}): ${device.state.flow}l/h`}
            </Typography>
          )}

          {device.state.reachable
            && Number.isFinite(device.state.flow)
            && (!device.state.hotWater || device.state.hotWater === false) && (
            <Typography className={classes.info}>
              {`${t('Flow')} (${t('cold water')}): ${device.state.flow.toFixed(2)}l/h`}
            </Typography>
          )}

          {device.state.reachable && Number.isFinite(device.state.flowTemp) && (
            <Typography className={classes.info}>
              {`${t('Water temperature')}: ${device.state.flowTemp.toFixed(1)}°C`}
            </Typography>
          )}

          {device.state.reachable && Number.isFinite(device.state.volumeToday) && (
            <Typography className={classes.info}>
              {`${t('Volume today')}: ${device.state.volumeToday.toFixed(2)}l`}
            </Typography>
          )}

          {device.state.reachable && Number.isFinite(device.state.volumeYesterday) && (
            <Typography className={classes.info}>
              {`${t('Volume yesterday')}: ${device.state.volumeYesterday.toFixed(2)}l`}
            </Typography>
          )}

          {device.state.reachable && Number.isFinite(device.state.volume) && (
            <Typography className={classes.info}>
              {`${t('Volume')}: ${device.state.volume.toFixed(2)}l`}
            </Typography>
          )}

          {device.state.reachable && Number.isFinite(device.state.humidity) && (
            <Typography className={classes.info}>
              {`${t('Humidity')}: ${device.state.humidity.toFixed(1)}%`}
            </Typography>
          )}

          {device.state.reachable && Number.isFinite(device.state.activePower) && (
            <Typography className={classes.info}>
              {`${t('Active power')}: ${device.state.activePower.toFixed(2)}W`}
            </Typography>
          )}

          {device.state.reachable && Number.isFinite(device.state.powerToday) && (
            <Typography className={classes.info}>
              {`${t('Power today')}: ${device.state.powerToday.toFixed(2)}kWh`}
            </Typography>
          )}
          {device.state.reachable && Number.isFinite(device.state.powerYesterday) && (
            <Typography className={classes.info}>
              {`${t('Power yesterday')}: ${device.state.powerYesterday.toFixed(2)}kWh`}
            </Typography>
          )}

          {device.state.reachable && Number.isFinite(device.state.totalPower) && (
            <Typography className={classes.info}>
              {`${t('Total Power')}: ${device.state.totalPower.toFixed(2)}kWh`}
            </Typography>
          )}

          {device.state.reachable && device.state.open === true && (
            <Typography className={classes.alert}>
              {`${t('Open')}`}
            </Typography>
          )}

          {device.state.reachable && device.state.open === false && (
            <Typography className={classes.info}>
              {`${t('Closed')}`}
            </Typography>
          )}

          {device.state.reachable && device.state.motion && (
            <Typography className={classes.alert}>
              {`${t('Motion')}: ${device.state.motion ? t('Yes') : t('No')}`}
            </Typography>
          )}

          {device.state.reachable && device.state.lastMotion && (
            <Typography className={classes.info} variant="body2">
              {`${t('Last motion seen')}: ${formatDate(device.state.lastMotion)}`}
            </Typography>
          )}

          {/* device.state.reachable && device.state.alert && (
            <Typography className={classes.info}>
              {`${t('Siren')}: ${device.state.sirenOn ? t('Yes') : t('No')}`}
            </Typography>
          ) */}

          {device.state.reachable && device.state.alert && (
            <Typography className={classes.alert}>
              {`${t('Alert')}: ${device.state.alert ? t('Yes') : t('No')}`}
            </Typography>
          )}

          {device.state.reachable && device.state.alertAt && (
            <Typography className={classes.info} variant="body2">
              {`${t('Last alert seen')}: ${formatDate(device.state.alertAt)}`}
            </Typography>
          )}

          {device.state.reachable && device.state.moisture && (
            <Typography className={classes.alert}>
              {`${t('Moisture')}: ${device.state.moisture ? t('Yes') : t('No')}`}
            </Typography>
          )}

          {device.state.reachable && device.state.moistureAt && (
            <Typography className={classes.info} variant="body2">
              {`${t('Last moisture seen')}: ${formatDate(device.state.moistureAt)}`}
            </Typography>
          )}

          {device.state.reachable && device.state.batteryLow && (
            <Typography className={classes.alert}>
              {`${t('Battery low')}: ${device.state.batteryLow ? t('Yes') : t('No')}`}
            </Typography>
          )}

          {device.state.reachable && device.state.batteryV && (
            <Typography className={classes.info} variant="body2">
              {`${t('Battery level')}: ${device.state.batteryV} V`}
            </Typography>
          )}

          {device.pairing && device.pairing.actionRequired && (
            <Typography className={classes.alert}>
              {`${t('Pairing action')}: ${device.pairing.actionRequired}`}
            </Typography>
          )}

          {device.pairing && device.pairing.pairingStatus && (
            <Typography className={classes.infoOn}>
              {`${t('Pairing status')}: ${device.pairing.pairingStatus}`}
            </Typography>
          )}

          {device.pairing && device.pairing.info && (
            <Typography className={classes.info}>
              {`${t('Pairing info')}: ${device.pairing.info}`}
            </Typography>
          )}

          { !isEmpty(measurements) && (
            <Typography className={classes.info} variant="body2">
              {`${t('Last measurements')}: `}
              { measurements.map((entry) => {
                if (entry[1]) {
                  return (
                    <span key={entry[0]}>{`${entry[0]}: ${entry[1][entry[0]]}`}</span>
                  )
                }
                return null
              })}
            </Typography>
          )}

          {device.state.lastSeen && (
            <Typography className={classes.info} variant="body2">
              {`${t('Last seen')}: ${formatDate(device.state.lastSeen)}`}
            </Typography>
          )}
          {device.state.lastChange && (
            <Typography className={classes.info} variant="body2">
              {`${t('Last change')}: ${formatDate(device.state.lastChange)}`}
            </Typography>
          )}

          {inProgess && <CircularProgress className={classes.progress} />}

        </Grid>


        {!inProgess && (
          <Grid item xs={12}>
            <TextField
              id={`devicename-${device.id}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              onChange={handleNameChange}
              type="text"
              autoComplete={`devicename${device.id}`}
              autoFocus
              required
              value={deviceName || ''}
              placeholder={t('Name')}
              className={classes.input}
              inputProps={{
                'aria-label': t('Name'),
              }}
            />
          </Grid>
        )}
        {!inProgess && (
          <Grid item xs={12}>
            <Select
              className={classes.input}
              value={selectedRoom || ''}
              onChange={handleRoomChange}
              displayEmpty
              inputProps={{
                name: `room-${device.id}`,
                id: `room-helper-${device.id}`,
                'aria-label': t('Room'),
              }}
            >
              <MenuItem value="">
                <em>{t('No specific room')}</em>
              </MenuItem>
              {selectableRooms && selectableRooms.map((room) => (
                <MenuItem key={`room-menu-${device.id}-${room.id}`} value={room.id}>{room.name}</MenuItem>
              ))}

            </Select>
          </Grid>
        )}
        {/* !inProgess && (
          <FormControlLabel
            control={(
              <Checkbox
                checked={visibilityState}
                onChange={handleVisibilityChange}
                value={device.deviceType}
                color="primary"
                className={classes.checkbox}
              />
            )}
            classes={{ label: classes.formlabel }}
            label={t('Show device to other users')}
          />
         ) */}

        {!inProgess && (
          <Typography className={classes.icontext}>

            <IconButton aria-label="hide" color={visibilityState ? 'default' : 'primary'} onClick={() => handleSetVisibility(false)}>
              <VisibilityOffTwoToneIcon />
            </IconButton>
            <IconButton aria-label="unlock" color={visibilityState ? 'primary' : 'default'} onClick={() => handleSetVisibility(true)}>
              <VisibilityTwoToneIcon />
            </IconButton>
            {visibilityState ? `${t('Show device to other users')}` : `${t('Hide device from other users')}`}
          </Typography>
        )}

        {/* !inProgess && (
          <FormControlLabel
            control={(
              <Checkbox
                checked={lockedState}
                onChange={handleLockChange}
                value={device.deviceType}
                color="primary"
                className={classes.checkbox}
              />
            )}
            classes={{ label: classes.formlabel }}
            label={t('Lock device from other users')}
          />
        ) */}

        {!inProgess && (
          <Typography className={classes.icontext}>

            <IconButton aria-label="lock" color={lockedState ? 'primary' : 'default'} onClick={() => handleSetLock(true)}>
              <LockTwoToneIcon />
            </IconButton>
            <IconButton aria-label="unlock" color={lockedState ? 'default' : 'primary'} onClick={() => handleSetLock(false)}>
              <LockOpenTwoToneIcon />
            </IconButton>
            {lockedState ? `${t('Device locked from other users')}` : `${t('Device not locked from other users')}`}

          </Typography>
        )}

        {!inProgess && (
          <Grid item xs={12}>
            {!device.pairing && (
              <Button size="small" color="primary" type="submit" variant="contained" className={classes.button}>{t('Save')}</Button>
            )}


            {device.pairing && (
              <Button size="small" color="primary" type="button" disabled={!pairing || !device.state.reachable} variant="contained" onClick={() => handleDevicePairing()} className={classes.button}>
                {t('Pair')}
              </Button>
            )}

            {isTestable && (
              <Button size="small" color="primary" type="button" disabled={!isTestable || !device.state.reachable} variant="contained" onClick={() => handleDeviceTest()} className={classes.button}>
                {t('Test')}
              </Button>
            )}

            {!device.pairing && (
              <Button size="small" color="secondary" type="button" disabled={pairing} variant="contained" onClick={() => handleDeviceUnPair(device)} className={classes.button}>
                {t('Unpair')}
              </Button>
            )}

          </Grid>
        )}
      </Grid>
    </form>
  )
}

DeviceForm.propTypes = {
  device: PropTypes.any.isRequired,
}
