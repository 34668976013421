import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
import ArrowForwardIosTwoTone from '@material-ui/icons/ArrowForwardIosTwoTone'
import CircularProgress from '@material-ui/core/CircularProgress'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { isEmpty } from '../utils'
import { useLocalStorage } from '../hooks'
import locationService from '../services/locations'
import { showError } from '../services/notifications'

const useStyles = makeStyles((theme) => ({
  '@media print': {
    noPrint: {
      display: 'none !important',
    },
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  list: {
    width: '100%',
    minHeight: 120,
  },
  header: {
    color: theme.palette.text.secondary,
  },
  progress: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
  },
}))


const Locations = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    user,
    building,
    showLocationList,
    setShowLocationList,
    selectBuilding,
    updateJobs,
    setReloadJobs,
    reloadJobs,
  } = props

  const [jobs, setJobs] = useState([])
  // eslint-disable-next-line
  const [locations, setLocations] = useState([])
  const [name, setName] = useState('')
  const [storedLocation, setStoredLocation] = useLocalStorage('location')
  const [loadingHubs, setLoadingHubs] = useState(false)

  const selectLocation = useCallback(async (selectedLocation) => {
    if (!storedLocation || (selectedLocation.id !== storedLocation.id)) {
      setStoredLocation(selectedLocation)
    }


    // const all = await locationService.getAllOfSite(user.access_token, selectedLocation.id)
    const apartments = await locationService.getApartments(user.access_token, selectedLocation.id)
    // const devices = await locationService.getDevices(user.access_token, selectedLocation.id)
    const apartmentsMap = {}
    if (!isEmpty(apartments)) {
      apartments.map((apartment) => {
        if (apartment.type === 'area') {
          const apartmentPathParts = apartment.path.split('/')
          let number = '?'
          let stair = ''
          let floor = ''
          let area

          for (let i = 1; i < apartmentPathParts.length; i += 2) {
            switch (apartmentPathParts[i]) {
            case 'building':
              number = apartmentPathParts[i + 1]
              break
            case 'stair':
              stair = apartmentPathParts[i + 1]
              break
            case 'floor':
              floor = apartmentPathParts[i + 1]
              break
            case 'area':
              area = apartmentPathParts[i + 1]
              break
            default:
              console.debug('part not handled: ', apartmentPathParts[i])
            }
          }

          const job = {
            id: apartment.path,
            location: {
              number,
              stair,
              floor,
              area,
            },
            siteId: selectedLocation.id,
            site: selectedLocation,
            hubId: undefined,
            hub: {},
          }
          apartmentsMap[apartment.path] = job
        }
        return true
      })
    }
    const devices = apartments
    if (!isEmpty(devices)) {
      devices.map((device) => {
        if (device.type === 'device') {
          if (device.attributes && (device.attributes.DeviceType === 'COZIFY_HUB' || device.attributes.deviceType === 'COZIFY_HUB')) {
            const devicePathParts = device.path.split('/')
            let deviceId = device.attributes ? (device.attributes.deviceId || device.attributes.DeviceId) : undefined;
            if (!deviceId) {
              for (let i = 1; i < devicePathParts.length; i += 2) {
                switch (devicePathParts[i]) {
                case 'device':
                  deviceId = devicePathParts[i + 1]
                  break
                default:
                  // debugger
                }
              }
            }
            const apartmentId = devicePathParts.slice(0, devicePathParts.length - 2).join('/')
            if (apartmentId && apartmentsMap[apartmentId]) {
              if (apartmentId === '/building/1/stair/a/floor/1/area/aula') debugger
              apartmentsMap[apartmentId].hubId = deviceId
              apartmentsMap[apartmentId].hub = {
                id: deviceId,
                keys: {
                  cozifyCloudToken: `Bearer ${user.access_token}`,
                  cozifyHubKey: null,
                },
                name: 'Cozify Hub',
                serialNo: undefined,
                desiredVersionId: '',
                currentVersionId: undefined,
                status: 'claimed',
                ownerEmail: undefined,
                features: [],
              }
            }
          }
        }
        return true
      })
    }
    const allJobs = Object.values(apartmentsMap)
    setJobs(allJobs)
    updateJobs(allJobs)
    if (!building || building !== selectedLocation.id) {
      setName(selectedLocation.displayName)
      selectBuilding(selectedLocation.id, allJobs)
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(jobs),
    // eslint-disable-next-line
    JSON.stringify(building),
    // eslint-disable-next-line
    JSON.stringify(storedLocation),
    setStoredLocation,
    selectBuilding,
  ])


  useEffect(() => {
    console.log('Locations EFFECT locations reloadJobs?', reloadJobs)
    console.log('Locations EFFECT locations isEmpty(jobs)?', isEmpty(jobs))

    const fetchLocations = async () => {
      if (user.access_token) {
        if (isEmpty(jobs) || reloadJobs) {
          // All jobs
          setLoadingHubs(true)
          let fetchedSites = []
          fetchedSites = await locationService.getSites(user.access_token)
          if (isEmpty(fetchedSites)) {
            showError({ message: 'No locations, check your useraccount' })
          }         
          if (isEmpty(locations)) {
            setLocations(fetchedSites)
          }
          setLoadingHubs(false)
          console.log('Locations:', fetchedSites)
        }
      }
      setReloadJobs(false)
    }
    if (!loadingHubs) {
      if (isEmpty(jobs) || reloadJobs) {
        fetchLocations()
      }
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(user),
    // eslint-disable-next-line
    JSON.stringify(jobs),
    // eslint-disable-next-line
    JSON.stringify(locations),
    updateJobs, setReloadJobs, reloadJobs,
  ])


  useEffect(() => {
    if (locations) {
      console.log('Locations EFFECT cookie: ', storedLocation)
      const cookieLocation = locations.filter((location) => {
        if (storedLocation && location.id === storedLocation.id) {
        // if (storedLocation) {
          return true
        }
        return false
      })
      if (cookieLocation && cookieLocation[0]) {
        selectLocation(cookieLocation[0])
      }
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(locations),
    // eslint-disable-next-line
    JSON.stringify(storedLocation),
    // selectLocation,
  ])

  const Row = ({ index, style }) => {
    if (locations && locations.length > 0) {
      const location = locations[index]
      return (
        <ListItem
          selected={building === `${location.id})`}
          button
          key={index}
          style={style}
          onClick={() => selectLocation(locations[index], jobs)}
        >
          <ListItemIcon>
            <HomeTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary={`${location.displayName}`} />
          <ListItemIcon>
            <ArrowForwardIosTwoTone style={{ fontSize: 12 }} />
          </ListItemIcon>
        </ListItem>
      )
    }

    return null
  }

  Row.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  }

  return (
    <Box my={1} mx={2}>
      <Grid
        container
        className={classes.header}
        justify="center"
        alignItems="center"
        onClick={() => setShowLocationList(!showLocationList)}
      >
        <Grid item xs={11}>
          <Typography variant="h6" component="h4" gutterBottom>
            {`${t('Address')}: ${name}`}
            {loadingHubs
              && (
                <CircularProgress
                  className={classes.progress}
                  disableShrink
                  size={16}
                  thickness={4}
                  color="primary"
                  variant="indeterminate"
                />
              )}
          </Typography>
        </Grid>
        <Grid item xs={1} align="right">
          {!showLocationList && (<ExpandMoreTwoToneIcon />)}
          {showLocationList && (<ExpandLessTwoToneIcon />)}
        </Grid>


      </Grid>

      {locations && locations.length > 0 && showLocationList
      && (
        <Paper className={classes.root}>
          <AutoSizer className={classes.list} defaultHeight="500">
            {({ height, width }) => (
              <FixedSizeList
                className="List"
                height={height}
                itemCount={locations.length}
                itemSize={50}
                width={width}
              >
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Paper>

      )}
    </Box>
  )
}

Locations.propTypes = {
  selectBuilding: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const ConnectedLocations = connect(mapStateToProps, mapDispatchToProps)(Locations)

export default ConnectedLocations
