import React from 'react'
// import { useTranslation } from 'react-i18next'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'

import NodeEditable from './NodeEditable'
import useStyles from './EditStyles'
import './NodeEditable.css'
// import { setLocationNode } from 'cozify-sdk'
// import planningService from '../services/planning'

import { isEmpty } from '../utils'

const Node = (props) => {
  // const { t } = useTranslation()
  const classes = useStyles()
  const {
    opened,
    handleOpenAddDialog,
    handleCancelAddDialog,
    handleAddChild,
    handleChangeNodeData,
    handleRemove,
    toggle,
    addToParentId,
    id,
    parentId,
    child,
    // rootId,
    data,
    nodes,
    handleAddRoomName,
    handleRemoveRoomName,
    handleAddDeviceType,
    handleRemoveDeviceType,
    handleAddSceneType,
    handleRemoveSceneType,
    handleAddRuleType,
    handleRemoveRuleType,
  } = props

  console.log(`Nodes ${JSON.stringify(nodes)}`)
  console.log(`Node ${id}`)
  console.log(`Node data ${data}`)


  const handleChangeDataName = async (id, newData) => {
    handleChangeNodeData(id, 'name', newData)
  }
  const handleChangeHubRooms = async (id, newData) => {
    handleChangeNodeData(id, 'rooms', newData)
  }
  const handleChangeHubDevices = async (id, newData) => {
    handleChangeNodeData(id, 'deviceTypes', newData)
  }
  const handleChangeHubScenes = async (id, newData) => {
    handleChangeNodeData(id, 'sceneTypes', newData)
  }
  const handleChangeHubRules = async (id, newData) => {
    handleChangeNodeData(id, 'ruleTypes', newData)
  }
  const renderChild = (childId) => {
    console.log(`Opened ${opened}`)
    console.log(`childId ${childId}`)
    if (isEmpty(nodes)) {
      return null
    }
    const currentNode = nodes[childId]
    const open = true // opened.includes(id)
    const retVal = (
      <Collapse key={`collapse-${childId}`} in={open} timeout="auto" unmountOnExit>
        <List key={`list-${childId}`} component="div" disablePadding className={classes.child}>
          <ListItem button key={`list-item-${childId}`} className={classes.nested}>
            <Node
              opened={opened}
              handleOpenAddDialog={handleOpenAddDialog}
              handleCancelAddDialog={handleCancelAddDialog}
              handleAddChild={handleAddChild}
              handleRemove={handleRemove}
              toggle={toggle}
              nodes={nodes}
              data={currentNode}
              child={currentNode.child}
              id={childId}
              parentId={id}
              addToParentId={addToParentId}
              handleChangeNodeData={handleChangeNodeData}
              handleAddRoomName={handleAddRoomName}
              handleRemoveRoomName={handleRemoveRoomName}
              handleAddDeviceType={handleAddDeviceType}
              handleRemoveDeviceType={handleRemoveDeviceType}
              handleAddSceneType={handleAddSceneType}
              handleRemoveSceneType={handleRemoveSceneType}
              handleAddRuleType={handleAddRuleType}
              handleRemoveRuleType={handleRemoveRuleType}
              className={classes.fullWidth}
              key={`child-node-${childId}`}
            />
          </ListItem>
        </List>
      </Collapse>
    )
    return retVal
  }
  //

  return (
    <div className={classes.fullWidth}>

      {data
      && (
        <Collapse key={`collapse-${id}`} in timeout="auto" unmountOnExit>
          <List key={`list-${id}`} component="div" disablePadding className={classes.child}>
            <ListItem button key={`list-item-${id}`} className={classes.nested}>
              <NodeEditable
                title={data.name || '?'}
                toggle={() => toggle(id)}
                open={opened.includes(id)}
                child={child}
                addChild={() => handleOpenAddDialog(id)}
                removeNode={() => { handleRemove(id) }}
                changeTitle={(newName) => { handleChangeDataName(id, newName) }}
                parentId={parentId}
                nodeType={data.type}
                selectedRooms={data.rooms || []}
                handleAddRoomName={handleAddRoomName}
                handleChangeRooms={(rooms) => { handleChangeHubRooms(id, rooms) }}
                handleRemoveRoomName={handleRemoveRoomName}
                selectedDeviceTypes={data.deviceTypes || []}
                handleAddDeviceType={handleAddDeviceType}
                handleChangeDeviceTypes={(devices) => { handleChangeHubDevices(id, devices) }}
                handleRemoveDeviceType={handleRemoveDeviceType}
                selectedSceneTypes={data.sceneTypes || []}
                handleAddSceneType={handleAddSceneType}
                handleChangeSceneTypes={(scenes) => { handleChangeHubScenes(id, scenes) }}
                handleRemoveSceneType={handleRemoveSceneType}
                selectedRuleTypes={data.ruleTypes || []}
                handleAddRuleType={handleAddRuleType}
                handleChangeRuleTypes={(rules) => { handleChangeHubRules(id, rules) }}
                handleRemoveRuleType={handleRemoveRuleType}
              />
            </ListItem>
          </List>
        </Collapse>
      )}
      <List
        component="nav"
        aria-labelledby="tree"
        className={classes.tree}
      >
        {child && child.map(renderChild)}
      </List>


    </div>
  )
}
/*
const mapStateToProps = (state, ownProps) => {
  if (ownProps.id) {
    return state.plans.locations[ownProps.id]
  }
  return state.plans.locations.root
}


const mapDispatchToProps = {
}


const ConnectedNode = connect(mapStateToProps, mapDispatchToProps)(Node)
*/

export default Node
