/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import SwipeableViews from 'react-swipeable-views'
import DeviceForm from './DeviceForm'

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`device-tabpanel-${index}`}
      aria-labelledby={`device-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `device-tab-${index}`,
    'aria-controls': `device-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  tabPanel: {
    '& .div': {
      padding: 0,
    },
    padding: 0,
  },
  devicesContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    padding: theme.spacing(0),
  },
  deviceContainer: {
    padding: '4px',
    // padding: theme.spacing(1),
  },
}))

export default function DeviceTabs(props) {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const {
    label1,
    label2,
    devices,
    newAdded,
    pairingsFound,
    devicesFound,
    hubId,
    rooms,
    locale,
    token,
    visible,
    pairing,
    hubMeasurements,
    handleStopPairing,
    handleDeviceUnPair,
  } = props

  useEffect(() => {
    if (newAdded) {
      setValue(1)
    } else if (pairingsFound) {
      setValue(0)
    } else if (devicesFound) {
      setValue(1)
    }
  }, [newAdded, pairingsFound, devicesFound])

  const handleChange = (event, newValue) => {
    if (newValue < 2) {
      setValue(newValue)
    }
  }

  const handleChangeIndex = (index) => {
    if (index < 2) {
      setValue(index)
    }
  }

  // if (pairingsFound) debugger

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="paired and unpaired selection"
        >

          <Tab label={label1} {...a11yProps(0)} disabled={!pairingsFound} />
          <Tab label={label2} {...a11yProps(1)} disabled={!devicesFound && !newAdded} />

        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >

        <TabPanel key="unpaired" value={value} index={0} className={classes.tabPanel}>
          <div className={classes.devicesContainer}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              {devices.map((device) => {
                if (device.pairing) {
                  return (
                    <Grid item className={classes.deviceContainer} xs={12} sm={6} md={4} lg={3} key={`form-${device.id}`}>
                      <Paper>
                        <DeviceForm
                          hubId={hubId}
                          device={device}
                          visible={visible}
                          pairing={pairing}
                          rooms={rooms}
                          locale={locale}
                          token={token}
                          hubMeasurements={hubMeasurements}
                          handleStopPairing={handleStopPairing}
                          handleDeviceUnPair={handleDeviceUnPair}
                        />
                      </Paper>
                    </Grid>
                  )
                }
                return null
              })}
            </Grid>
          </div>
        </TabPanel>

        <TabPanel key="paired" value={value} index={1} className={classes.tabPanel}>
          <div className={classes.devicesContainer}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"

            >
              {devices.map((device) => {
                if (!device.pairing) {
                  return (
                    <Grid item className={classes.deviceContainer} xs={12} sm={6} md={4} lg={3} key={`form-${device.id}`}>
                      <Paper>
                        <DeviceForm
                          hubId={hubId}
                          device={device}
                          visible={visible}
                          pairing={pairing}
                          rooms={rooms}
                          locale={locale}
                          token={token}
                          hubMeasurements={hubMeasurements}
                          handleStopPairing={handleStopPairing}
                          handleDeviceUnPair={handleDeviceUnPair}
                        />
                      </Paper>
                    </Grid>
                  )
                }
                return null
              })}
            </Grid>
          </div>
        </TabPanel>

      </SwipeableViews>
    </div>
  )
}
