// eslint-disable-next-line import/no-extraneous-dependencies
import { addRoom, removeRoom } from 'cozify-sdk'

import { isEmpty } from '../utils'

export const ROOM_NAMES = [
  '$BEDROOM 1',
  '$BEDROOM 2',
  '$BEDROOM 3',
  '$BEDROOM 4',
  '$HALLWAY',
  '$LIVING ROOM',
  '$KITCHEN',
  '$UTILITY ROOM',
  '$SAUNA',
  '$WALK IN CLOSET',
  '$BALCONY',
  '$TOILET',
  '$BATHROOM',
]

export const setRooms = (hubId, devices, currentRooms, roomNames) => {
  const addedNames = []
  const removedNames = []

  // Make copy of room so that it includes devices property
  const allRooms = {}
  Object.values(currentRooms).map((room) => {
    const roomDevices = []
    Object.values(devices).map((device) => {
      if (device.room && device.room.indexOf(room.id) !== -1) {
        roomDevices.push(device.id)
      }
      return true
    })
    allRooms[room.id] = {...room}    
    allRooms[room.id].deviceIds = roomDevices
    return true
  })

  if (isEmpty(allRooms)) {
    return { addedNames, removedNames }
  }

  // Loop current rooms to Remove extras
  Object.values(allRooms).map((room) => {
    // If room name is not found
    if (roomNames.indexOf(room.name) === -1 && isEmpty(allRooms[room.id].deviceIds)) {
      removedNames.push(room.name)
      // remove it
      removeRoom(hubId, room)
        .then(() => { console.info('setRooms: removed room: ', room) })
        .catch(() => { console.error('setRooms: room remove failed for room: ', room) })
    }
    return true
  }) // end looping current rooms


  // Loop given room names to Add missing
  roomNames.forEach((name) => {
    let foundName = false
    // loop current rooms
    Object.values(allRooms).map((room) => {
      // If room name is found
      if (room.name === name) {
        foundName = true
      }
      return true
    }) // end looping current rooms

    // If given room name is not found
    if (!foundName) {
      addedNames.push(name)
      addRoom(hubId, { name })
        .then(() => { console.info('setRooms: added room name: ', name) })
        .catch(() => { console.error('setRooms: room add failed for room name: ', name) })
    }
  }) // end looping given room names
  return { addedNames, removedNames }
}
