import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import userReducer from './reducers/userReducer'
import notificationsReducer from './reducers/notificationsReducer'

const reducer = combineReducers({
  user: userReducer,
  notifications: notificationsReducer,
})

const appStore = createStore(reducer, applyMiddleware(thunk))

console.log('App store inital state', appStore.getState())

appStore.subscribe(() => {
  console.log('App store state', appStore.getState())
})


export default appStore
