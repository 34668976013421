
import React, { useState, useEffect } from 'react'
import './NodeEditable.css'
import RemoveCircleOutlineTwoToneIcon from '@material-ui/icons/RemoveCircleOutline'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Select from '@material-ui/core/Select'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import { isEmpty } from '../utils'
import useStyles from './EditStyles'


const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const getStyles = (item, items, theme) => ({
  fontWeight:
      items.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
})

const RuleTypes = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const {
    selectedRuleTypes,
    ruleTypes,
    handleAddRuleType,
    handleChangeRuleTypes,
    handleRemoveRuleType,
    readOnly,
  } = props
  const [editRuleTypes, setEditRuleTypes] = useState(false)
  const [newRuleTypeValue, setNewRuleTypeValue] = useState('')
  const [rulesSelected, setRuleTypesSelected] = useState([])
  const [allRuleTypes, setAllRuleTypes] = useState([])

  const addRule = () => {
    if (!allRuleTypes.includes(newRuleTypeValue)) {
      setAllRuleTypes([...allRuleTypes, newRuleTypeValue])
      setNewRuleTypeValue('')
      // Add to model
      handleAddRuleType(newRuleTypeValue)
    }
  }

  const handleMultiRuleChange = (event) => {
    setRuleTypesSelected(event.target.value)
    handleChangeRuleTypes(event.target.value)
  }

  const removeRule = (rule) => {
    if (allRuleTypes.includes(rule)) {
      const index = allRuleTypes.indexOf(rule)
      // Remove from selectable items
      setAllRuleTypes([
        ...allRuleTypes.slice(0, index),
        ...allRuleTypes.slice(index + 1),
      ])
      // Remove from selected items
      if (rulesSelected.includes(rule)) {
        const indexInSelected = rulesSelected.indexOf(rule)
        setRuleTypesSelected([
          ...rulesSelected.slice(0, indexInSelected),
          ...rulesSelected.slice(indexInSelected + 1),
        ])
        console.log(rulesSelected)
      }
      // Remove from model
      handleRemoveRuleType(rule)
    }
  }

  useEffect(() => {
    if (!isEmpty(selectedRuleTypes)) {
      setRuleTypesSelected(selectedRuleTypes)
    }
  }, [selectedRuleTypes])

  useEffect(() => {
    if (!isEmpty(ruleTypes)) {
      setAllRuleTypes(ruleTypes)
    }
  }, [ruleTypes])

  return (
    <div className={classes.editArea}>
      <FormControl component="fieldset">
        {editRuleTypes && (
          <Link
            component="button"
            variant="body2"
            key="rule-label"
            onClick={(event) => setEditRuleTypes(false)}
          >
            <ExpandLessTwoToneIcon className="EditableItem-Icon EditableItem-Link-Icon" />
            {t('Edit RuleTypes')}

          </Link>
        )}
        {editRuleTypes && (
          <List key="rule-edit-list" component="div" disablePadding className={classes.list}>
            {
              allRuleTypes.map((rule) => (
                <ListItem button key={`list-item-${rule}`} className={classes.item}>
                  {rule}
                  <IconButton
                    className={classes.iconButton}
                    aria-label="Remove"
                    onClick={() => removeRule(rule)}
                  >
                    <RemoveCircleOutlineTwoToneIcon className="EditableItem-Icon" />
                  </IconButton>
                </ListItem>
              ))
            }
          </List>
        )}
        {editRuleTypes && (
          <Paper component="form" onSubmit={addRule} className={classes.inlineListButtton}>
            <Input
              inputProps={{ 'aria-label': 'Name' }}
              // className="EditableItem-Text"
              onChange={(e) => { setNewRuleTypeValue(e.target.value) }}
              value={newRuleTypeValue}
            />
            <Button
              type="submit"
              // className="EditableItem-Button EditableItem-Button_save"
              onClick={addRule}
              variant="contained"
              color="secondary"
              size="small"
              disabled={isEmpty(newRuleTypeValue)}
              className={classes.button}
            >
              {` ${t('Add')}`}
            </Button>
          </Paper>
        )}


        {(!editRuleTypes && !readOnly) && (
          <Link
            component="button"
            variant="body2"
            key="rule-label"
            onClick={(event) => setEditRuleTypes(true)}
          >
            <EditTwoToneIcon className="EditableItem-Icon EditableItem-Link-Icon" />
            {t('RuleTypes')}

          </Link>
        )}
        {(!editRuleTypes && readOnly) && (
          <Typography className={classes.infoOn}>
            {t('RuleTypes')}
          </Typography>
        )}
        {!editRuleTypes && (
          <FormGroup key="rule-group" row>
            <Select
              disabled={readOnly}
              labelId="rules"
              id="rules-chip"
              multiple
              value={rulesSelected}
              onChange={handleMultiRuleChange}
              inputProps={{
                id: 'select-multiple-native',
              }}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected && selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              { allRuleTypes.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, allRuleTypes, theme)}>
                  {name}
                </MenuItem>
              ))}


            </Select>
          </FormGroup>
        )}
      </FormControl>

    </div>
  )
}

export default RuleTypes
