
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'
import './index.css'
import appStore from './appStore'
import App from './App'
import * as serviceWorker from './serviceWorker'


// Store console log lines
import { recordLogs } from './utils/log'

recordLogs(['error', 'warn', 'info', 'log'])

if (process.env.NODE_ENV === 'production') {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={appStore}>
        <App />
      </Provider>
    </ThemeProvider>,
    document.querySelector('#root'),
  )
} else {
  // ReactDOM
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={appStore}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </ThemeProvider>,
    document.querySelector('#root'),
  )
}

// appStore.subscribe(renderApp)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
