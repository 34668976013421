import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
import NotificationDialog from './NotificationDialog'

/*
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}))
*/
const Notification = (props) => {
  const { notifications } = props
  // const classes = useStyles()
  useEffect(() => {
    console.log('Notifications EFFECT')
  })
  const notificationDialogs = () => (
    <div>
      {notifications.map((notification) => (
        <NotificationDialog
          key={notification.id}
          message={notification.message}
          description={notification.description}
          ok={notification.ok}
          okText={notification.okText}
          cancel={notification.cancel}
          cancelText={notification.cancelText}
          type={notification.type}
        />
      ))}
    </div>
  )

  if (notifications && notifications.length > 0) {
    return notificationDialogs()
  }
  return null
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
})


Notification.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const ConnectedNotification = connect(mapStateToProps, mapDispatchToProps)(Notification)
export default ConnectedNotification
