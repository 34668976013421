import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { BrowserBarcodeReader } from '@zxing/library'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { showError } from '../services/notifications'


const reader = new BrowserBarcodeReader()
console.debug(reader)

const useStyles = makeStyles((theme) => ({
  '@media print': {
    noPrint: {
      display: 'none !important',
    },
  },
  rootContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflowX: 'auto',
  },
  video: {
    // border: '1px solid gray',
    // display: 'flex',
    maxWidth: '600px',
    width: '100%',
    height: '100%',
  },
  videoContainer: {
    textAlign: 'center',
  },
  scanButton: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

}))


const BarcodeReader = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [reading, setReading] = useState()
  const [deviceId, setDeviceId] = useState()
  const [codeReader, setCodeReader] = useState()

  const {
    setBarcode,
  } = props


  useEffect(() => {
    console.log('Scanner EFFECT')
    // new window.ZXing.BrowserBarcodeReader()
    // const reader = new window.ZXing.BrowserMultiFormatReader()

    setCodeReader(reader)
    reader.getVideoInputDevices()
      .then((videoInputDevices) => {
        videoInputDevices.forEach((device) => {
          console.log(`${device.label}, ${device.deviceId}`)
        })
        if (videoInputDevices[1] && videoInputDevices[1].deviceId) {
          setDeviceId(videoInputDevices[1].deviceId)
        } else if (videoInputDevices[0] && videoInputDevices[0].deviceId) {
          setDeviceId(videoInputDevices[0].deviceId)
        }
      })
  }, [])


  const reset = () => {
    setBarcode('')
    codeReader.reset()
    setReading(false)
  }
  const handleError = (err) => {
    showError({ message: 'Barcode reader failed', err })
    setBarcode('')
    codeReader.reset()
    setReading(false)
  }
  const start = () => {
    // codeReader.decodeOnceFromVideoDevice(deviceId, 'video').then((result) => {
    // codeReader.decodeFromInputVideoDevice
    // codeReader.decodeFromVideoDevice(deviceId, 'video', (result, err) => {
    codeReader.decodeOnceFromVideoDevice(deviceId, 'video').then((result) => {
      if (result) {
        setBarcode(result.text)
        codeReader.reset()
        setReading(false)
        /*
        if (result.text && result.text.split('-').length === 3) {
          setBarcode(result.text)
          codeReader.reset()
          setReading(false)
        } else {
          handleError('wrong code format')
        }
        */
      } else {
        handleError('no result')
      }
    }).catch((err) => {
      debugger
      if (err.message !== 'Video stream has ended before any code could be detected.') {
        handleError(err)
        console.error(err)
      }
    })
    setReading(true)
    console.log(`Started continous decode from camera with id ${deviceId}`)
  }

  return (
    <div className="rootContainer">
      <Grid container spacing={1} direction="row" justify="center">
        <Grid item xs={6}>
          { !reading
          && (
            <Button
              size="small"
              onClick={() => start()}
              color="primary"
              type="button"
              variant="contained"
              className={classes.scanButton}
            >
              {t('Read barcode')}
            </Button>
          )}
          { reading
          && (
            <Button
              size="small"
              onClick={() => reset()}
              color="primary"
              type="button"
              variant="contained"
              className={classes.scanButton}
            >
              {t('Stop reading')}
            </Button>
          )}
        </Grid>
      </Grid>
      { reading && (
        <Grid container spacing={1} direction="row" justify="center" className={classes.videoContainer}>
          <Grid item xs={12}>
            <video
              id="video"
              className={classes.video}
            />
          </Grid>
        </Grid>
      )}

    </div>
  )
}

export default BarcodeReader
