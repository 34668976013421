import React, { useState, useEffect } from 'react'
import {
  useSelector, useDispatch,
} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import { 
  ArrowForwardIosTwoTone,
  DeleteTwoTone,
  DoneTwoTone,
  EditTwoTone,
  ExpandLessTwoTone,
  ExpandMoreTwoTone,
  FolderTwoTone,
} from '@material-ui/icons'

import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  // ListSubheader,
  Paper,
  Typography,
} from '@material-ui/core'

import {
  reactSelectAllPlans,
  // reactFetchPlans,
  reactSubscribePlans,
  reactInsertPlan,
  reactUpdatePlan,
  reactRemovePlan,
} from 'cozify-sdk'

import { dateOptions } from '../utils'
import { showError, askConfirmation } from '../services/notifications'
import Documents from './Documents'


const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: '100%',
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  list: {
    width: '100%',
    minHeight: 500,
  },
  header: {
    marginTop: 0,
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  inlineInput: {
    margin: theme.spacing(1),
    // width: '100%',
  },
  actionIcons: {
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
  },
  removeIcon: {
    color: 'red',
    '&:hover': {
      color: 'orangered',
    },
  },
  listItemAlone: {
    paddingLeft: 20,
  },

}))

const Plans = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  /*
  const {
    templates,
  } = props
  */

  const [showPlansList, setShowPlansList] = useState(true)
  const [editMode, setEditMode] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState(undefined)
  const [planName, setPlanName] = useState('')
  const [planDate, setPlanDate] = useState('')

  const dispatch = useDispatch()


  const plans = useSelector(reactSelectAllPlans)

  useEffect(() => {
    // dispatch(reactFetchPlans())
    dispatch(reactSubscribePlans())
      .then((response) => {
        console.info('reactFetchPlans ')
      })
      .catch((error) => {
        debugger
        showError({ message: 'Reading failed' })
      })
  }, [dispatch])

  const handleEditMode = () => {
    setEditMode(!editMode)
  }

  const resetInputFields = () => {
    setPlanName('')
  }

  const handleShowPlanList = () => {
    setShowPlansList(!showPlansList)
    setPlanName('')
  }


  const handleNameChange = (event) => {
    const name = event.target.value
    setPlanName(name)
  }

  const handleInsertPlan = (event) => {
    dispatch(reactInsertPlan({ name: planName }))
      .then((response) => {
        resetInputFields()
        console.info('handleInsertPlan ok: ')
      })
      .catch((error) => {
        debugger
        showError({ message: 'Saving failed' })
      })
  }

  const handleSavePlan = (event) => {
    dispatch(reactUpdatePlan({ uid: selectedPlanId, changes: { name: planName } }))
      .then((response) => {
        console.info('handleSavePlan ok: ')
        setEditMode(false)
      })
      .catch((error) => {
        debugger
        showError({ message: 'Saving failed' })
      })
  }

  const removePlan = () => {
    dispatch(reactRemovePlan({ uid: selectedPlanId }))
      .then((response) => {
        setShowPlansList(true)
        setSelectedPlanId(undefined)
        resetInputFields()
        console.info('removePlan ok: ')
      })
      .catch((error) => {
        debugger
        showError({ message: 'Saving failed' })
      })
  }

  const handleRemovePlan = (event) => {
    askConfirmation({
      message: 'Are you sure to remove',
      cancel: () => { console.log('Canceled') },
      ok: () => { removePlan() },
      timeout: -1,
    })
  }

  const handleSelectPlan = (plan) => {
    setSelectedPlanId(plan.uid)
    setShowPlansList(false)
    setPlanName(plan.name)
    setPlanDate(plan.changed_at || plan.created_at)
  }


  const AddRow = () => (
    <ListItem button>
      <ListItemAvatar>
        <Avatar>
          <FolderTwoTone />
        </Avatar>
      </ListItemAvatar>
      {/* <ListItemIcon>
        <IconButton
          className={classes.addIcon}
          aria-label="add"
          edge="end"
        >
          <AddTwoTone className="" />
        </IconButton>
      </ListItemIcon> */}
      <ListItemText>
        {t('New plan')}
      </ListItemText>
      <ListItemText>
        <Input
          id={`planname-${selectedPlanId}`}
          // eslint-disable-next-line react/jsx-props-no-spreading
          onChange={handleNameChange}
          type="text"
          required
          value={planName || ''}
          placeholder={t('Name')}
          className={classes.inlineInput}
          inputProps={{
            'aria-label': t('Name'),
          }}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="save"
                onClick={handleInsertPlan}
                // onMouseDown={handleInsertPlan}
                edge="end"
              >
                <DoneTwoTone className="" />
              </IconButton>
            </InputAdornment>
          )}
        />
      </ListItemText>
    </ListItem>
  )
  const PlanRows = ({ index, style }) => {
    if (plans && plans.length > 0) {
      const plan = plans[index]
      return (
        <ListItem
          button
          key={index}
          style={style}
          selected={selectedPlanId && (selectedPlanId === plan.uid)}
          onClick={() => { handleSelectPlan(plan) }}
        >

          <ListItemAvatar>
            <Avatar>
              <FolderTwoTone />
            </Avatar>
          </ListItemAvatar>
          {plan.changed_at && (
            <ListItemText primary={(plan.name) || ''} secondary={new Intl.DateTimeFormat('default', dateOptions).format(new Date(plan.changed_at))} />
          )}
          {!plan.changed_at && plan.created_at && (
            <ListItemText primary={(plan.name) || ''} secondary={new Intl.DateTimeFormat('default', dateOptions).format(new Date(plan.created_at))} />
          )}
          {!plan.changed_at && !plan.created_at && (
            <ListItemText primary={(plan.name) || ''} />
          )}
          <ListItemIcon>
            <ArrowForwardIosTwoTone style={{ fontSize: 12 }} />
          </ListItemIcon>
        </ListItem>
      )
    }

    return null
  }


  //
  return (
    <Box my={1} mx={1}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={1}
      >

        {plans && (
          <Grid item xs={12}>
            <Grid
              container
              className={classes.header}
              justify="center"
              alignItems="center"
              onClick={() => handleShowPlanList()}
            >
              <Grid item xs={11}>
                <Typography variant="h6" component="h4">
                  {`${t('Plans')}: `}
                </Typography>

              </Grid>
              <Grid item xs={1} align="right">
                {!showPlansList && (<ExpandMoreTwoTone />)}
                {showPlansList && (<ExpandLessTwoTone />)}
              </Grid>
            </Grid>
            {showPlansList && plans && (
              <Paper className={classes.listRoot}>

                <AutoSizer className={classes.list} defaultHeight="1000">
                  {({ height, width }) => (
                    <FixedSizeList
                      className="List"
                      height={height}
                      itemCount={plans.length}
                      itemSize={100}
                      width={width}
                    >
                      {PlanRows}
                    </FixedSizeList>
                  )}
                </AutoSizer>

              </Paper>
            )}

            {showPlansList && plans && (
              <List>
                {AddRow()}
              </List>
            )}
          </Grid>
        )}
        {selectedPlanId && !showPlansList && (
          <List>
            {!editMode && (
              <ListItem className={classes.listItemIcon}>
                <ListItemAvatar>
                  <Avatar>
                    <FolderTwoTone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={(planName) || ''} secondary={(planDate) || ''} />
                <ListItemIcon>
                  <IconButton
                    className={classes.editIcon}
                    aria-label="edit"
                    onClick={handleEditMode}
                    edge="end"
                  >
                    <EditTwoTone className="" />
                  </IconButton>

                  <IconButton
                    className={classes.removeIcon}
                    aria-label="remove"
                    onClick={handleRemovePlan}
                    // onMouseDown={handleRemovePlan}
                    edge="end"
                  >
                    <DeleteTwoTone className="" />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            )}
            {editMode && (
              <ListItem className={classes.listItemIcon}>
                <ListItemAvatar>
                  <Avatar>
                    <FolderTwoTone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Input
                    id={`planname-${selectedPlanId}`}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    onChange={handleNameChange}
                    type="text"
                    required
                    value={planName || ''}
                    placeholder={t('Name')}
                    className={classes.inlineInput}
                    inputProps={{
                      'aria-label': t('Name'),
                    }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="save"
                          onClick={handleSavePlan}
                          onMouseDown={handleSavePlan}
                          edge="end"
                        >
                          <DoneTwoTone className="" />
                        </IconButton>
                      </InputAdornment>
                    )}
                  />
                </ListItemText>
                <ListItemIcon>
                  <IconButton
                    className={classes.editIcon}
                    aria-label="edit"
                    onClick={handleEditMode}
                    edge="end"
                  >
                    <EditTwoTone className="" />
                  </IconButton>

                  <IconButton
                    className={classes.removeIcon}
                    aria-label="remove"
                    onClick={handleRemovePlan}
                    // onMouseDown={handleRemovePlan}
                    edge="end"
                  >
                    <DeleteTwoTone className="" />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            )}
          </List>
        )}
        {selectedPlanId && !showPlansList && (
          <Documents planId={selectedPlanId} />
        )}
      </Grid>
    </Box>
  )
}

/*
const mapDispatchToProps = {
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const ConnectedPlanning = connect(mapStateToProps, mapDispatchToProps)(Plans)
export default ConnectedPlanning
*/

export default Plans
