/* eslint-disable prefer-destructuring */
import { isEmpty } from '.'

let logLines = []

const addLogLine = (line) => {
  logLines = ([
    ...logLines,
    {
      id: logLines.length,
      type: line.type,
      datetime: line.datetime,
      message: line.message,
      arguments: isEmpty(line.args) ? null : JSON.parse(JSON.stringify(line.args)),
    },
  ])
}

const bindConsole = (levels) => {
  const origConsoleLog = console.log

  console.log = (...args) => {
    if (!levels || levels.indexOf('log') !== -1) {
      const message = Array.from(args)[0]
      let params = Array.prototype.slice.call(args, 1)
      if (params && params.length === 1) params = params[0]
      addLogLine({
        type: 'log', datetime: Date().toLocaleString(), message, args: params,
      })
    }
    origConsoleLog.apply(console, args)
  }
  const origConsoleError = console.error
  console.error = (...args) => {
    if (!levels || levels.indexOf('error') !== -1) {
      const message = Array.from(args)[0]
      let params = Array.prototype.slice.call(args, 1)
      if (params && params.length === 1) params = params[0]
      addLogLine({
        type: 'error', datetime: Date().toLocaleString(), message, args: params,
      })
    }
    origConsoleError.apply(console, args)
  }
  const origConsoleWarn = console.warn
  console.warn = (...args) => {
    if (!levels || levels.indexOf('warn') !== -1) {
      const message = Array.from(args)[0]
      let params = Array.prototype.slice.call(args, 1)
      if (params && params.length === 1) params = params[0]
      addLogLine({
        type: 'warn', datetime: Date().toLocaleString(), message, args: params,
      })
    }
    origConsoleWarn.apply(console, args)
  }
  const origConsoleInfo = console.info
  console.info = (...args) => {
    if (!levels || levels.indexOf('info') !== -1) {
      const message = Array.from(args)[0]
      let params = Array.prototype.slice.call(args, 1)
      if (params && params.length === 1) params = params[0]
      addLogLine({
        type: 'info', datetime: Date().toLocaleString(), message, args: params,
      })
    }
    origConsoleInfo.apply(console, args)
  }
  const origConsoleDebug = console.debug
  console.warn = (...args) => {
    if (!levels || levels.indexOf('debug') !== -1) {
      const message = Array.from(args)[0]
      let params = Array.prototype.slice.call(args, 1)
      if (params && params.length === 1) params = params[0]
      addLogLine({
        type: 'debug', datetime: Date().toLocaleString(), message, args: params,
      })
    }
    origConsoleDebug.apply(console, args)
  }
}
// eslint-disable-next-line import/prefer-default-export
export const recordLogs = (levels) => {
  bindConsole(levels)

  window.getLogLines = (level) => {
    let retLines = logLines
    if (level) {
      retLines = logLines.filter((line) => line.type === level)
    }
    return retLines
  }
}
