/* eslint-disable max-len */

// Some ideas from https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore

export const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length

export const isNotDefined = (value) => (value === undefined || (typeof (value) === 'string' ? (value === null || value.trim() === '') : value === null))
export const isDefined = (value) => (value !== undefined && (typeof (value) === 'string' ? (value !== null && value.trim() !== '') : value !== null))
export const uniqArrayObjects = (datas) => [...new Map(datas.map((obj) => [JSON.stringify(obj), obj])).values()]

export const pick = (object, keys) => keys.reduce((obj, key) => {
  // eslint-disable-next-line
  if (object && object.hasOwnProperty(key)) {
    // eslint-disable-next-line
    obj[key] = object[key]
  }
  return obj
}, {})


export const dateOptions = {
  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
}

export const convertArrayToObject = (array, key) => {
  const initialValue = {}
  return array.reduce((obj, item) => ({
    ...obj,
    [item[key]]: item,
  }), initialValue)
}
