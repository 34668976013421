import React from 'react'
// import { useTranslation } from 'react-i18next'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'

import TemplateEditable from './TemplateEditable'
// import { isEmpty } from '../utils'
// import planningService from '../services/planning'

import useStyles from './EditStyles'
import './NodeEditable.css'


const Template = (props) => {
  // const { t } = useTranslation()
  const classes = useStyles()
  const {
    opened,
    handleChangeTemplateName,
    handleRemoveTemplate,
    roomNames,
    handleAddRoomName,
    handleRemoveRoomName,
    handleChangeRooms,
    deviceTypes,
    handleAddDeviceType,
    handleChangeDeviceTypes,
    handleRemoveDeviceType,
    sceneTypes,
    handleAddSceneType,
    handleChangeSceneTypes,
    handleRemoveSceneType,
    ruleTypes,
    handleAddRuleType,
    handleChangeRuleTypes,
    handleRemoveRuleType,
    toggle,
    templates,
    readOnly,
    selectTemplate,
  } = props


  const renderTemplates = (template) => {
    console.log(`Opened ${opened} ${template.id}`)
    const open = true // opened.includes(template.id)

    const retVal = (
      <Collapse key={`collapse-${template.id}`} in={open} timeout="auto" unmountOnExit>
        <List key={`list-${template.id}`} component="div" disablePadding className={classes.child}>
          <ListItem button key={`list-item-${template.id}`} className={classes.nested}>
            <TemplateEditable
              title={template.data.name || '?'}
              open={opened.includes(template.id)}
              changeTitle={(newName) => { handleChangeTemplateName(template.id, newName) }}
              removeTemplate={() => { handleRemoveTemplate(template.id) }}
              toggle={(e) => { e.preventDefault(); toggle(template.id) }}
              selectedRooms={template.data.rooms || []}
              roomNames={roomNames || []}
              handleChangeRooms={(rooms) => { handleChangeRooms(template.id, rooms) }}
              handleAddRoomName={handleAddRoomName}
              handleRemoveRoomName={handleRemoveRoomName}
              readOnly={readOnly}
              selectTemplate={() => selectTemplate(template)}
              deviceTypes={deviceTypes || []}
              selectedDeviceTypes={template.data.deviceTypes || []}
              handleAddDeviceType={handleAddDeviceType}
              handleChangeDeviceTypes={(devices) => { handleChangeDeviceTypes(template.id, devices) }}
              handleRemoveDeviceType={handleRemoveDeviceType}
              sceneTypes={sceneTypes || []}
              selectedSceneTypes={template.data.sceneTypes || []}
              handleAddSceneType={handleAddSceneType}
              handleChangeSceneTypes={(devices) => { handleChangeSceneTypes(template.id, devices) }}
              handleRemoveSceneType={handleRemoveSceneType}
              ruleTypes={ruleTypes || []}
              selectedRuleTypes={template.data.ruleTypes || []}
              handleAddRuleType={handleAddRuleType}
              handleChangeRuleTypes={(devices) => { handleChangeRuleTypes(template.id, devices) }}
              handleRemoveRuleType={handleRemoveRuleType}
            />
          </ListItem>
        </List>
      </Collapse>
    )
    return retVal
  }

  //

  return (
    <div className={classes.fullWidth}>

      <List
        component="nav"
        aria-labelledby="Targets"
        className={classes.tree}
      >
        {templates && templates.map(renderTemplates)}
      </List>


    </div>
  )
}


export default Template
