import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tree: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
    padding: '2px 0px',
  },
  button: {
    margin: theme.spacing(1),
  },
  child: {
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: 'white',
      textDecoration: 'none',
    },
  },

  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  editArea: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(3),
    marginTop: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  list: {
    width: '100%',
  },
  item: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
  },
  iconButtonst: {
    padding: 0,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  inlineListButtton: {
    boxShadow: 'none',
    marginLeft: '1rem',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
    // margin: theme.spacing(2, 0),
  },
}))

export default useStyles
