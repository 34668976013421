
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AddLocationTwoToneIcon from '@material-ui/icons/AddLocationTwoTone'
import RouterTwoToneIcon from '@material-ui/icons/RouterTwoTone'
import ArrowForwardIosTwoTone from '@material-ui/icons/ArrowForwardIosTwoTone'
import Slide from '@material-ui/core/Slide'
import {
  // store as cozifyStore,
  PLAN_NODES,
} from 'cozify-sdk'
import Template from './Template'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: 70,
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  dialogRoot: {
    width: '100%',
    height: '100%',
  },
  topMarginForContentText: {
    marginTop: theme.spacing(1.5),
  },
  templateInList: {
    border: '1px solod gray',
  },
  header: {
    color: theme.palette.text.secondary,
  },
}))


// eslint-disable-next-line
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

export default function NodeAddDialog(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    opened,
    toggle,
    templates,
    roomNames,
    deviceTypes,
    sceneTypes,
    ruleTypes,
  } = props

  const [open, setOpen] = React.useState(true)
  const nodeTypes = [{
    type: PLAN_NODES.LOCATION,
    name: t('ADDRESS'),
    // eslint-disable-next-line react/display-name
    icon: () => (<AddLocationTwoToneIcon />),
  }, {
    type: PLAN_NODES.HUB,
    name: t('HUB'),
    // eslint-disable-next-line react/display-name
    icon: () => (<RouterTwoToneIcon />),
  }]
  // eslint-disable-next-line react/destructuring-assignment
  // const message = props.message || 'Ok?'
  // eslint-disable-next-line react/destructuring-assignment
  // const description = props.description || ''
  // eslint-disable-next-line react/destructuring-assignment
  const cancelText = props.cancelText || 'Cancel'
  // eslint-disable-next-line react/destructuring-assignment
  // const okText = props.okText || 'Ok'

  const { cancel, ok } = props

  const handleCancel = () => {
    if (cancel) cancel()
    setOpen(false)
  }
  /*
  const selectTemplate = (template) => {
    debugger
  }
  */
  const selectHub = (template) => {
    const node = {
      rooms: [],
      deviceTypes: [],
      ruleTypes: [],
      sceneTypes: [],
      type: PLAN_NODES.HUB,
      name: '?',
    }
    if (template) {
      node.rooms = [...template.data.rooms]
      node.deviceTypes = [...template.data.deviceTypes]
      node.ruleTypes = [...template.data.ruleTypes]
      node.sceneTypes = [...template.data.sceneTypes]
    }
    if (ok) ok(node)
    setOpen(false)
  }

  const selectNewAddress = () => {
    const node = {
      type: PLAN_NODES.LOCATION,
      name: '?',
    }
    if (ok) ok(node)
    setOpen(false)
  }


  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="Add location entry"
      aria-describedby="Add location entry like address, hub, plan etc."
      className={classes.dialogRoot}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">{` ${t('Add planning item')} `}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('Add new address or hub')}
        </DialogContentText>

        <List component="nav" className={classes.root} aria-label="contacts">

          <ListItem
            className={classes.root}
            selected={false}
            button
            key="add-new-location"
            onClick={() => selectNewAddress()}
          >
            <ListItemIcon>
              {nodeTypes[0].icon()}
            </ListItemIcon>
            <ListItemText primary={`${t('Add new address')}`} />
            <ListItemIcon>
              <ArrowForwardIosTwoTone style={{ fontSize: 12 }} />
            </ListItemIcon>
          </ListItem>

          <ListItem
            className={classes.root}
            selected={false}
            button
            key="add-new-hub"
            onClick={() => selectHub()}
          >
            <ListItemIcon>
              {nodeTypes[1].icon()}
            </ListItemIcon>
            <ListItemText primary={`${t('Add new hub')}`} />
            <ListItemIcon>
              <ArrowForwardIosTwoTone style={{ fontSize: 12 }} />
            </ListItemIcon>
          </ListItem>

          <DialogContentText className={classes.topMarginForContentText} id="alert-dialog-description">
            {t('Add new hub based on template')}
          </DialogContentText>


          <Template
            className={classes.templateInList}
            // eslint-disable-next-line react/jsx-boolean-value
            readOnly={true}
            opened={opened}
            toggle={toggle}
            selectTemplate={selectHub}
            templates={templates}
            roomNames={roomNames}
            deviceTypes={deviceTypes}
            sceneTypes={sceneTypes}
            ruleTypes={ruleTypes}
          />


        </List>


      </DialogContent>
      <DialogActions>
        {cancel
          && (
            <Button onClick={handleCancel} color="primary">
              {cancelText}
            </Button>
          )}
      </DialogActions>
    </Dialog>
  )
}
