// import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

/* A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
})
*/
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#101010',
    },
    background: {
      default: '#fefefe',
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
  },
})


export default theme
