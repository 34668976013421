import React, { useEffect, useState, useRef } from 'react'

import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
/*
import {
  LANGUAGES, getUserState, setAuthenticated, fetchHubs,
  cozifyToken, doPwLogin, connectHubByTokens, acceptEula, useTestcloud as testCloud,
} from 'cozify-sdk'
*/
import { setUser, removeUser } from '../services/user'
import AuthService from '../services/auth'
import { showError } from '../services/notifications'


const hasCodeInUrl = (location) => {
  const searchParams = new URLSearchParams(location.search)
  const hashParams = new URLSearchParams(location.hash.replace('#', '?'))
  return Boolean(
    searchParams.get('code')
      || searchParams.get('id_token')
      || searchParams.get('session_state')
      || hashParams.get('code')
      || hashParams.get('id_token')
      || hashParams.get('session_state'),
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  form: {
    width: '100%',
  },
  input: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  link: {
    fontSize: 18,
    margin: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  borderTop: {
    marginTop: theme.spacing(2),
    borderTop: '1px solid #d3d4d5',
  },

}))

const authService = new AuthService()

const Login = (props) => {
  const { history } = props
  const { location } = window
  const classes = useStyles()
  const { t } = useTranslation()
  const isMountedRef = useRef(true)

  const {
    user, // planning,
  } = props

  const [expiryDate, setExpiryDate] = useState('')
 
  
  authService.getSigningExpired(() => {
    showError({ message: 'Session expired, please log-in' })
    history.push('/login')
  })

  authService.getSigningExpiring(async () => {
    // args
    const data = await authService.renewToken()
    setUser(data)
    /*
    askConfirmation({
      message: 'Session expires soon!',
      ok: async () => {
        const data = await authService.renewToken()
        setUser(data)
      },
      timeout: -1,
    }) */
  })

  

  const onSignOut = (user) => {
    // the `user` prop is actually the data the app received from `/userinfo` endpoint.
    history.push('/')
  }

  const onBeforeSignIn = () => {
    // could be used to save location before login
  }

  useEffect(() => () => {    
    isMountedRef.current = false
  }, [])

  useEffect(() => {
    const onSignIn = (user) => {
      // the `user` prop is actually the data the app received from `/userinfo` endpoint.
      history.push('/')
      // debugger
      location.href = location.href.split('?')[0]
    }

    const checkUser = async () => {
      if (hasCodeInUrl(location)) {
        try {
          const data = await authService.signinCallback()
          if (isMountedRef.current) {
            setUser(data)
            onSignIn(data)
            return
          }
        } catch (e) {
          location.search = null
        }
      }

      const authUser = await authService.getUser()
      if (!authUser || (authUser.expires_at < new Date().now) || authUser.expired) {
        console.log('You are not logged in.')
        onBeforeSignIn()
        authService.login()
      } else if (isMountedRef.current) {
        console.log('User has been successfully loaded from store.')
        setUser(authUser)
        // redirect to home unless asked for session
        if (location.hash !== '#/login#session') {
          history.push('/')
        }
      }
    }
    checkUser()
    console.log('Login first EFFECT', location)
  }, [history, location])

  useEffect(() => {
    // for refreshing react state when new state is available in e.g. session storage
    const updateUserData = async () => {
      const user = await authService.getUser()
      isMountedRef.current && setUser(user)
    }
    authService.addUserLoaded(updateUserData)

    return () => authService.removeUserLoaded(updateUserData)
  }, [])

  useEffect(() => {
    console.log('Login EFFECT', props)
  })

  useEffect(() => {
    console.log('Login EFFECT user', user)
    if (user.expires_at) {
      const date = new Date(user.expires_at * 1000)
      setExpiryDate(date.toLocaleString())
    }
  }, [user])

  const signOutHooks = async () => {
    removeUser()
    onSignOut()
  }


  // Not used
  /*
  const signInPopup = async () => {
    const userFromPopup = await authService.signinPopup()
    setUser(userFromPopup)
    onSignIn(userFromPopup)
    await authService.signinPopupCallback()
  }

  const signOutRedirect = async (args) => {
    await authService.logout(args)
    await signOutHooks()
  }

  const goHome = async (event) => {
    event.preventDefault()
    history.push('/')
  }

  */

  const handleLogin = async (event) => {
    // args?
    event.preventDefault()
    await authService.login()
  }

  const handleRenew = async (event) => {
    // args?
    event.preventDefault()
    const data = await authService.renewToken()
    setUser(data)
  }

  const handleLogout = async (event) => {
    event.preventDefault()
    await authService.logout()
    await signOutHooks()
  }


  return (
    <div className={classes.container}>

      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={1} direction="row" justify="center">
          {/* <Grid item xs={12}>
            {JSON.stringify(user)}
          </Grid> */}
          {user.profile && (
            <Grid item xs={12}>
              <Typography variant="caption" component="div">
                {user.profile.email || user.profile.preferred_username}
              </Typography>
            </Grid>
          )}

          {!user.access_token && (
            <Grid item xs={12}>
              <Button size="small" onClick={handleLogin} color="primary" type="button" variant="contained" className={classes.button}>{t('Login')}</Button>
            </Grid>
          )}
          {user.access_token && (
            <Grid item xs={12}>
              <Link size="small" to="/" color="primary" className={classes.link}>
                {`${t('Go')} ${t('Locations')}`}
              </Link>

            </Grid>
          )}
          <Grid item xs={12} className={classes.borderTop} />
          <Grid item xs={12} className={classes.marginTop}>
            {expiryDate && (
              <Typography variant="caption" component="div">
                {`${t('Session expiration')}: ${expiryDate}`}
              </Typography>
            )}
          </Grid>
          {user.access_token && (
            <Grid item xs={12}>
              <Button size="small" onClick={handleRenew} color="primary" type="button" variant="contained" className={classes.button}>{t('Renew session')}</Button>
            </Grid>
          )}
          {user.access_token && (
            <Grid item xs={12}>
              <Button size="small" onClick={handleLogout} color="secondary" type="button" variant="contained" className={classes.button}>{t('Logout')}</Button>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  )
}
/*
Login.propTypes = {
  appStore: PropTypes.func.isRequired,
}
*/
const mapDispatchToProps = {
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
export default ConnectedLogin
