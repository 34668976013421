export const NOTIFICATION_TYPES = Object.freeze({
  ERROR: 'ERROR',
  INFORMATION: 'INFORMATION',
  CONFIRMATION: 'CONFIRMATION',
})

const notificationsReducer = (state = [], orgAction) => {
  console.log('notificationsReducer ACTION: ', orgAction)
  const action = orgAction
  let id = 0
  let index = -1
  switch (action.type) {
  case NOTIFICATION_TYPES.ERROR:
  case NOTIFICATION_TYPES.INFORMATION:
  case NOTIFICATION_TYPES.CONFIRMATION:
    index = state.map((item) => item.name).indexOf(action.data.name)
    if (index !== -1) return state
    id = state.length + 1
    action.data.id = id
    return [...state, action.data]
  case 'REMOVE':
    index = state.map((item) => item.name).indexOf(action.data.name)
    if (index === -1) return state
    return [
      ...state.slice(0, index),
      ...state.slice(index + 1),
    ]
  case 'CLEAR':
    return []
  default:
    return state
  }
}


export const notificationAction = ({
  type, name, message, description, ok, okText, cancel, cancelText,
}) => ({
  type,
  data: {
    type,
    name,
    message,
    description,
    ok,
    okText,
    cancel,
    cancelText,
  },
})

export const clearNotificationAction = (name) => ({
  type: 'REMOVE',
  data: { name },
})

export const clearAllAction = () => ({
  type: 'CLEAR',
  data: {},
})

export default notificationsReducer
