/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'

import {
  Link as RouterLink, withRouter,
} from 'react-router-dom'

// import ListItemText from '@material-ui/core/ListItemText'

import { removeUserAction } from '../reducers/userReducer'

const useStyles = makeStyles((theme) => ({
  '@media print': {
    noPrint: {
      display: 'none !important',
    },
  },
  headerText: {
    marginBottom: 0,
    marginLeft: 0,
    textAlign: 'center',
    left: '-20px',
    position: 'relative',
  },
  menu: {
    width: 'auto',
    backgroundColor: theme.palette.primary.main,
    lineHeight: 1.3125,
    color: 'white',
  },
  button: {
    minWidth: '50px',
    textAlign: 'left',
    margin: 0,
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const Header = (props) => {
  const classes = useStyles()
  const [menuOpen, setMenuOpen] = useState(false)

  const { t, i18n } = useTranslation()
  const { user, job, planning } = props
  const { history } = props
  useEffect(() => {
    console.log('Menu EFFECT')
  }, [])

  const onLogout = (event) => {
    event.preventDefault()
    // props.removeUserAction()
    setMenuOpen(false)
    history.push('/login#session')
  }

  const menuItems = (
    <List className={classes.menu}>

      <ListItem button key="lang">
        <Typography variant="body1" gutterBottom>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            className={classes.button}
            component="button"
            variant="body1"
            onClick={() => i18n.changeLanguage('fi-FI')}
          >
            FI
          </Link>

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            className={classes.button}
            component="button"
            variant="body1"
            onClick={() => i18n.changeLanguage('en-EN')}
          >
            EN
          </Link>
        </Typography>
      </ListItem>

      {user.access_token
      && (
        <RouterLink className={classes.button} to="/">
          <ListItem button key="targets">
            <Typography variant="body1" gutterBottom>
              {t('Targets')}
            </Typography>
          </ListItem>
        </RouterLink>
      )}
      {user.access_token && planning
      && (
        <RouterLink className={classes.button} to="/planning">
          <ListItem button key="planning">
            <Typography variant="body1" gutterBottom>
              {t('Planning')}
            </Typography>
          </ListItem>
        </RouterLink>
      )}
      {user.access_token && job && job.hubId
      && (
        <RouterLink className={classes.button} to="/devices">
          <ListItem button key="devices">
            <Typography variant="body1" gutterBottom>
              {t('Devices')}
            </Typography>
          </ListItem>
        </RouterLink>
      )}
      {user.access_token
      && (
        <ListItem button key="logout" onClick={onLogout}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            className={classes.button}
            component="button"
            variant="body1"
          >
            <Typography variant="body1" gutterBottom>
              {t('Session')}
            </Typography>
          </Link>
        </ListItem>
      )}

    </List>
  )

  return (
    <div className={classes.noPrint}>
      <AppBar position="static">
        <Toolbar>
          <IconButton color="inherit" onClick={(event) => { event.preventDefault(); setMenuOpen(true) }} aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} text-align="center">
              <Typography variant="h6" component="h1" gutterBottom className={classes.headerText}>
                {t('Cozify Installer application')}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer anchor="top" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => setMenuOpen(false)}
          onKeyDown={() => setMenuOpen(false)}
        >
          {menuItems}
        </div>
      </Drawer>
    </div>
  )
}
const mapDispatchToProps = {
  removeUserAction,
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const HeaderWithRouter = withRouter(Header)

const ConnectedHeader = connect(mapStateToProps, mapDispatchToProps)(HeaderWithRouter)
export default ConnectedHeader
