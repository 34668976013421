import React, { useState, useEffect } from 'react'
import {
  useSelector, useDispatch,
} from 'react-redux'

import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import {
  ExpandLessTwoTone,
  ExpandMoreTwoTone,  
} from '@material-ui/icons'

import {  
  Grid,  
  Typography,
} from '@material-ui/core'

import {
  reactSelectPlanDocumentNodes,
  reactSubscribePlanDocumentNodes,
  reactInsertPlanDocumentNode,
  // reactUpdatePlanDocumentNode,
  // reactRemovePlanDocumentNode,
} from 'cozify-sdk'

import { convertArrayToObject } from '../utils'
import { showError, showInfo, askConfirmation } from '../services/notifications'

// import Template from './Template'
import Node from './Node'
import NodeAddDialog from './NodeAddDialog'

const useStyles = makeStyles((theme) => ({
  subHeader: {
    marginTop: 0,
    color: theme.palette.text.secondary,
  },
}))

const Nodes = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    documentId,
    // templates,
    // roomNames,
    // deviceTypes,
    // sceneTypes,
    // ruleTypes,
  } = props

  const [showNodeList, setShowNodeList] = useState(true)
  const [currentDocumentId, setCurrentDocumentId] = useState(undefined)

  const [opened, setOpened] = useState([])
  const [showAddDialog, setShowAddDialog] = useState(false)
  // eslint-disable-next-line
  const [savingNeeded, setSavingNeeded] = useState(false)
  const [addToParentId, setAddToParentId] = useState(undefined)
  const [rootNode, setRootNode] = useState(undefined)
  // eslint-disable-next-line
  const [showTemplateList, setShowTemplateList] = useState(false)
  // eslint-disable-next-line
  const [showJSON, setShowJSON] = useState(false)


  const dispatch = useDispatch()


  const nodes = useSelector((state) => reactSelectPlanDocumentNodes(state, documentId))


  useEffect(() => {
    if (documentId !== currentDocumentId) {
      setCurrentDocumentId(documentId)
      setShowNodeList(true)
    }
    dispatch(reactSubscribePlanDocumentNodes({ documentId }))
      .then((response) => {
        console.info('reactSubscribePlanDocumentNodes ')
      })
      .catch((error) => {
        debugger
        showError({ message: 'Reading failed' })
      })
  }, [currentDocumentId, dispatch, documentId])

  useEffect(() => {
    const findRoot = nodes.filter((node) => node.parent_id === null)
    if (findRoot) {
      if (findRoot.length > 0) {
        const root = findRoot[0]
        setOpened([root.uid])
        setAddToParentId(root.uid)
        setRootNode(root)
      }
    }
  }, [nodes])

  // NODES ->
  const toggle = (childId) => {
    debugger
    if (opened.includes(childId)) {
      const newOpened = [...opened].filter((opnId) => opnId !== childId)
      setOpened(newOpened)
    } else {
      const newOpened = [...opened]
      newOpened.push(childId)
      setOpened(newOpened)
    }
  }

  const handleOpenAddDialog = (parentId) => {
    setShowAddDialog(true)
    setAddToParentId(parentId)
  }

  const handleCancelAddDialog = () => {
    setShowAddDialog(false)
  }
  const handleAddChild = async (parentId, node) => {
    try {
      dispatch(reactInsertPlanDocumentNode({ documentId, parentId, changes: { ...node } }))
        .then((response) => {
          // resetInputFields()
          console.info('handleInsertPlanDocumentNode ok: ')
          setSavingNeeded(true)
        })
        .catch((error) => {
          debugger
          showError({ message: 'Saving failed' })
        })

      setShowAddDialog(false)
      if (!opened.includes(parentId)) {
        toggle(parentId)
      }
    } catch (e) {
      debugger
      showError({ message: 'Adding failed' })
      setShowAddDialog(false)
      console.log(e)
    }
  }
  const handleChangeNodeData = async (id, dataName, newData) => {
    if (nodes[id]) {
      const changedNode = { ...nodes }
      changedNode.data[dataName] = newData
      try {
        // await setLocationNode(changedNode)
        setSavingNeeded(true)
      } catch (e) {
        debugger
        showError({ message: 'Editing failed' })
        console.log(e)
      }
    } else {
      showError({ message: 'Editing failed' })
    }
  }
  const handleRemove = async (id) => {
    askConfirmation({
      message: 'Are you sure to remove?',
      cancel: () => { console.log('Canceled') },
      ok: async () => {
        try {
          // await removeLocationNode(id)
          setSavingNeeded(true)
          showInfo({ message: 'Remove done' })
        } catch (e) {
          showError({ message: 'Remove failed' })
          console.log(e)
        }
      },
      timeout: -1,
    })
  }
  /*
  const handleSave = async (event) => {
    event.preventDefault()
    // const { removeChild, deleteNode, parentId, id } = this.props;
    // removeChild(parentId, id);
    // deleteNode(id);
    try {
      // await saveDocumet()
      setSavingNeeded(false)
      showInfo({ message: 'Saving done' })
    } catch (e) {
      debugger
      showError({ message: 'Saving failed' })
      console.log(e)
    }
  }
  */
  // <- NODES

  // ROOM NAMES ->
  const handleAddRoomName = async (newName) => {
    try {
      // await addRoomName(newName)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Adding failed' })
      console.log(e)
    }
  }
  /*
  const handleChangeRooms = async (id, newRooms) => {

    const changedTemplate = { ...plansLoaded.templates[id] }
    changedTemplate.data.rooms = newRooms
    try {
      await setTemplate(changedTemplate)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Editing failed' })
      console.log(e)
    }

  }
  */
  const handleRemoveRoomName = async (name) => {
    /*
    try {
      await removeRoomName(name)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Adding failed' })
      console.log(e)
    }
    */
  }
  // <- ROOM NAMES

  // DEVICE TYPES ->
  const handleAddDeviceType = async (device) => {
    try {
      // await addDeviceType(device)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Adding failed' })
      console.log(e)
    }
  }
  /*
  const handleChangeDeviceTypes = async (id, newDeviceTypes) => {
    const changedTemplate = { ...plansLoaded.templates[id] }
    changedTemplate.data.deviceTypes = newDeviceTypes
    try {
      await setTemplate(changedTemplate)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Editing failed' })
      console.log(e)
    }
  }
  */

  const handleRemoveDeviceType = async (name) => {
    try {
      // await removeDeviceType(name)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Adding failed' })
      console.log(e)
    }
  }
  // -> DEVICE TYPES

  // SCENE TYPES ->
  const handleAddSceneType = async (scene) => {
    try {
      // await addSceneType(scene)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Adding failed' })
      console.log(e)
    }
  }
  /*
  const handleChangeSceneTypes = async (id, newSceneTypes) => {
    const changedTemplate = { ...plansLoaded.templates[id] }
    changedTemplate.data.sceneTypes = newSceneTypes
    try {
      await setTemplate(changedTemplate)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Editing failed' })
      console.log(e)
    }
  }
  */
  const handleRemoveSceneType = async (name) => {
    try {
      // await removeSceneType(name)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Adding failed' })
      console.log(e)
    }
  }
  // -> SCENE TYPES

  // RULE TYPES->
  const handleAddRuleType = async (rule) => {
    try {
      // await addRuleType(rule)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Adding failed' })
      console.log(e)
    }
  }
  /*
  const handleChangeRuleTypes = async (id, newRuleTypes) => {
    const changedTemplate = { ...plansLoaded.templates[id] }
    changedTemplate.data.ruleTypes = newRuleTypes
    try {
      await setTemplate(changedTemplate)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Editing failed' })
      console.log(e)
    }
  }
  */
  const handleRemoveRuleType = async (name) => {
    try {
      // await removeRuleType(name)
      setSavingNeeded(true)
    } catch (e) {
      debugger
      showError({ message: 'Adding failed' })
      console.log(e)
    }
  }
  // -> RULE TYPES

  /*
  // TEMPLATES ->
  const handleChangeTemplateName = async (id, newName) => {
    const changedTemplate = { ...plansLoaded.templates[id] }
    changedTemplate.data.name = newName
    try {
      await setTemplate(changedTemplate)
      setSavingNeeded(true)
      refreshPlans()
    } catch (e) {
      debugger
      showError({ message: 'Editing failed' })
      console.log(e)
    }
  }
  const handleRemoveTemplate = async (id) => {
    askConfirmation({
      message: 'Are you sure to remove?',
      cancel: () => { console.log('Canceled') },
      ok: async () => {
        try {
          await removeTemplate(id)
          setSavingNeeded(true)
          refreshPlans()
          showInfo({ message: 'Remove done' })
        } catch (e) {
          showError({ message: 'Remove failed' })
          console.log(e)
        }
      },
      timeout: -1,
    })
  }
  // -> TEMPLATES
  */
  /*
  const TextView = ({ onChange, value }) => (
    <div className={classes.textView}>
      <textarea
        className={classes.textViewArea}
        value={value}
        onChange={onChange}
      />
    </div>
  )
  */

  return (
    <Grid
      container
      className={classes.subHeader}
      justify="center"
      alignItems="center"
      onClick={() => setShowNodeList(!showNodeList)}
    >
      <Grid item xs={11}>
        <Typography gutterBottom>
          {`${t('Nodes for document id')}: ${documentId}`}
        </Typography>
        {/* <Typography gutterBottom>
          {`${t('Nodes')}:`}
          {JSON.stringify(nodes)}
        </Typography> */}
      </Grid>
      <Grid item xs={1} align="right">
        {!showNodeList && (<ExpandMoreTwoTone />)}
        {showNodeList && (<ExpandLessTwoTone />)}
      </Grid>
      <Grid item xs={12}>
        <Node
          opened={opened}
          handleOpenAddDialog={handleOpenAddDialog}
          handleCancelAddDialog={handleCancelAddDialog}
          handleAddChild={handleAddChild}
          handleChangeNodeData={handleChangeNodeData}
          handleRemove={handleRemove}
          toggle={toggle}
          rootId={rootNode ? rootNode.uid : undefined}
          nodes={convertArrayToObject(nodes, 'uid')}
          data={rootNode}
          child={rootNode ? rootNode.child : undefined}
          id={rootNode ? rootNode.uid : undefined}
          addToParentId={addToParentId}
          handleAddRoomName={handleAddRoomName}
          handleRemoveRoomName={handleRemoveRoomName}
          handleAddDeviceType={handleAddDeviceType}
          handleRemoveDeviceType={handleRemoveDeviceType}
          handleAddSceneType={handleAddSceneType}
          handleRemoveSceneType={handleRemoveSceneType}
          handleAddRuleType={handleAddRuleType}
          handleRemoveRuleType={handleRemoveRuleType}
        />

        { showAddDialog && (
          <NodeAddDialog
            key="AddDialog"
            opened={opened}
            toggle={toggle}
            ok={(newItem) => { handleAddChild(addToParentId, newItem) }}
            cancel={handleCancelAddDialog}
            okText={` ${t('Save')} `}
            cancelText={` ${t('Cancel')} `}
          />
        )}
      </Grid>
    </Grid>
  )
}


export default Nodes
