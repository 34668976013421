import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'


import { isDefined } from '../utils'

const registerTypeInputOptions = [
  {
    value: '0',
    label: 'Select',
  },
  {
    value: '1',
    label: 'Discretes Input',
  },
  {
    value: '3',
    label: 'Input register',
  },
]

const registerTypeOutputOptions = [
  {
    value: '0',
    label: 'Select',
  },
  {
    value: '2',
    label: 'Coil',
  },
  {
    value: '4',
    label: 'Holding register',
  },
]

const inputTypeOptions = [
  {
    value: 'true',
    label: 'Modbus Normally Off short',
  },
  {
    value: 'false',
    label: 'Modbus Reversed Off short',
  },
]


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
    paddingLeft: theme.spacing(1),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  form: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(1),
  },
  checkbox: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  note: {
    fontSize: '.9em',
    margin: theme.spacing(1),
    color: '#333',
  },
}))


export default function ModbusConfiguration(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const {
    devices, pairModbus, handleManualPairingDone,
  } = props


  useEffect(() => {
    console.log('ModbusConfiguration EFFECT', props)
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    pairModbus()
  }

  const handleCancel = () => {
    handleManualPairingDone()
  }


  const handleChange = (canged, prop, value) => {
    const deviceChanged = canged
    if (isDefined(deviceChanged) && isDefined(prop) && isDefined(value)) {
      deviceChanged[prop] = value
      let submitReady = true
      devices.map((device) => {
        if (device.selected) {
          device.ready = false
          if (isDefined(device.name)
            && isDefined(device.address)
            && (device.address > 0)
            && (device.address <= 247)) {
            if (device.registerRequired) {
              if (isDefined(device.registerType)) {
                device.registerType = Number(device.registerType)
              }
              if ((device.register >= 0)
                && (device.register <= 65535)
                && isDefined(device.registerType)
                && (device.registerType >= 1)
                && (device.registerType <= 4)
              ) {
                device.ready = true
              }
            } else {
              device.ready = true
            }
          }
          if (device.ready && submitReady) {
            submitReady = true
          } else {
            submitReady = false
          }
          setReadyToSubmit(submitReady)
        }
        return true
      })
    }
  }

  return (
    <div className={classes.container}>

      <form onSubmit={handleSubmit} className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={1} direction="row" justify="center">

          <Grid item xs={12}>
            <Typography variant="caption" className={classes.note} gutterBottom>
              {t('Adding Modbus devices intro')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.heading} gutterBottom>
              {t('Devices available')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} direction="row" justify="left">
              {devices.map((device) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={device.name}>
                  <Paper className={classes.paper}>

                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={device.selected}
                          onChange={(event) => { handleChange(device, 'selected', event.target.checked) }}
                          value={device.deviceType}
                          color="primary"
                          className={classes.checkbox}
                        />
                      )}
                      label={device.name}
                    />

                    <Grid item xs={12}>
                      <Typography className={classes.heading}>
                        {`${device.manufacturer}`}
                      </Typography>

                    </Grid>
                    {device.selected && (
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          id="address"
                          onChange={(event) => { handleChange(device, 'address', event.target.value) }}
                          type="number"
                          required
                          value={device.address || ''}
                          autoComplete="current-address"
                          placeholder={t('Modbus address')}
                          className={classes.input}
                          inputProps={{
                            'aria-label': t('Modbus address'),
                            min: '1',
                            max: '247',
                          }}
                          helperText={t('Modbus address explanation')}
                        />
                      </Grid>
                    )}
                    {device.selected && device.registerRequired && (
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          id="address"
                          onChange={(event) => { handleChange(device, 'register', event.target.value) }}
                          type="number"
                          required
                          value={device.register || ''}
                          autoComplete="current-register"
                          placeholder={t('Modbus register')}
                          className={classes.input}
                          inputProps={{
                            'aria-label': t('Modbus register'),
                            min: '0',
                            max: '65535',
                          }}
                          helperText={t('Modbus register explanation')}
                        />
                      </Grid>
                    )}
                    {device.selected && device.registerRequired && (
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          id="registertype"
                          onChange={(event) => { handleChange(device, 'registerType', event.target.value) }}
                          select
                          // label={t('Modbus register type')}
                          value={device.registerType || 0}
                          helperText={t('Modbus register type explanation')}
                          className={classes.input}
                        >
                          {device.deviceType === 'RELAY' && registerTypeOutputOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value} disabled>
                              {t(option.label)}
                            </MenuItem>
                          ))}
                          {device.deviceType === 'SIGNAL' && registerTypeInputOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value} disabled>
                              {t(option.label)}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {device.selected && (device.deviceType === 'RELAY' || device.deviceType === 'SIGNAL') && (
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          id="inputtype"
                          onChange={(event) => { handleChange(device, 'normallyOff', event.target.value) }}
                          select
                          value={device.normallyOff}
                          helperText={t('input type explanation explanation')}
                          className={classes.input}
                        >
                          {inputTypeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value} disabled>
                              {t(option.label)}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.note} gutterBottom>
              {t('Adding Modbus devices intro2')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button size="small" color="primary" disabled={!readyToSubmit} type="submit" variant="contained" className={classes.button}>{t('Done')}</Button>

            <Button size="small" color="secondary" type="button" variant="contained" onClick={handleCancel} className={classes.button}>{t('Cancel')}</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
