import React, { useState, useEffect, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import AlarmAddTwoToneIcon from '@material-ui/icons/AlarmAddTwoTone'
import AlarmOffTwoToneIcon from '@material-ui/icons/AlarmOffTwoTone'
import PanToolTwoToneIcon from '@material-ui/icons/PanToolTwoTone'
import PermScanWifiTwoToneIcon from '@material-ui/icons/PermScanWifiTwoTone'
import IconButton from '@material-ui/core/IconButton'
import TuneTwoToneIcon from '@material-ui/icons/TuneTwoTone'
import DeleteIcon from '@material-ui/icons/Delete'
import HealingTwoToneIcon from '@material-ui/icons/HealingTwoTone'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NewReleasesTwoToneIcon from '@material-ui/icons/NewReleasesTwoTone'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

import {
  changeLanguage, setAuthenticated,
  connectHubBySite,
  doPoll,
  startPairingById, stopPairingById,
  isZwaveEnabled,
  doZwaveInclusion,
  stopZwaveInclusion,
  doZwaveExclusion,
  stopZwaveExclusion,
  healZwave,
  getModbusDevicePairings,
  setModbusDevicePairings,
  getZwaveNodes,
  checkIsFailedZWaveNode,
  removeFailedZWaveNode,
  getZWaveNodeParameter,
  setZWaveNodeParameter,
  unpairDevice,
  selectCloud,
  // closeAlarm, removeAlarm,
} from 'cozify-sdk'


import { setRooms, ROOM_NAMES } from '../services/rooms'
import { showError, showInfo, askConfirmation } from '../services/notifications'
import { isNotDefined, isDefined, isEmpty } from '../utils'
import DeviceTabs from './DeviceTabs'
import ModbusConfiguration from './ModbusConfiguration'
import ZWaveConfiguration from './ZWaveConfiguration'

const PAIRING_TIMEOUT = 1000 * 60 * 5
// import { useSelector, useActions } from 'react-redux'

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))((props) => (
  <MenuItem
    {...props}
  />
))
// }))(MenuItem)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  flexGrow: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
    textTransform: 'uppercase',
  },
  explanation: {
    verticalAlign: 'text-top',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  headerItem: {
    minHeight: 30,
  },
  newDevice: {
    position: 'relative',
    top: 0,
    left: 5,
    fontSize: 12,
    color: theme.palette.error.main,
    margin: theme.spacing(0, 1, 0, 2),
  },
  progress: {
    marginLeft: 0,
    fontSize: 12,
    position: 'relative',
    top: 0,
    height: 8,
  },
  progressContainer: {
    width: '100%',
    textAlign: 'center',
  },
  circularProgress: {
    display: 'inline-block',
    margin: theme.spacing(2),
    color: 'black',

  },
  iconButtons: {
    width: '100%',
  },
  iconButton: {
    margin: theme.spacing(1),
  },
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconButtonLabelTxt: {
    fontSize: 9,
    textTransform: 'uppercase',
    paddingTop: '0.3rem',
    width: '70%',
  },
  iconButtonLabelChar: {
    fontSize: 17,
    position: 'relative',
    top: 0,
    left: 0,
  },
  menuIcon: {
    maxWidth: '30%',
    color: 'white',
  },
  rightMenuIcon: {
    maxWidth: '50%',
    color: 'white',
    float: 'right',
  },
  greenIcon: {
    color: 'green',
  },
  redIcon: {
    color: 'red',
  },
  breadCrumbHeader: {
    color: 'rgba(0,0,0.85)',
  },
  /*
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  */
}))

const Devices = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { job } = props
  const [deleteInProgess, setDeleteInProgess] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [cozifyHubId, setCozifyHubId] = useState(null)
  const [cozifyUserToken, setCozifyUserToken] = useState(null)
  const [cozifyHubKey, setCozifyHubKey] = useState(null)
  // const [isMeasurements, setIsMeasurements] = useState(false)
  const [loginBusy, setLoginBusy] = useState(false)
  const [connecting, setConnecting] = useState(false)
  const [connected, setConnected] = useState(false)
  const [pairingBusy, setPairingBusy] = useState(false)
  const [stopPairingBusy, setStopPairingBusy] = useState(false)
  const [pairingInfo, setPairingInfo] = useState({})
  const [roomsChecked, setRoomsChecked] = useState(false)
  const [devicesByType, setDevicesByType] = useState([])
  // eslint-disable-next-line
  const [devicesArray, setDevicesArray] = useState([])
  const [isZwavesTested, setIsZwavesTested] = useState(false)
  const [isZwaves, setIsZwaves] = useState(false)
  // TODO: get status
  // eslint-disable-next-line
  const [isModbus, setIsModbus] = useState(true)
  const [manualDevices, setManualDevices] = useState([])
  const [zWaveNodes, setZWaveNodes] = useState([])
  const [zWaveNodesBusy, setZWaveNodesBusy] = useState(false)
  const [showZWaveConfiguration, setShowZWaveConfiguration] = useState(false)
  const [showManualConfiguration, setShowManualConfiguration] = useState(false)
  const [modbusDiscoveryBusy, setModbusDiscoveryBusy] = useState(false)
  const [zWaveInclusionBusy, setZWaveInclusionBusy] = useState(false)
  const [zWaveExclusionBusy, setZWaveExclusionBusy] = useState(false)
  const [zWaveHealingnBusy, setZWaveHealingnBusy] = useState(false)
  const [cancelPollIntervall, setCancelPollIntervall] = useState()
  // eslint-disable-next-line
  const [measurements, setMeasurements] = useState([])
  const [completed, setCompleted] = React.useState(0)
  const intervallHandle = React.useRef()
  const pairingTimeoutHandle = React.useRef()


  const [zWaveMenuAnchorEl, setZWaveMenuAnchorEl] = React.useState(null)
  const isZWaveMenuOpen = Boolean(zWaveMenuAnchorEl)

  // eslint-disable-next-line
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
  // const openMenu = Boolean(menuAnchorEl)

  const {
    alarms, hubs, devices, pairings, rooms, user, locale, token,
  } = props


  const handleZWaveMenuOpen = (event) => {
    setZWaveMenuAnchorEl(event.currentTarget)
  }

  const handleZWaveMenuClose = () => {
    setZWaveMenuAnchorEl(null)
  }
  /*
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }
  */

  const handleDoPoll = async () => {
    doPoll(cozifyHubId, true).then(() => {
      // console.info('Poll status', status)
      setConnected(true)
    }).catch((error) => {
      if (error.message.indexOf('Already polling') !== -1) {
        setConnected(true)
      } else {
        showError({ message: 'Hub polling failed' })
      }
      console.error('!!!!', error)
    })
  }

  // Set correct rooms
  useEffect(() => {
    if (cozifyHubId
      && !isEmpty(devices[cozifyHubId])
      && !isEmpty(rooms[cozifyHubId])
      && !roomsChecked) {
      selectCloud(`${process.env.REACT_APP_DIRECTORY_URL}/directory/device/site/${job.siteId}/`)
      const changed = setRooms(cozifyHubId, devices[cozifyHubId], rooms[cozifyHubId], ROOM_NAMES)
      const notChanged = isEmpty(changed.addedNames) && isEmpty(changed.removedNames)
      setRoomsChecked(!notChanged)
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(devices),
    // eslint-disable-next-line
    JSON.stringify(rooms),
    cozifyHubId,
    roomsChecked,
  ])

  // Show alarms
  useEffect(() => {
    if (cozifyHubId && !isEmpty(alarms[cozifyHubId])) {
      Object.values(alarms[cozifyHubId]).map((alarm) => {
        showError({ message: alarm.title })
        return true
      })
      console.error('Hub alarms ', alarms[cozifyHubId])
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(alarms), 
    cozifyHubId,
  ])

  // Test zwave
  useEffect(() => {
    const testZwave = async () => {
      if (!isZwavesTested && cozifyHubId && connected) {
        try {
          const test = await isZwaveEnabled(cozifyHubId)
          setIsZwaves(test)
          setIsZwavesTested(true)
        } catch (error) {
          setIsZwaves(false)
          setIsZwavesTested(true)
        }
      }
    }
    testZwave()
  }, [
    pairingBusy,
    cozifyHubId,
    connected,
    isZwavesTested,
  ])


  useEffect(() => {
    const INTERVAL = 1000
    const progress = () => {
      const times = PAIRING_TIMEOUT / INTERVAL
      // 10s välein 120s = 100 => 12kertaa | 10s välien 300s = 100 => 300 / 10 => 30kertaa
      setCompleted((prevCompleted) => (prevCompleted >= 100 ? 0 : prevCompleted + (100 / times)))
    }
    if (pairingBusy) {
      intervallHandle.current = setInterval(progress, INTERVAL)
    }
    return () => {
      setCompleted(0)
      clearInterval(intervallHandle.current)
      intervallHandle.current = null
    }
  }, [pairingBusy])

  // When unmounting
  useLayoutEffect(() => async () => {
    if (cancelPollIntervall) {
      clearInterval(cancelPollIntervall)
    }
  }, [cancelPollIntervall])

  useEffect(() => {
    const doLogin = async () => {
      changeLanguage(locale)
      const cozifyToken = job.hub.keys.cozifyCloudToken || null
      const hubKey = job.hub.keys.cozifyHubKey || null
      try {
        setCozifyUserToken(cozifyToken)
        setCozifyHubKey(hubKey)
        setAuthenticated(cozifyToken)
      } catch (e) {
        showError({ message: 'Cozify login error' })
      }
      return cozifyToken
    }

    // Do login
    if (!cozifyUserToken && !loginBusy) {
      setLoginBusy(true)
      doLogin()
    }
    console.log('Devices EFFECT user', user)
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(user),
    job.hub.keys.cozifyCloudToken,
    job.hub.keys.cozifyHubKey,
    loginBusy,
    cozifyUserToken,
    locale,
  ])

  /*
  useEffect(() => {
    if (!isEmpty(devicesArray) && isEmpty(measurements) && !isMeasurements) {
      // get measurements
      const getMeasurements = async () => {
        try {
          const deviceIds = [...new Set(devicesArray.map((d) => d.id))]
          const measures = []
          // not available const measures = await locationService.getLatestMeasurements(token, deviceIds, job)
          setIsMeasurements(true)
          setMeasurements(measures)
        } catch (error) {
          console.error('Measurements not found')
        }
      }
      if (isEmpty(measurements)) {
        getMeasurements()
      }
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(devicesArray),
    // eslint-disable-next-line
    JSON.stringify(measurements),
    isMeasurements,
    token,
  ])
*/
  useEffect(() => {
    // Connect
    if (job && job.hub.id && !connected) {
      setCozifyHubId(job.hub.id)
    }
    if (cozifyHubId && !connecting) {
      setConnecting(true)

      // connectHubByTokens(cozifyHubId, cozifyHubKey, false).then(() => {
      selectCloud(`${process.env.REACT_APP_DIRECTORY_URL}/directory/device/site/${job.siteId}/`)
      connectHubBySite(cozifyHubId, job.siteId, false).then(() => {
        doPoll(cozifyHubId, true).then((status) => {
          console.info('Poll status', status)
          setConnected(true)
          setCancelPollIntervall(setInterval(handleDoPoll, 5000))
        }).catch((error) => {
          // showError({ message: 'Hub polling failed' })
          console.error(error)
        })
      })
    }
    /*
    // Auto start pairing
    if (connected && cozifyHubId && hubs && hubs[cozifyHubId] && !pairingBusy) {
      setPairingBusy(true)
      startPairingById(cozifyHubId, true)
    }
    */
    console.log('Devices EFFECT job', job)
    // console.log('Devices EFFECT hubs', hubs)
    // console.log('Devices EFFECT hubId', cozifyHubId)
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(hubs),
    // eslint-disable-next-line
    JSON.stringify(job),
    handleDoPoll,
    cozifyHubId,
    cozifyHubKey,
    connecting,
    connected,
    // pairingBusy,
  ]) // run only if hubs or hub id changes

  useEffect(() => {
    if (!isEmpty(devices) && cozifyHubId && devices[cozifyHubId]) {
      console.log('Devices EFFECT devices before', devices[cozifyHubId])
      console.log('Devices EFFECT pairings before', pairings[cozifyHubId])
      console.log('Devices EFFECT pairingInfo before', pairingInfo)
      const allDevices = {}
      if (cozifyHubId && !isEmpty(devices) && !isEmpty(devices[cozifyHubId])) {
        // eslint-disable-next-line max-len
        Object.values(devices[cozifyHubId]).map((device) => { allDevices[device.id] = {...device}; return true })
      }
      let pairingTypes = []
      if (pairingBusy) {
        // Make copy
        let allPairings = {}
        // eslint-disable-next-line max-len
        Object.values(pairingInfo).map((pairing) => { allPairings[pairing.id] = pairing; return true })

        if (cozifyHubId && !isEmpty(pairings) && !isEmpty(pairings[cozifyHubId])) {
          allPairings = {}
          // eslint-disable-next-line max-len
          Object.values(pairings[cozifyHubId]).map((pairing) => {
            allPairings[pairing.id] = pairing
            return true
          })
          setPairingInfo(allPairings)
        } else {
          setPairingInfo({})
        }

        const pairingsArr = Object.values(allPairings)
        pairingTypes = [...new Set(pairingsArr.map((d) => d.type))]
        if (!isEmpty(allPairings)) {
          new Map(Object.entries(allPairings)).forEach((pairing, key) => {
            if (!allDevices[key] || !allDevices[key].pairing) {
              // Add pairing device to the list of paired devices
              allDevices[key] = {...pairing}
              const {
                ignored, info, actionRequired, pairingStatus,
              } = pairing
              /* if (pairing.capabilities.values.indexOf('ZW') !== -1) { debugger } */
              if (ignored) {
                allDevices[key].pairing = {
                  ignored, info, actionRequired, pairingStatus,
                }
              } else {
                allDevices[key].added = {
                  ignored, info, actionRequired, pairingStatus,
                }
              }

              /* Org
              allDevices[key].pairing = {
                ignored, info, actionRequired, pairingStatus,
              } */
            }
          })
        }
      }

      const deviceArr = Object.values(allDevices)
      setDevicesArray(deviceArr)
      const deviceTypes = [...new Set(deviceArr.map((d) => d.type), pairingTypes)].sort()
      // const deviceCapabilities = [...new Set(deviceArr.map((d) => d.capabilities.values))].flat()
      const byType = deviceTypes.map((devType) => {
        const devs = deviceArr.filter((dev) => dev.type === devType)
        const sortedDevs = devs.sort((devA, devB) => {
          if (!rooms[cozifyHubId]) {
            return 0
          }
          if (isEmpty(rooms[cozifyHubId])) {
            return 0
          }
          if (!devA.room && !devB.room) {
            return 0
          }
          if (!devA.room) {
            return -1
          }
          if (!devB.room) {
            return 1
          }
          if (isEmpty(devA.room) && isEmpty(devB.room)) {
            return 0
          }
          if (isEmpty(devA.room)) {
            return -1
          }
          if (isEmpty(devB.room)) {
            return 1
          }
          if (!rooms[cozifyHubId][devA.room[0]] && !rooms[cozifyHubId][devB.room[0]]) {
            return 0
          }
          if (!rooms[cozifyHubId][devA.room[0]]) {
            return -1
          }
          if (!rooms[cozifyHubId][devB.room[0]]) {
            return 1
          }
          const a = rooms[cozifyHubId][devA.room[0]].name
          const b = rooms[cozifyHubId][devB.room[0]].name
          if (a > b) {
            return 1
          }
          if (b > a) {
            return -1
          }
          return 0
        })
        const isPairings = !isEmpty(devs.filter((dev) => dev.pairing))
        const isPaireds = !isEmpty(devs.filter((dev) => !dev.pairing))
        const isNewAdded = !isEmpty(devs.filter((dev) => dev.added))
        const retVal = {
          name: devType, devices: sortedDevs, isPairings, isPaireds, isNewAdded,
        }
        return retVal
      })
      setDevicesByType(byType)
    }
    console.log('Devices EFFECT device by types', devicesByType)
    console.log('Devices EFFECT devices', devices[cozifyHubId])
    console.log('Devices EFFECT pairings', pairings[cozifyHubId])
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(devices[cozifyHubId]),
    // eslint-disable-next-line
    JSON.stringify(pairings[cozifyHubId]),
    // eslint-disable-next-line
    JSON.stringify(pairingInfo),
    // eslint-disable-next-line
    JSON.stringify(rooms),
    pairingBusy,
    cozifyHubId,
  ])

  // When unmounting
  useLayoutEffect(() => async () => {
    try {
      if (zWaveExclusionBusy) {
        await stopZwaveExclusion(cozifyHubId)
      }
      if (zWaveInclusionBusy) {
        await stopZwaveInclusion(cozifyHubId)
      }
    } catch (error) {
      // showError({ message: 'Stopping pairings failed' })
    }
  }, [cozifyHubId, zWaveExclusionBusy, zWaveInclusionBusy])

  // When unmounting
  useLayoutEffect(() => async () => {
    try {
      if (pairingBusy && !stopPairingBusy) {
        setStopPairingBusy(true)
        if (cozifyHubId) {
          await stopPairingById(cozifyHubId)
        }
        setStopPairingBusy(false)
        if (pairingTimeoutHandle && pairingTimeoutHandle.current) {
          clearTimeout(pairingTimeoutHandle.current)
          pairingTimeoutHandle.current = null
        }
        if (intervallHandle && intervallHandle.current) {
          clearInterval(intervallHandle.current)
          intervallHandle.current = null
        }
      }
    } catch (error) {
      console.error('Stopping pairings failed, error')
      setStopPairingBusy(false)
    }
  },
  // eslint-disable-next-line
  [
    cozifyHubId,
    pairingBusy,
    stopPairingBusy,
  ])

  const handleStartPairing = async () => {
    if (connected && cozifyHubId && hubs && hubs[cozifyHubId] && !pairingBusy) {
      setPairingBusy(true)
      try {
        await startPairingById(cozifyHubId, true)
        pairingTimeoutHandle.current = setTimeout(() => {
          setPairingBusy(false)
          showInfo({ message: 'Pairing stopped due to timeout' })
        }, PAIRING_TIMEOUT)
      } catch (error) {
        showError({ message: 'Discovery start failed' })
        console.error(error)
      }
    } else {
      showError({ message: 'Discovery can not be started' })
    }
  }
  const handleStopPairing = async () => {
    setManualDevices([])
    setShowManualConfiguration(false)
    setShowZWaveConfiguration(false)
    try {
      setStopPairingBusy(true)
      setPairingBusy(false)
    } catch (error) {
      setPairingBusy(true)
      showError({ message: 'Stopping pairing failed' })
    }
  }

  const handleStartZwaveHealing = async () => {
    try {
      setZWaveHealingnBusy(true)
      await healZwave(cozifyHubId)
      setZWaveHealingnBusy(false)
      showError({ message: 'Z-wave healing started. Healing will take 5-30 minutes and will be cancelled if any pairing happens during that time', timeout: 10000 })
    } catch (error) {
      setZWaveHealingnBusy(false)
      showError({ message: 'Z-wave healing failed' })
    }
  }


  const handleStartZWaveNetworkDetails = async () => {
    try {
      setZWaveNodesBusy(true)
      const discoveredNodes = await getZwaveNodes(cozifyHubId)

      if (discoveredNodes && discoveredNodes.length > 0) {
        const awailableNodes = discoveredNodes.filter((node) => {
          if (node.type === 'ZWAVE_NODE') {
            // eslint-disable-next-line no-param-reassign
            node.parameters = {}
            return node
          }
          return null
        })
        setZWaveNodes(awailableNodes)
        setShowZWaveConfiguration(true)
      } else {
        setZWaveNodes([])
        showError({ message: 'No Z-Wave node found' })
      }
      setZWaveNodesBusy(false)
    } catch (error) {
      debugger
      setZWaveNodesBusy(false)
      showError({ message: 'Z-Wave node lookup failed' })
    }
  }

  const handleZWaveConfigurationDone = () => {
    setZWaveNodes([])
    setShowZWaveConfiguration(false)
  }


  const handleStartZwaveExclusion = async (show) => {
    try {
      setZWaveExclusionBusy(true)
      const status = await doZwaveExclusion(cozifyHubId)
      if (show) {
        if (status) {
          showInfo({ message: 'Z-wave device removed' })
        } else {
          showError({ message: 'Z-wave remove failed' })
        }
      }
      setZWaveExclusionBusy(false)
      return (status)
    } catch (error) {
      setZWaveExclusionBusy(false)
      if (error.message !== 'exclusion stopped') {
        if (show) {
          showError({ message: 'Z-wave remove failed' })
        }
        return (null)
      }
      return (false)
    }
  }
  const confirmStartZwaveExclusion = async () => {
    askConfirmation({
      message: 'To unpair Z-wave device, you must follow device unpair procedure - continue?',
      cancel: () => { console.log('Canceled') },
      ok: () => { handleStartZwaveExclusion(true) },
      timeout: -1,
    })
  }
  const handleCancelZwaveExclusion = async () => {
    try {
      await stopZwaveExclusion(cozifyHubId)
      setZWaveExclusionBusy(false)
    } catch (error) {
      setZWaveExclusionBusy(false)
      showError({ message: 'Z-wave remove cancelation failed' })
    }
  }

  const deviceUnPair = async (device) => {
    if (!deleteInProgess) {
      setDeleteInProgess(true)
      try {
        let status = true
        await unpairDevice(cozifyHubId, device.id, true)
        if (device.capabilities.values.indexOf('ZW') !== -1) {
          status = await handleStartZwaveExclusion(false)
        }
        if (status) {
          // if (pairing) await stopPairingById(hubId)
          setTimeout(() => {
            showInfo({ message: 'Unpaired', timeout: 10000 })
            setDeleteInProgess(false)
          }, 3000)
        } else {
          if (device.capabilities.values.indexOf('ZW') !== -1) {
            showError({ message: 'Z-wave remove failed' })
          } else {
            showError({ message: 'Unpairing failed' })
          }
          setDeleteInProgess(false)
        }
      } catch (error) {
        debugger
        showError({ message: 'Unpairing failed' })
        setDeleteInProgess(false)
      }
      // makePoll()
      // restartPairing()
      // setTimeout(restartPairing, SHORT_TIMEOUT)
    }
  }

  const handleDeviceUnPair = async (device, callBack) => {
    let message = 'Are you sure to unpair?'
    if (device.capabilities.values.indexOf('ZW') !== -1) {
      message = 'To unpair Z-wave device, you must follow device unpair procedure - continue?'
    }
    askConfirmation({
      message,
      cancel: () => { console.log('Canceled') },
      ok: async () => {
        await deviceUnPair(device)
        if (callBack) {
          callBack()
        }
      },
      timeout: -1,
    })
  }


  const handleExPanelChange = (devType) => (event, isExpanded) => {
    if (devType.isNewAdded && pairingBusy) {
      handleStopPairing()
    }
    setExpanded(isExpanded ? devType.name : false)
  }


  const back = () => {
    window.history.back()
  }
  const handleManualPairingDone = () => {
    setManualDevices([])
    setShowManualConfiguration(false)
  }
  const handlePairModbus = async (awailableDevices) => {
    let invalidData = false
    const data = []
    awailableDevices.map((device) => {
      if (device.selected) {
        if (isNotDefined(device.address)) {
          invalidData = true
        }
        if (isNotDefined(device.deviceType)) {
          invalidData = true
        }
        const item = {
          address: device.address,
          deviceType: device.deviceType,
          name: device.name || '',
          readDeviceIdSupported: device.readDeviceIdSupported, // as is - used only by hub
          type: 'PAIR_MODBUS',
        }
        if (isDefined(device.normallyOff)) {
          item.normallyOff = !!(device.normallyOff === true || device.normallyOff === 'true')
        }
        if (isDefined(device.name)
        && isDefined(device.address)
        && (device.address > 0)
        && (device.address <= 247)) {
          if (device.registerRequired) {
            if (isDefined(device.registerType)) {
              device.registerType = Number(device.registerType)
            }
            if (isDefined(device.register)
            && (device.register >= 0)
            && (device.register <= 65535)
            && isDefined(device.registerType)) {
              item.registerRequired = device.registerRequired
              item.register = device.register
              item.registerType = device.registerType
            } else {
              invalidData = true
            }
          } else {
            item.registerRequired = device.registerRequired
          }
        } else {
          invalidData = true
        }
        if (!invalidData) {
          data.push(item)
        }
      }
      return true
    })

    if (!invalidData && data.length > 0) {
      try {
        const reply = await setModbusDevicePairings(cozifyHubId, data)
        if (reply) {
          showInfo({ message: 'Modbus device configured - pair now' })
          setExpanded('RELAY')
          handleManualPairingDone()
        } else {
          showError({ message: 'Modbus device configuration failed' })
        }
      } catch (e) {
        if (e.message.indexOf('already exists')) {
          showError({ message: 'Modbus device already paired' })
        } else {
          showError({ message: 'Modbus device configuration failed' })
        }
      }
    } else {
      showError({ message: 'Missing or invalid Modbus device data' })
    }
  }

  const handleListDiscoverModbusDevices = async () => {
    try {
      setModbusDiscoveryBusy(true)
      const discoveredDevices = await getModbusDevicePairings(cozifyHubId)
      if (discoveredDevices && discoveredDevices.length > 0) {
        const awailableDevices = discoveredDevices.filter((device) => {
          if (!device.address) {
            // Hardcoded for Majakka
            device.address = 1
          }
          if (!device.registerType) {
            device.registerType = 0
          }
          if (device.deviceType === 'RELAY') {
            // Hardcoded for Majakka
            device.register = 8959
            device.registerType = 4
            device.selected = true
          }
          if (device.deviceType === 'SIGNAL') {
            // Hardcoded for Majakka
            device.register = 8960
            device.registerType = 3
          }
          if (((device.deviceType === 'RELAY') || (device.deviceType === 'SIGNAL'))) {
            device.normallyOff = false
          }
          if (device.deviceType === 'RELAY') {
            return device
          }
          return false
        })
        setManualDevices(awailableDevices)
        // TODO: Hide if not modbus
        setShowManualConfiguration(awailableDevices && awailableDevices.length > 0)
        if (awailableDevices && (awailableDevices.length > 0)) {
          handlePairModbus(awailableDevices)
        }
      } else {
        showError({ message: 'No Modbus device found' })
      }
      setModbusDiscoveryBusy(false)
    } catch (error) {
      setModbusDiscoveryBusy(false)
      showError({ message: 'Modbus device search failed' })
    }
  }


  const handleStartZwaveInclusion = async () => {
    try {
      setZWaveInclusionBusy(true)
      const status = await doZwaveInclusion(cozifyHubId)
      if (status) {
        /* Not needed to show?
        setTimeout(() => {
          showInfo({ message: 'Z-wave device found and paired' })
        }, 3000)
        */
        /*
        try {
          await setDeviceMeta(hubId, device.id, deviceName, room)
        } catch (error) {
          showError({ message: 'Pairing failed' })
        }
        try {
          await stopPairingById(cozifyHubId)
          await handleStopPairing()
        } catch (error) {
          showError({ message: 'Pairing failed' })
        }
        */
      } else {
        showError({ message: 'No Z-wave device found' })
      }
      setZWaveInclusionBusy(false)
    } catch (error) {
      setZWaveInclusionBusy(false)
      if (error.message !== 'inclusion stopped') {
        showError({ message: 'Z-wave inclusion failed' })
      }
    }
  }
  const handleCancelZwaveInclusion = async () => {
    try {
      await stopZwaveInclusion(cozifyHubId)
      setZWaveInclusionBusy(false)
    } catch (error) {
      setZWaveInclusionBusy(false)
      showError({ message: 'Z-wave inclusion cancelation failed' })
    }
  }

  const zWaveMenuId = 'zwave-menu'
  const renderZWaveMenu = (
    <Menu
      anchorEl={zWaveMenuAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={zWaveMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isZWaveMenuOpen}
      onClose={handleZWaveMenuClose}
    >

      {!pairingBusy && !zWaveExclusionBusy && !zWaveInclusionBusy && (
        <StyledMenuItem
          disabled={zWaveHealingnBusy}
          onClick={() => { handleZWaveMenuClose(); handleStartZwaveHealing() }}
          color="green"
          aria-label="Heal network"
        >
          <ListItemIcon>
            <HealingTwoToneIcon className={classes.greenIcon} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('Heal zwave network')} />
        </StyledMenuItem>
      )}


      <StyledMenuItem
        onClick={() => { handleZWaveMenuClose(); confirmStartZwaveExclusion() }}
        aria-label={t('Remove zwave device')}
      >
        <ListItemIcon>
          <DeleteIcon className={classes.redIcon} fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('Remove zwave device')} />
      </StyledMenuItem>


      <StyledMenuItem
        onClick={() => { handleZWaveMenuClose(); handleStartZWaveNetworkDetails() }}
        aria-label={t('Z-Wave network details')}
      >
        <ListItemIcon>
          <PermScanWifiTwoToneIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('Z-Wave network details')} />
      </StyledMenuItem>

    </Menu>
  )

  // if (!isEmpty(devices[cozifyHubId])) {
  return (
    <Box my={1} mx={1}>
      { !showZWaveConfiguration && (
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" onClick={back}>
            {`${t('Targets')}`}
          </Link>
          <Typography variant="h4" component="h2" className={classes.breadCrumbHeader}>
            {t('Devices of area', { Area: job.location.stair.concat(' ').concat(job.location.area) })}
          </Typography>
        </Breadcrumbs>
      )}

      { showZWaveConfiguration && (
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" onClick={back}>
            {`${t('Targets')}`}
          </Link>
          <Link color="inherit" onClick={handleZWaveConfigurationDone}>
            {t('Devices of area', { Area: job.location.stair.concat(' ').concat(job.location.area) })}
          </Link>
          <Typography variant="h4" component="h2" className={classes.breadCrumbHeader}>
            {t('Nodes available')}
          </Typography>
        </Breadcrumbs>

      )}
      { !showZWaveConfiguration && !showManualConfiguration && !zWaveNodesBusy && (
        <div className={classes.flexGrow}>
          <AppBar position="static" style={{ backgroundColor: '#666' }}>
            <Toolbar>
              <div className={classes.iconButtons}>
                {!pairingBusy && (

                  <IconButton
                    edge="end"
                    aria-label={t('Start discovery')}
                    classes={{ label: classes.iconButtonLabel }}
                    onClick={handleStartPairing}
                    className={classes.menuIcon}
                    disabled={zWaveExclusionBusy}
                  >
                    <AlarmAddTwoToneIcon />
                    <div className={classes.iconButtonLabelTxt}>
                      {t('Start discovery')}
                    </div>
                  </IconButton>


                )}
                {pairingBusy && (
                  <IconButton
                    edge="end"
                    aria-label={t('Stop discovery')}
                    classes={{ label: classes.iconButtonLabel }}
                    className={classes.menuIcon}
                    onClick={handleStopPairing}
                    disabled={zWaveInclusionBusy || modbusDiscoveryBusy || showManualConfiguration || showZWaveConfiguration}
                  >
                    <AlarmOffTwoToneIcon />
                    <div className={classes.iconButtonLabelTxt}>
                      {t('Stop discovery')}
                    </div>
                  </IconButton>
                )}

                {isModbus && pairingBusy && (
                  <IconButton
                    aria-label={t('Add Modbus devices')}
                    classes={{ label: classes.iconButtonLabel }}
                    className={classes.menuIcon}
                    onClick={handleListDiscoverModbusDevices}
                    disabled={zWaveExclusionBusy || zWaveInclusionBusy || showManualConfiguration || modbusDiscoveryBusy || showZWaveConfiguration}
                  >
                    <div className={classes.iconButtonLabelChar}>+Modbus</div>
                    <div className={classes.iconButtonLabelTxt}>
                      {t('Add Modbus devices')}
                    </div>
                  </IconButton>
                )}

                {isZwaves && pairingBusy && !zWaveInclusionBusy && (
                  <IconButton
                    aria-label={t('Discover zwave device')}
                    classes={{ label: classes.iconButtonLabel }}
                    className={classes.menuIcon}
                    onClick={handleStartZwaveInclusion}
                    disabled={modbusDiscoveryBusy || showManualConfiguration || zWaveExclusionBusy || zWaveInclusionBusy || showZWaveConfiguration}
                  >
                    <div className={classes.iconButtonLabelChar}>+Z-Wave</div>
                    <div className={classes.iconButtonLabelTxt}>
                      {t('Discover zwave device')}
                    </div>
                  </IconButton>
                )}

                {isZwaves && zWaveInclusionBusy && (
                  <IconButton
                    aria-label={t('Cancel zwave discovery')}
                    classes={{ label: classes.iconButtonLabel }}
                    className={classes.menuIcon}
                    onClick={handleCancelZwaveInclusion}
                  >
                    <PanToolTwoToneIcon />
                    <div className={classes.iconButtonLabelTxt}>
                      {t('Cancel zwave discovery')}
                    </div>

                  </IconButton>
                )}

                {isZwaves && zWaveExclusionBusy && (
                  <IconButton
                    aria-label={t('Cancel zwave discovery')}
                    classes={{ label: classes.iconButtonLabel }}
                    className={classes.menuIcon}
                    onClick={handleCancelZwaveExclusion}
                  >
                    <PanToolTwoToneIcon />
                    <div className={classes.iconButtonLabelTxt}>
                      {t('Cancel zwave device removal')}
                    </div>
                  </IconButton>
                )}
                {isZwaves && !pairingBusy && !zWaveNodesBusy && (
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={zWaveMenuId}
                    aria-haspopup="true"
                    classes={{ label: classes.iconButtonLabel }}
                    onClick={handleZWaveMenuOpen}
                    className={classes.rightMenuIcon}
                    disabled={pairingBusy || modbusDiscoveryBusy || showManualConfiguration || zWaveExclusionBusy || zWaveInclusionBusy || showZWaveConfiguration}
                  >
                    <TuneTwoToneIcon />
                    <div className={classes.iconButtonLabelTxt}>
                      {t('Manage Z-Wave network')}
                    </div>
                  </IconButton>
                )}
              </div>
            </Toolbar>
            {pairingBusy && (
              <LinearProgress
              // color="secondary"
                variant="determinate"
                value={completed}
                disableShrink
                // size={18}
                // thickness={4}
                className={classes.progress}
              />
            )}

          </AppBar>
          {!pairingBusy && !zWaveInclusionBusy && !zWaveExclusionBusy && !zWaveNodesBusy && renderZWaveMenu}
        </div>
      )}

      <Divider className={classes.divider} />

      { showManualConfiguration > 0 && (
        <div>
          <ModbusConfiguration
            cozifyHubId={cozifyHubId}
            devices={manualDevices}
            pairModbus={handlePairModbus}
            handleManualPairingDone={handleManualPairingDone}
          />
        </div>
      )}

      {zWaveNodesBusy && (
        <Typography className={classes.progressContainer}>
          <CircularProgress
            className={classes.circularProgress}
            disableShrink
            size={20}
            thickness={4}
            color="primary"
            variant="indeterminate"
          />
        </Typography>
      )}
      { showZWaveConfiguration > 0 && (
        <div>
          <ZWaveConfiguration
            devices={devices[cozifyHubId]}
            rooms={rooms[cozifyHubId]}
            cozifyHubId={cozifyHubId}
            nodes={zWaveNodes}
            handleStartZWaveNetworkDetails={handleStartZWaveNetworkDetails}
            handleZWaveConfigurationDone={handleZWaveConfigurationDone}
            handleDeviceUnPair={handleDeviceUnPair}
            checkIsFailedZWaveNode={checkIsFailedZWaveNode}
            removeFailedZWaveNode={removeFailedZWaveNode}
            getZWaveNodeParameter={getZWaveNodeParameter}
            setZWaveNodeParameter={setZWaveNodeParameter}
          />
        </div>
      )}

      { !showManualConfiguration && !showZWaveConfiguration && !zWaveNodesBusy && devicesByType.map((devType) => (
        <ExpansionPanel
          key={devType.name}
          expanded={expanded === devType.name}
          onChange={handleExPanelChange(devType)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={devType.name.concat('-content')}
            id={devType.name.concat('-header')}
          >
            <Typography className={classes.heading}>
              {t(devType.name.concat('-devices'))}
              {(devType.isPairings || devType.isNewAdded) && (
                <NewReleasesTwoToneIcon className={classes.newDevice} />
              )}
              {(devType.isNewAdded) && (
                <Typography className={classes.explanation} variant="caption" component="span">
                  {t('Added')}
                </Typography>
              )}


            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails className={classes.flexRow}>
            <DeviceTabs
              label1={t('Unpaired')}
              label2={t('Paired')}
              pairing={pairingBusy}
              handleStopPairing={handleStopPairing}
              handleDeviceUnPair={handleDeviceUnPair}
              visible={expanded}
              token={token}
              hubId={cozifyHubId}
              devices={devType.devices}
              rooms={rooms[cozifyHubId]}
              newAdded={devType.isNewAdded}
              pairingsFound={devType.isPairings}
              devicesFound={devType.isPaireds}
              locale={locale}
              hubMeasurements={measurements}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}

    </Box>
  )
}

const mapStateToProps = (state) => ({
  alarms: state.alarms,
  devices: state.devices,
  hubs: state.hubs,
  pairings: state.pairings,
  rooms: state.rooms,
  user: state.user,
})

const mapDispatchToProps = {
}


const ConnectedDevices = connect(mapStateToProps, mapDispatchToProps)(Devices)

export default ConnectedDevices
