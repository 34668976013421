import React, { useState } from 'react'
import { connect, Provider } from 'react-redux'
//import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'


import {
  store as cozifyStore,
  // getPlans,
  // listPlans,
  // loadPlan,
  // simplifyPlans,
  // savePlans,
  // addRoomName,
  // removeRoomName,
  // addDeviceType,
  // removeDeviceType,
  // addSceneType,
  // removeSceneType,
  // addRuleType,
  // removeRuleType,
  // addTemplate,
  // setTemplate,
  // removeTemplate,
  // addLocationNode,
  // setLocationNode,
  // removeLocationNode,
  // PLAN_NODES,
} from 'cozify-sdk'

//import { isEmpty, dateOptions } from '../utils'


import Plans from './Plans'

/*
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },

  listRoot: {
    width: '100%',
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  list: {
    width: '100%',
    minHeight: 500,
  },

  header: {
    marginTop: 20,
    color: theme.palette.text.secondary,
  },
  subHeader: {
    marginTop: 20,
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  headerItem: {
    minHeight: 30,
  },
  button: {
    margin: theme.spacing(1),
  },
  tree: {
    display: 'flex',
    width: '100%',
    minWidth: 800,
  },

  textView: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textViewArea: {
    resize: 'none',
    width: '90%',
    height: '90%',
    paddingLeft: 25,
    color: 'dimgrey',
    fontSize: '8pt',
    fontFamily: '"Consolas", "Lucida Console", "Monaco", "Menlo", monospace',
    borderLeft: '1px solid gainsboro',
  },
}))
*/
const Planning = (props) => {
  const { t } = useTranslation()
  
  /*
  const classes = useStyles()
  const {
    templates,
  } = props
  */

  // const [newPlan, setNewPlan] = useState({})
  // const [locations, setLocations] = useState({})

  // eslint-disable-next-line
  const [showPlansList, setShowPlansList] = useState(true)


  return (
    <Box my={1} mx={1}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={1}
      >

        <Grid item xs={12}>
          <Typography variant="h4" component="h2">
            {` ${t('Planning')}: `}
            {/* JSON.stringify(plan) */}
          </Typography>
        </Grid>

        {showPlansList && (
          <Provider store={cozifyStore}>
            <Plans />
          </Provider>
        )}

      </Grid>
    </Box>
  )
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const ConnectedPlanning = connect(mapStateToProps, mapDispatchToProps)(Planning)
export default ConnectedPlanning
