import React, { useState, useEffect } from 'react'
import {
  useSelector, useDispatch,
} from 'react-redux'

import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import {
  ArrowForwardIosTwoTone,
  DeleteTwoTone,
  DoneTwoTone,
  EditTwoTone,
  ExpandLessTwoTone,
  ExpandMoreTwoTone,
  InsertDriveFileTwoTone,
} from '@material-ui/icons'

import {
  Avatar,
  Grid,
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'

import {
  reactSelectPlanDocuments,
  reactSubscribePlanDocuments,
  reactInsertPlanDocument,
  reactUpdatePlanDocument,
  reactRemovePlanDocument,
} from 'cozify-sdk'

import { dateOptions } from '../utils'
import { showError, askConfirmation } from '../services/notifications'
import Nodes from './Nodes'

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: '100%',
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  list: {
    width: '100%',
    minHeight: 500,
  },
  subHeader: {
    marginTop: 0,
    color: theme.palette.text.secondary,
  },
  listItemIcon: {
    paddingLeft: 20,
  },
  listItemAlone: {
    paddingLeft: 20,
  },
  removeIcon: {
    color: 'red',
    '&:hover': {
      color: 'orangered',
    },
  },
}))

const Documents = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    planId,
  } = props

  const [showDocumentList, setShowDocumentList] = useState(true)
  const [editMode, setEditMode] = useState(false)
  const [selectedDocumentId, setSelectedDocumentId] = useState(undefined)
  const [selectedDocument, setSelectedDocument] = useState(undefined)
  const [currentPlanId, setCurrentPlanId] = useState(undefined)
  const [documentName, setDocumentName] = useState('')
  const [documentAuthor, setDocumentAuthor] = useState('')
  const [documentDate, setDocumentDate] = useState('')
  const dispatch = useDispatch()

  const documents = useSelector((state) => reactSelectPlanDocuments(state, planId))
  console.error(documents)

  useEffect(() => {
    if (planId !== currentPlanId) {
      setCurrentPlanId(planId)
      setShowDocumentList(true)
    }
    dispatch(reactSubscribePlanDocuments({ planId }))
      .then((response) => {
        console.info('reactFetchPlans ')
      })
      .catch((error) => {
        debugger
        showError({ message: 'Reading failed' })
      })
  }, [currentPlanId, dispatch, planId])

  // const documents = useSelector(reactSelectAllDocuments)
  // const allDocuments = useSelector((state) => state.documents)

  const handleEditMode = () => {
    setEditMode(!editMode)
  }
  const resetInputFields = () => {
    setDocumentName('')
    setDocumentAuthor('')
  }

  const handleNameChange = (event) => {
    const name = event.target.value
    setDocumentName(name)
  }

  /*
  const handleAuthorChange = (event) => {
    const author = event.target.value
    setDocumentAuthor(author)
  }
  */

  const handleSelectDocument = (document) => {
    setShowDocumentList(false)
    setSelectedDocumentId(document.uid)
    setSelectedDocument(document)
    setDocumentName(document.name || '')
    setDocumentAuthor(document.author || '')
    setDocumentDate(document.created_at || document.changed_at)
  }

  const handleInsertDocument = (event) => {
    dispatch(reactInsertPlanDocument({ planId, changes: { name: documentName, author: documentAuthor } }))
      .then((response) => {
        resetInputFields()
        console.info('handleInsertPlanDocument ok: ')
      })
      .catch((error) => {
        debugger
        showError({ message: 'Saving failed' })
      })
  }

  const handleSaveDocument = (event) => {
    dispatch(reactUpdatePlanDocument({ uid: selectedDocumentId, changes: { name: documentName, author: documentAuthor } }))
      .then((response) => {
        console.info('handleSavePlan ok: ')
        setEditMode(false)
      })
      .catch((error) => {
        debugger
        showError({ message: 'Saving failed' })
      })
  }

  const removeDocument = () => {
    dispatch(reactRemovePlanDocument({ uid: selectedDocumentId }))
      .then((response) => {
        console.info('removeDocument ok: ')
        setShowDocumentList(true)
        setSelectedDocumentId(undefined)
        resetInputFields()
      })
      .catch((error) => {
        debugger
        showError({ message: 'Saving failed' })
      })
  }

  const handleRemoveDocument = () => {
    askConfirmation({
      message: 'Are you sure to remove',
      cancel: () => { console.log('Canceled') },
      ok: () => { removeDocument() },
      timeout: -1,
    })
  }

  const AddRow = () => (
    <ListItem button>
      <ListItemAvatar>
        <Avatar>
          <InsertDriveFileTwoTone />
        </Avatar>
      </ListItemAvatar>
      {/* <ListItemIcon>
        <IconButton
          className={classes.addIcon}
          aria-label="add"
          edge="end"
        >
          <AddTwoTone className="" />
        </IconButton>
      </ListItemIcon> */}
      <ListItemText>
        {t('New version')}
      </ListItemText>
      <ListItemText>
        <Input
          id={`documentname-${selectedDocumentId}`}
          // eslint-disable-next-line react/jsx-props-no-spreading
          onChange={handleNameChange}
          type="text"
          required
          value={documentName || ''}
          placeholder={t('Name')}
          className={classes.inlineInput}
          inputProps={{
            'aria-label': t('Name'),
          }}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="save"
                onClick={handleInsertDocument}
                edge="end"
              >
                <DoneTwoTone className="" />
              </IconButton>
            </InputAdornment>
          )}
        />
      </ListItemText>
    </ListItem>
  )

  const DocumentRows = ({ index, style }) => {
    if (documents.length > 0) {
      const document = documents[index]
      return (
        <ListItem
          button
          key={index}
          style={style}
          selected={selectedDocumentId && (selectedDocumentId === document.uid)}
          onClick={() => { handleSelectDocument(document) }}
        >
          <ListItemAvatar>
            <Avatar>
              <InsertDriveFileTwoTone />
            </Avatar>
          </ListItemAvatar>
          {document.changed_at && (
            <ListItemText primary={(document.name) || ''} secondary={new Intl.DateTimeFormat('default', dateOptions).format(new Date(document.changed_at))} />
          )}
          {!document.changed_at && document.created_at && (
            <ListItemText primary={(document.name) || ''} secondary={new Intl.DateTimeFormat('default', dateOptions).format(new Date(document.created_at))} />
          )}
          {!document.changed_at && !document.created_at && (
            <ListItemText primary={(document.name) || ''} />
          )}
          <ListItemIcon>
            <ArrowForwardIosTwoTone style={{ fontSize: 12 }} />
          </ListItemIcon>
        </ListItem>
      )
    }

    return null
  }


  return (
    <Grid item xs={12}>
      <Grid
        container
        className={classes.subHeader}
        justify="center"
        alignItems="center"
        onClick={() => setShowDocumentList(!showDocumentList)}
      >
        <Grid item xs={11}>
          <Typography gutterBottom>
            {`${t('Versions')}:`}
          </Typography>
        </Grid>
        <Grid item xs={1} align="right">
          {!showDocumentList && (<ExpandMoreTwoTone />)}
          {showDocumentList && (<ExpandLessTwoTone />)}
        </Grid>
      </Grid>
      {showDocumentList && documents && (
        <Paper className={classes.listRoot}>
          <AutoSizer className={classes.list} defaultHeight="1000">
            {({ height, width }) => (
              <FixedSizeList
                className="List"
                height={height}
                itemCount={documents.length}
                itemSize={100}
                width={width}
              >
                {DocumentRows}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Paper>
      )}
      {showDocumentList && documents && (
        <List>
          {AddRow()}
        </List>
      )}
      {selectedDocumentId && !showDocumentList && (
        <List>

          <ListItem className={classes.listItemIcon}>
            <ListItemAvatar>
              <Avatar>
                <InsertDriveFileTwoTone />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={(documentName) || ''} secondary={(documentDate) || ''} />
            <ListItemIcon>
              <IconButton
                className={classes.editIcon}
                aria-label="edit"
                onClick={handleEditMode}
                edge="end"
              >
                <EditTwoTone className="" />
              </IconButton>

              <IconButton
                className={classes.removeIcon}
                aria-label="remove"
                onClick={handleRemoveDocument}
                edge="end"
              >
                <DeleteTwoTone className="" />
              </IconButton>
            </ListItemIcon>
          </ListItem>

          {editMode && (
            <ListItem className={classes.listItemIcon}>
              <ListItemText>
                <InputLabel>
                  {t('Name')}
                </InputLabel>
                <Input
                  id={`planname-${currentPlanId}`}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  onChange={handleNameChange}
                  type="text"
                  required
                  value={documentName || ''}
                  placeholder={t('Name')}
                  className={classes.inlineInput}
                  inputProps={{
                    'aria-label': t('Name'),
                  }}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="save"
                        onClick={handleSaveDocument}
                        edge="end"
                      >
                        <DoneTwoTone className="" />
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </ListItemText>
            </ListItem>
          )}
          {editMode && (
            <ListItem className={classes.listItemAlone}>
              <ListItemText>
                <InputLabel>
                  {t('Author')}
                </InputLabel>
                <Input
                  id={`planauthor-${currentPlanId}`}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  // onChange={(handleAuthorChange)}
                  onChange={(event) => setDocumentAuthor(event.target.value)}
                  type="text"
                  required
                  value={documentAuthor || ''}
                  placeholder={t('Author')}
                  className={classes.inlineInput}
                  inputProps={{
                    'aria-label': t('Author'),
                  }}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="save"
                        onClick={handleSaveDocument}
                        // onMouseDown={handleSaveDocument}
                        edge="end"
                      >
                        <DoneTwoTone className="" />
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </ListItemText>
            </ListItem>
          )}
        </List>
      )}
      {selectedDocumentId && !showDocumentList && selectedDocument && (
        <Nodes
          documentId={selectedDocumentId}
          templates={selectedDocument.template_types}
          roomNames={selectedDocument.room_types}
          deviceTypes={selectedDocument.scene_types}
          sceneTypes={selectedDocument.device_types}
          ruleTypes={selectedDocument.rule_types}
        />
      )}
    </Grid>

  )
}


export default Documents
