import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// import { showError } from '../services/notifications'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { isEmpty } from '../utils'
import Locations from './Locations'
import Building from './Building'
import Apartment from './Apartment'

const useStyles = makeStyles((theme) => ({
  '@media print': {
    noPrint: {
      display: 'none !important',
    },
    divider: {
      display: 'none !important',
    },
    headerItemLocations: {
      display: 'none !important',
    },
    headerItemApartment: {
      display: 'none !important',
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  headerItemLocations: {
    minHeight: 30,
  },
  headerItemBuilding: {
    minHeight: 30,
  },
  headerItemApartment: {
    minHeight: 30,
  },
}))

const Home = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { user, job, setJob } = props
  const [jobs, setJobs] = useState([])
  const [reloadJobs, setReloadJobs] = useState(false)
  const [reloadBuildingJobs, setReloadBuildingJobs] = useState(false)
  const [building, setBuilding] = useState('')
  const [showLocationList, setShowLocationList] = useState(true) // sites
  const [showJobList, setShowJobList] = useState(true) // apartments to be worked in selected site
  const [showApartment, setShowApartment] = useState(true) // selected apartments

  useEffect(() => {
    console.log('Home EFFECT 1', user)
    console.log('Home EFFECT 2 showLocationList', showLocationList)
    console.log('Home EFFECT 3 showJobList', showJobList)
    console.log('Home EFFECT 4 showApartment', showApartment)
  })

  const selectBuilding = (selectedBuilding, selectedJobs) => {
    // debugger
    setJob({})
    setReloadBuildingJobs(true)
    setBuilding(selectedBuilding)
    setJobs(selectedJobs)
    setShowLocationList(false)
    setShowJobList(true)
    setShowApartment(false)
    console.debug('Home: selectBuilding', selectedJobs)
  }

  const updateJobs = (newJobs) => {
    setJobs(newJobs)
    const selectedJob = newJobs.find((j) => (j.id === job.id))
    if (selectedJob) {
      setJob(selectedJob)
    }
    console.debug('Home: updateJobs', newJobs)
  }

  const updateJob = (jobToBeUpdated, selectedJob) => {
    jobs.forEach((j, index) => {
      if (jobs[index].id === jobToBeUpdated.id) {
        jobs[index] = jobToBeUpdated
      }
    })

    // const selectedJob = jobs.find((j) => (j.id === job.id))
    if (selectedJob.id === jobToBeUpdated.id) {
      // setJob(jobToBeUpdated)
      setShowApartment(false)
      setTimeout(() => {
        setShowApartment(true)
      }, 500)
    }

    setJobs(jobs)
    console.info('Home: updateJob ', jobToBeUpdated)
  }


  const selectJob = (selectedJob) => {
    if (!reloadJobs) {
      setJob(selectedJob)
      setShowApartment(true)
      console.debug('Home: selectJob', selectedJob)
    }
  }

  const mappingDone = () => {
    setJob({})
    setReloadJobs(true)
    setTimeout(() => {
      setShowJobList(true)
      setShowApartment(false)
      console.debug('Home: mappingDone', job)
    }, 10)
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }
  const unMappingDone = () => {
    setJob({})
    setReloadJobs(true)
    setTimeout(() => {
      setShowJobList(true)
      setShowApartment(false)
      console.debug('Home: unMappingDone', job)
    }, 10)
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }

  return (
    <Box my={1} mx={1}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={1}
      >
        <Grid item xs={12} className={classes.noPrint}>
          <Typography variant="h4" component="h2">
            {t('Targets')}
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        <Grid className={classes.headerItemLocations} item xs={12}>
          <Locations
            showLocationList={showLocationList}
            setShowLocationList={setShowLocationList}
            building={building}
            selectBuilding={selectBuilding}
            updateJobs={updateJobs}
            reloadJobs={reloadJobs}
            setReloadJobs={setReloadJobs}
          />
        </Grid>

        {!isEmpty(jobs)
        && (
          <Grid className={classes.headerItemBuilding} item xs={12}>
            <Building
              building={building}
              showJobList={showJobList}
              setShowJobList={setShowJobList}
              jobs={jobs}
              selectJob={selectJob}
              reloadJobs={reloadJobs}
              reloadBuildingJobs={reloadBuildingJobs}
              setReloadBuildingJobs={setReloadBuildingJobs}
              updateJob={updateJob}
            />
          </Grid>
        )}

        {!isEmpty(job)
        && (
          <Grid className={classes.headerItemApartment} item xs={12}>
            <Apartment
              showApartment={showApartment}
              setShowApartment={setShowApartment}
              jobs={jobs}
              job={job}
              mappingDone={mappingDone}
              unMappingDone={unMappingDone}
            />
          </Grid>
        )}

      </Grid>
    </Box>
  )
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const ConnectedHome = connect(mapStateToProps, mapDispatchToProps)(Home)
export default ConnectedHome
