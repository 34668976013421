import React, { useState } from 'react'
import './NodeEditable.css'
import RemoveCircleOutlineTwoToneIcon from '@material-ui/icons/RemoveCircleOutline'
import RouterTwoToneIcon from '@material-ui/icons/RouterTwoTone'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/TextField'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItem from '@material-ui/core/ListItem'
import ArrowForwardIosTwoTone from '@material-ui/icons/ArrowForwardIosTwoTone'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
// import { useTranslation } from 'react-i18next'
import useStyles from './EditStyles'
import Rooms from './Rooms'
import DeviceTypes from './DeviceTypes'
import SceneTypes from './SceneTypes'
import RuleTypes from './RuleTypes'

const TemplateEditable = (props) => {
  // const { t } = useTranslation()
  const classes = useStyles()
  const {
    title,
    open,
    changeTitle,
    removeTemplate,
    toggle,
    handleChangeRooms,
    selectedRooms,
    roomNames,
    handleAddRoomName,
    handleRemoveRoomName,
    readOnly,
    selectTemplate,
    deviceTypes,
    selectedDeviceTypes,
    handleAddDeviceType,
    handleChangeDeviceTypes,
    handleRemoveDeviceType,
    sceneTypes,
    selectedSceneTypes,
    handleAddSceneType,
    handleChangeSceneTypes,
    handleRemoveSceneType,
    ruleTypes,
    selectedRuleTypes,
    handleAddRuleType,
    handleChangeRuleTypes,
    handleRemoveRuleType,
  } = props
  const [inputValue, setInputValue] = useState(title)


  if (readOnly) {
    return (
      <ListItem
        className={classes.root}
        selected={false}
        button
        key="add-location"
        onClick={() => selectTemplate()}
      >
        <ListItemIcon>
          <RouterTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary={inputValue} />
        <ListItemIcon>
          <ArrowForwardIosTwoTone style={{ fontSize: 12 }} />
        </ListItemIcon>
      </ListItem>
    )
  }

  return (
    <div className={classes.root}>
      <div className="EditableItem">
        <IconButton
          aria-label="Expand"
          className="EditableItem-IconButton"
          onClick={toggle}
        >
          {!open && (
            <ExpandMoreTwoToneIcon className="EditableItem-Icon" />
          )}
          {open && (
            <ExpandLessTwoToneIcon className="EditableItem-Icon" />
          )}
        </IconButton>


        <Input
          // onClick={toggle}
          inputProps={{ 'aria-label': 'Name', style: { fontSize: 14 } }}
          className="EditableItem-Text"
          onChange={(e) => { setInputValue(e.target.value) }}
          onBlur={() => { changeTitle(inputValue) }}
          value={inputValue}
          disabled={readOnly}
        />


        <IconButton
          aria-label="Remove"
          className="EditableItem-IconButton "
          onClick={removeTemplate}
        >
          <RemoveCircleOutlineTwoToneIcon className="EditableItem-Icon EditableItem-Icon_remove" />
        </IconButton>


      </div>
      {open && (
        <div>
          <Rooms
            handleChangeRooms={handleChangeRooms}
            selectedRooms={selectedRooms}
            roomNames={roomNames}
            handleAddRoomName={handleAddRoomName}
            handleRemoveRoomName={handleRemoveRoomName}
            readOnly={readOnly}
          />

          <DeviceTypes
            deviceTypes={deviceTypes}
            selectedDeviceTypes={selectedDeviceTypes}
            handleAddDeviceType={handleAddDeviceType}
            handleChangeDeviceTypes={handleChangeDeviceTypes}
            handleRemoveDeviceType={handleRemoveDeviceType}
            readOnly={readOnly}
          />

          <SceneTypes
            sceneTypes={sceneTypes}
            selectedSceneTypes={selectedSceneTypes}
            handleAddSceneType={handleAddSceneType}
            handleChangeSceneTypes={handleChangeSceneTypes}
            handleRemoveSceneType={handleRemoveSceneType}
            readOnly={readOnly}
          />

          <RuleTypes
            ruleTypes={ruleTypes}
            selectedRuleTypes={selectedRuleTypes}
            handleAddRuleType={handleAddRuleType}
            handleChangeRuleTypes={handleChangeRuleTypes}
            handleRemoveRuleType={handleRemoveRuleType}
            readOnly={readOnly}
          />
        </div>
      )}
    </div>

  )
}

export default TemplateEditable
