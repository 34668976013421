import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
import RouterTwoToneIcon from '@material-ui/icons/RouterTwoTone'
import ArrowForwardIosTwoTone from '@material-ui/icons/ArrowForwardIosTwoTone'
import LinkTwoToneIcon from '@material-ui/icons/LinkTwoTone'
import IconButton from '@material-ui/core/IconButton'
import PersonAddTwoToneIcon from '@material-ui/icons/PersonAddTwoTone'
import PersonAddDisabledTwoToneIcon from '@material-ui/icons/PersonAddDisabledTwoTone'

import { useTranslation } from 'react-i18next'
import {
  Link as RouterLink, withRouter,
} from 'react-router-dom'

import {
  selectCloud, doRemoteIdQuery, lockAndBackup, getDevices,
} from 'cozify-sdk'
import { useField } from '../hooks'
// import QRScan from './QRScan'
import BarcodeReader from './BarcodeReader'


import locationService from '../services/locations'
import { showError, showInfo, askConfirmation } from '../services/notifications'
import { isEmpty } from '../utils'

const useStyles = makeStyles((theme) => ({
  '@media print': {
    noPrint: {
      display: 'none !important',
    },
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    overflowX: 'auto',
  },
  form: {
    width: '100%',
  },
  input: {
    margin: theme.spacing(1),
  },
  error: {
    color: 'red',
  },
  button: {
    margin: theme.spacing(1),
  },
  hubButtons: {
    margin: theme.spacing(1),
    marginBottom: 0,
    marginTop: 0,
  },
  hubNote: {
    fontSize: '.9em',
    margin: theme.spacing(1),
    color: '#333',
  },
  hubNotePre: {
    fontWeight: 500,
    fontSize: '.9em',
    marginBottom: 0,
    marginTop: 0,
    margin: theme.spacing(1),
    color: '#333',
  },
  hubNoteHeader: {
    margin: theme.spacing(1),
    marginBottom: 0,
    marginTop: theme.spacing(1),
    color: '#222',
  },
  hubNoteImportant: {
    fontSize: '.9em',
    margin: theme.spacing(1),
    color: 'red',
  },
  mapButton: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(1),
  },
  unmapButton: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(1),
  },
  hubLink: {
    margin: theme.spacing(1),
    color: theme.palette.text.primary,
    textDecoration: 'none',
    display: 'block',
  },
  header: {
    color: theme.palette.text.secondary,
  },
}))


const Apartment = (props) => {
  const {
    job, jobs, showApartment, setShowApartment, user, mappingDone, unMappingDone,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const sn = useField('text')
  const [hubInfo, setHubInfo] = useState(null)
  const [hubConnected, setHubConnected] = useState(false)
  const [userRoles, setUserRoles] = useState({})

  const handleReadedCode = (code) => {
    const event = {
      target: {
        value: code,
      },
    }
    sn.onChange(event)
  }


  const askHubAdminAccess = async () => {
    const selectedJob = jobs.find((j) => j.id === job.id)

    if (selectedJob && selectedJob.location) {
      const reply = await locationService.addAdminAccess({
        token: user.access_token,
        job: selectedJob,
      })
      if (isEmpty(userRoles.filter((role) => role.email === user.email))) {
        const roles = [...userRoles]
        roles.push({ email: user.email, role: 32, roleName: 'Admin' })
        setUserRoles(roles)
      }

      if (reply) {
        console.log('Adding admin access ok', reply)
        showInfo({ message: 'Adding admin access done' })
        mappingDone()
      } else {
        showError({ message: 'Adding admin access failed' })
      }
    } else {
      showError({ message: 'Missing info from adding admin access' })
    }
  }
  const removeHubAdminAccess = async () => {
    const selectedJob = jobs.find((j) => j.id === job.id)

    if (selectedJob && selectedJob.location) {
      const reply = await locationService.removeAdminAccess({
        token: user.access_token,
        job: selectedJob,
      })
      const roles = userRoles.filter((role) => role.email !== user.email)
      setUserRoles(roles)
      if (reply) {
        console.log('Removing admin access ok', reply)
        showInfo({ message: 'Removing admin access done' })
        mappingDone()
      } else {
        showError({ message: 'Removing admin access failed' })
      }
    } else {
      showError({ message: 'Missing info from removing admin access' })
    }
  }
  const lockAndBackupHub = async () => {
    if (job && job.hub && job.hub.id) {
      const cozifyToken = `${job.hub.keys.cozifyCloudToken}` || null
      const cozifyHubKey = job.hub.keys.cozifyHubKey || null
      selectCloud(`${process.env.REACT_APP_DIRECTORY_URL}/directory/device/site/${job.siteId}/`)
      lockAndBackup(job.hub.id, cozifyToken, cozifyHubKey).then(() => {
        showInfo({ message: 'Current configurations locked' })
      }).catch((e) => {
        console.error('Hub lockAndBackupHub failed ', e)
        showError({ message: 'Configurations locking failed' })
      })
    }
  }

  const handleMap = async (event) => {
    event.preventDefault()
    console.info('Apartment handleMap: ', sn)
    if (sn.value.length < 5) {
      showError({ message: 'Missing serial number from owner mapping' })
      return
    }

    // const hubId = await locationService.snToId(user.access_token, sn.value)
    // setId(hubId)
    const selectedJob = jobs.find((j) => j.id === job.id)
    if (!sn.value) {
      showError({ message: 'Missing hubId from owner mapping' })
      return
    }
    if (selectedJob && selectedJob.location) {
      const reply = await locationService.doOwnerMapping({
        token: user.access_token,
        job: selectedJob,
        sn: sn.value,
      })

      if (reply) {
        console.log('Owner mapping ok', reply)
        showInfo({ message: 'Owner mapping done' })
        mappingDone()
      } else {
        showError({ message: 'Owner mapping failed' })
      }
    } else {
      showError({ message: 'Missing job info from owner mapping' })
    }
  }

  const handleUnMap = async () => {
    const selectedJob = jobs.find((j) => j.id === job.id)
    if (selectedJob && selectedJob.hubId) {
      console.info('Apartment unmap: ', selectedJob.hubId)
      const reply = await locationService.doOwnerUnMapping({
        token: user.access_token,
        job: selectedJob,
      })
      if (reply) {
        console.log('Owner unmapping ok', reply)
        showInfo({ message: 'Owner unmapping done' })
        unMappingDone()
      } else {
        showError({ message: 'Owner unmapping failed' })
      }
    } else {
      showError({ message: 'Missing info from owner unmapping' })
    }
  }

  const askUnMapConfirmation = () => {
    askConfirmation({
      message: 'Are you sure to unmap?',
      cancel: () => { console.log('Canceled') },
      ok: () => { handleUnMap() },
      timeout: -1,
    })
  }

  useEffect(() => {
    console.log('Apartment EFFECT job', job)

    if (job && job.hub && job.hub.id && job.hub.keys) {
      // To test hub connection:
      const cozifyToken = job.hub.keys.cozifyCloudToken || null
      const cozifyHubKey = job.hub.keys.cozifyHubKey || null
      if (job) {
        selectCloud(`${process.env.REACT_APP_DIRECTORY_URL}/directory/device/site/${job.siteId}/`)
        doRemoteIdQuery(job.hub.id, cozifyToken, cozifyHubKey).then((info) => {
          if (info.name) {
            job.hub.connected = info.connected
            job.hub.features = info.features
            job.hub.name = info.name
            job.hub.currentVersionId = info.version
            setHubInfo(info)
            setHubConnected(info.connected)
          }
        }).catch((e) => {
          console.error('Hub not remote connected ', e)
          setHubConnected(false)
          // showError({ message: 'Hub connection failed' })
        })
      }
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(job),
    // eslint-disable-next-line
    JSON.stringify(job.hub),
  ])

  const roles = {
    1: 'Anonymous',
    2: 'Guest',
    8: 'User',
    32: 'Admin',
    64: 'Owner',
    128: 'CozifyAdmin',
  }
  useEffect(() => {
    // this is not triggered, even jub.hub.users is set
    console.log('Apartment EFFECT', job.hub.users)
    const roleA = []
    let sortedRoles = []
    if (job && job.hub && job.hub.users) {
      job.hub.users.forEach((user) => {
        if (user && user.user_info && user.user_info.user && user.user_info.user.email) {
          const role = {
            email: user.user_info.user.email,
            role: user.user_info.role,
            roleName: roles[user.user_info.role] ? roles[user.user_info.role] : '?',
          }
          roleA.push(role)
        }
      })
      sortedRoles = roleA.sort((a, b) => {
        const x = a.role
        const y = b.role
        // eslint-disable-next-line no-nested-ternary
        return x < y ? -1 : x > y ? 1 : 0
      })
      console.log('Apartment EFFECT userRoles', sortedRoles)
    }
    setUserRoles(sortedRoles)
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(job.hub.users),
  ])

  const hubForm = () => (
    <form onSubmit={handleMap} className={classes.form} noValidate autoComplete="off">
      <Grid container spacing={1} direction="row" justify="center">
        <Grid item xs={6}>

          <TextField
            id="sn"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sn}
            autoComplete="sn"
            autoFocus
            required
            placeholder={t('Hub serial number')}
            className={classes.input}
            inputProps={{
              'aria-label': t('Serial number'),
            }}
          />

          <Button size="small" color="primary" type="submit" variant="contained" className={classes.button}>{t('Map to job')}</Button>
        </Grid>
        {/*
        <Grid item xs={6}>
          <QRScan setQR={handleReadedCode} />
        </Grid>
        */}
      </Grid>
      <Grid container spacing={1} direction="row" justify="center">
        <Grid item xs={12}>
          <BarcodeReader setBarcode={handleReadedCode} />
        </Grid>
      </Grid>
    </form>
  )
  let waterColdMeter1
  let waterHotMeter1
  let waterColdMeter2
  let waterHotMeter2
  let electricityMeter1
  let electricityMeter2
  if (job && job.hub && job.hub.id && getDevices() && getDevices()[job.hub.id]) {
    const devices = Object.values(getDevices()[job.hub.id])
    devices.forEach((dev) => {
      if (dev.type === 'WATER_METER') {
        if (dev.state.hotWater) {
          if (!waterHotMeter1) {
            waterHotMeter1 = dev
          } else {
            waterHotMeter2 = dev
          }
        } else if (!waterColdMeter1) {
          waterColdMeter1 = dev
        } else {
          waterColdMeter2 = dev
        }
      } else if (dev.type === 'POWER_METER') {
        if (!electricityMeter1) {
          electricityMeter1 = dev
        } else {
          electricityMeter2 = dev
        }
      }
    })
    // console.error('Devices: job.hub.id', JSON.stringify(getDevices()[job.hub.id]))
  }
  return (
    <Box my={1} mx={2}>

      <Grid
        container
        className={classes.header}
        justify="center"
        alignItems="center"
        onClick={() => setShowApartment(!showApartment)}
      >
        <Grid item xs={11}>

          <Typography variant="h6" component="h4" gutterBottom>
            {job.hubId ? t('Owner mapping') : t('Owner mapping') }
            {job.location
              && (
                <span>
                  {` ${job.location.stair} ${job.location.area}`}
                </span>
              )}
            :
          </Typography>
        </Grid>
        <Grid item xs={1} align="right">
          {!showApartment && (<ExpandMoreTwoToneIcon />)}
          {showApartment && (<ExpandLessTwoToneIcon />)}
        </Grid>
      </Grid>

      {showApartment && (
        <Paper className={classes.root}>


          { !job.hub.id
          && (
            <Grid
              container
              className={classes.header}
              justify="center"
              alignItems="center"
              direction="row"
            >
              {hubForm()}

            </Grid>
          )}

          { job.hub && job.hub.id
          && (
            <Grid
              container
              className={classes.header}
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Grid item xs={2} className={classes.hubIcon}>
                {hubConnected && (
                  <RouterLink className={classes.hubLink} to="/devices">
                    <RouterTwoToneIcon color={(hubInfo && hubInfo.connected) ? 'action' : 'error'} />
                  </RouterLink>
                )}
                {!hubConnected && (
                  <div className={classes.hubLink}>
                    <RouterTwoToneIcon color={(hubInfo && hubInfo.connected) ? 'primary' : 'error'} />
                  </div>
                )}
              </Grid>

              <Grid item xs={9} className={classes.hubText}>
                {hubConnected && (
                  <RouterLink className={classes.hubLink} to="/devices">
                    <Typography variant="body1" component="div" />
                    {job.hub.name && (
                      <Typography variant="body1" component="div">
                        {`${job.hub.name}`}
                      </Typography>
                    )}
                    {job.hub.serialNo && (
                      <Typography variant="caption" component="div">
                        {`SN: ${job.hub.serialNo}`}
                      </Typography>
                    )}
                    {job.hub.id && (
                      <Typography variant="caption" component="div">
                        {`ID: ${job.hub.id}`}
                        <IconButton aria-label="admin-link" size="small" onClick={(e) => { e.preventDefault(); window.open(`${process.env.REACT_APP_COZIFY_ADMIN_URL}hubs/view/${job.hub.id}`, '_admin') }}>
                          <LinkTwoToneIcon />
                          <Typography variant="caption" component="span">
                            AdminUI
                          </Typography>
                        </IconButton>
                      </Typography>
                    )}
                    {waterColdMeter1 && (
                      <Typography variant="caption" component="div">
                        {`Cold-water (${waterColdMeter1.id}): ${waterColdMeter1.state.volume}L` }
                      </Typography>
                    )}
                    {waterColdMeter2 && (
                      <Typography variant="caption" component="div">
                        {`Cold-water (${waterColdMeter2.id}): ${waterColdMeter2.state.volume}L` }
                      </Typography>
                    )}
                    {waterHotMeter1 && (
                      <Typography variant="caption" component="div">
                        {`Hot-water (${waterHotMeter1.id}): ${waterHotMeter1.state.volume}L`}
                      </Typography>
                    )}
                    {waterHotMeter2 && (
                      <Typography variant="caption" component="div">
                        {`Hot-water (${waterHotMeter2.id}): ${waterHotMeter2.state.volume}L`}
                      </Typography>
                    )}
                    {electricityMeter1 && electricityMeter1.state && electricityMeter1.state.totalPower && (
                      <Typography variant="caption" component="div">
                        {`Electricity (${electricityMeter1.id}): ${electricityMeter1.state.totalPower.toFixed(2)}kWh`}
                      </Typography>
                    )}
                    {electricityMeter2 && electricityMeter2.state && electricityMeter2.state.totalPower(
                      <Typography variant="caption" component="div">
                        {`Electricity (${electricityMeter2.id}): ${electricityMeter2.state.totalPower.toFixed(2)}kWh`}
                      </Typography>,
                    )}
                    {(!job.hub.desiredVersionId || job.hub.desiredVersionId === job.hub.currentVersionId)
                      && (
                        <Typography variant="caption" component="div">
                          {`${job.hub.currentVersionId} - ${job.hub.status}`}
                        </Typography>
                      )}
                    {(job.hub.desiredVersionId && job.hub.desiredVersionId !== job.hub.currentVersionId)
                      && (
                        <Typography variant="body2" color="error" component="div">
                          {`${job.hub.currentVersionId} / ${job.hub.desiredVersionId} - ${job.hub.status}`}
                        </Typography>
                      )}

                    <Typography variant="caption" component="div">
                      {job.hub.ownerEmail}
                    </Typography>
                    {(hubInfo
                      && hubInfo.features
                      && hubInfo.features.indexOf(11) !== -1
                      && hubInfo.features.indexOf(3) !== -1)
                      && (
                        <Typography variant="caption" component="div">
                          {`${t('Data collection')}: ${t('ON')}`}
                        </Typography>
                      )}
                    {(hubInfo
                      && hubInfo.features
                      && hubInfo.features.indexOf(3) === -1)
                      && (
                        <Typography variant="body2" color="error" component="div">
                          {`${t('Data collection')}: ${t('OFF')}`}
                        </Typography>
                      )}
                    {(hubInfo && hubInfo.features && hubInfo.features.indexOf(167) === -1)
                      && (
                        <Typography variant="body2" color="error" component="div">
                          {`Fortum: ${t('OFF')}`}
                        </Typography>
                      )}
                  </RouterLink>
                )}
                {!hubConnected && (
                  <div className={classes.hubLink}>
                    <Typography variant="body1" component="div">
                      {job.hub.name }
                    </Typography>
                    {job.hub.id && (
                      <Typography variant="caption" component="div">
                        {`ID: ${job.hub.id}`}
                        <IconButton aria-label="admin-link" size="small" onClick={(e) => { e.preventDefault(); window.open(`${process.env.REACT_APP_COZIFY_ADMIN_URL}hubs/view/${job.hub.id}`, '_admin') }}>

                          <LinkTwoToneIcon />
                          <Typography variant="caption" component="span">
                            AdminUI
                          </Typography>
                        </IconButton>
                      </Typography>
                    )}
                    {(job.hub.desiredVersionId === job.hub.currentVersionId)
                      && (
                        <Typography variant="caption" component="div">
                          {`${job.hub.currentVersionId} - ${job.hub.status}`}
                        </Typography>
                      )}
                    {(job.hub.desiredVersionId !== job.hub.currentVersionId)
                      && (
                        <Typography variant="body2" color="error" component="div">
                          {`${job.hub.currentVersionId} / ${job.hub.desiredVersionId} - ${job.hub.status}`}
                        </Typography>
                      )}
                    <Typography variant="caption" component="div">
                      {job.hub.ownerEmail}
                    </Typography>
                    <Typography variant="body2" color="error" component="div">
                      {t('Hub is not connected internet')}
                    </Typography>

                  </div>
                )}
              </Grid>
              <Grid item xs={1} className={classes.hubIcon}>
                {hubConnected && (
                  <RouterLink className={classes.hubLink} to="/devices">
                    <ArrowForwardIosTwoTone style={{ fontSize: 12 }} />
                  </RouterLink>
                )}
              </Grid>

              { job.hub && job.hubId
              && (

                <Grid item xs={12}>
                  <Grid container>

                    <Grid item xs={12} className={classes.hubNoteHeader}>
                      <Typography component="h3" className={classes.hubNoteHeader}>
                        {t('Locking')}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.hubButtons}>
                      <Button
                        size="small"
                        onClick={() => lockAndBackupHub()}
                        color="primary"
                        type="button"
                        variant="contained"
                        className={classes.unmapButton}
                      >
                        {t('BACKUP AND LOCK CONFIGURATION')}
                      </Button>

                      <Button
                        size="small"
                        onClick={() => askUnMapConfirmation()}
                        color="secondary"
                        type="button"
                        variant="contained"
                        className={classes.unmapButton}
                      >
                        {t('Unmap Hub')}
                      </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.hubNote}>
                      <Typography component="p" className={classes.hubNote}>
                        {t('Backup current configuration and lock current devices so that other users can not change them')}
                      </Typography>
                      <Typography component="p" className={classes.hubNoteImportant}>
                        {t('Unmap will remove hub from target address and area')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

              )}

              { job.hub && job.hubId
              && (

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} className={classes.hubNoteHeader}>
                      <Typography component="h3" className={classes.hubNoteHeader}>
                        {t('Access rights')}
                      </Typography>
                    </Grid>

                    { !isEmpty(userRoles) && (
                      <Grid item xs={12} className={classes.hubNoteHeader}>


                          { userRoles.map((entry) => {
                            return (
                              <Typography className={classes.hubNote} variant="body2" key={entry.email}>{`${entry.email}: ${entry.roleName}`}</Typography>
                            )
                          })}

                      </Grid>
                    )}

                    <Grid item xs={12} className={classes.hubNotePre}>
                      <Typography component="p" className={classes.hubNotePre}>

                        {`${t('You can set admin access for yor user')} ${user.email}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.hubButtons}>
                      <Button
                        size="small"
                        onClick={() => askHubAdminAccess()}
                        color="primary"
                        type="button"
                        variant="contained"
                        className={classes.unmapButton}
                        startIcon={<PersonAddTwoToneIcon />}
                      >
                        {t('Ask admin access')}
                      </Button>

                      <Button
                        size="small"
                        onClick={() => removeHubAdminAccess()}
                        color="secondary"
                        type="button"
                        variant="contained"
                        className={classes.unmapButton}
                        startIcon={<PersonAddDisabledTwoToneIcon />}
                      >
                        {t('Remove admin access')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

              )}
            </Grid>
          )}


        </Paper>
      )}


    </Box>
  )
}

Apartment.propTypes = {
  job: PropTypes.any.isRequired,
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const ConnectedApartment = connect(mapStateToProps, mapDispatchToProps)(withRouter(Apartment))
export default ConnectedApartment
