
import React, { useState, useEffect } from 'react'
import './NodeEditable.css'
import RemoveCircleOutlineTwoToneIcon from '@material-ui/icons/RemoveCircleOutline'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Select from '@material-ui/core/Select'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import { isEmpty } from '../utils'
import useStyles from './EditStyles'


const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const getStyles = (item, items, theme) => ({
  fontWeight:
      items.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
})

const SceneTypes = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const {
    selectedSceneTypes,
    sceneTypes,
    handleAddSceneType,
    handleRemoveSceneType,
    handleChangeSceneTypes,
    readOnly,
  } = props
  const [editSceneTypes, setEditSceneTypes] = useState(false)
  const [newSceneTypeValue, setNewSceneTypeValue] = useState('')
  const [scenesSelected, setSceneTypesSelected] = useState([])
  const [allSceneTypes, setAllSceneTypes] = useState([])

  const addScene = () => {
    if (!allSceneTypes.includes(newSceneTypeValue)) {
      setAllSceneTypes([...allSceneTypes, newSceneTypeValue])
      setNewSceneTypeValue('')
      // Add to model
      handleAddSceneType(newSceneTypeValue)
    }
  }

  const handleMultiSceneChange = (event) => {
    setSceneTypesSelected(event.target.value)
    handleChangeSceneTypes(event.target.value)
  }

  const removeScene = (scene) => {
    debugger
    if (allSceneTypes.includes(scene)) {
      const index = allSceneTypes.indexOf(scene)
      // Remove from selectable items
      setAllSceneTypes([
        ...allSceneTypes.slice(0, index),
        ...allSceneTypes.slice(index + 1),
      ])
      // Remove from selected items
      if (scenesSelected.includes(scene)) {
        const indexInSelected = scenesSelected.indexOf(scene)
        setSceneTypesSelected([
          ...scenesSelected.slice(0, indexInSelected),
          ...scenesSelected.slice(indexInSelected + 1),
        ])
        console.log(scenesSelected)
      }
      // Remove from model
      handleRemoveSceneType(scene)
    }
  }

  useEffect(() => {
    if (!isEmpty(selectedSceneTypes)) {
      setSceneTypesSelected(selectedSceneTypes)
    }
  }, [selectedSceneTypes])

  useEffect(() => {
    if (!isEmpty(sceneTypes)) {
      setAllSceneTypes(sceneTypes)
    }
  }, [sceneTypes])

  return (
    <div className={classes.editArea}>
      <FormControl component="fieldset">
        {editSceneTypes && (
          <Link
            component="button"
            variant="body2"
            key="scene-label"
            onClick={(event) => setEditSceneTypes(false)}
          >
            <ExpandLessTwoToneIcon className="EditableItem-Icon EditableItem-Link-Icon" />
            {t('Edit SceneTypes')}

          </Link>
        )}
        {editSceneTypes && (
          <List key="scene-edit-list" component="div" disablePadding className={classes.list}>
            {
              allSceneTypes.map((scene) => (
                <ListItem button key={`list-item-${scene}`} className={classes.item}>
                  {scene}
                  <IconButton
                    className={classes.iconButton}
                    aria-label="Remove"
                    onClick={() => removeScene(scene)}
                  >
                    <RemoveCircleOutlineTwoToneIcon className="EditableItem-Icon" />
                  </IconButton>
                </ListItem>
              ))
            }
          </List>
        )}
        {editSceneTypes && (
          <Paper component="form" onSubmit={addScene} className={classes.inlineListButtton}>
            <Input
              inputProps={{ 'aria-label': 'Name' }}
              // className="EditableItem-Text"
              onChange={(e) => { setNewSceneTypeValue(e.target.value) }}
              value={newSceneTypeValue}
            />
            <Button
              type="submit"
              // className="EditableItem-Button EditableItem-Button_save"
              onClick={addScene}
              variant="contained"
              color="secondary"
              size="small"
              disabled={isEmpty(newSceneTypeValue)}
              className={classes.button}
            >
              {` ${t('Add')}`}
            </Button>
          </Paper>
        )}


        {(!editSceneTypes && !readOnly) && (
          <Link
            component="button"
            variant="body2"
            key="scene-label"
            onClick={(event) => setEditSceneTypes(true)}
          >
            <EditTwoToneIcon className="EditableItem-Icon EditableItem-Link-Icon" />
            {t('SceneTypes')}

          </Link>
        )}
        {(!editSceneTypes && readOnly) && (
          <Typography className={classes.infoOn}>
            {t('SceneTypes')}
          </Typography>
        )}
        {!editSceneTypes && (
          <FormGroup key="scene-group" row>
            <Select
              disabled={readOnly}
              labelId="scenes"
              id="scenes-chip"
              multiple
              value={scenesSelected}
              onChange={handleMultiSceneChange}
              inputProps={{
                id: 'select-multiple-native',
              }}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected && selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              { allSceneTypes.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, allSceneTypes, theme)}>
                  {name}
                </MenuItem>
              ))}


            </Select>
          </FormGroup>
        )}
      </FormControl>

    </div>
  )
}

export default SceneTypes
