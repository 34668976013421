const userReducer = (state = { language: 'fi' }, action) => {
  console.log('userReducer ACTION: ', action)
  // eslint-disable-next-line default-case
  switch (action.type) {
  case 'LANGUAGE':
    return {
      ...state,
      language: action.data.language,
    }
  case 'LOGIN':
    return {
      ...state,
      access_token: action.data.access_token,
      expires_at: action.data.expires_at,
      id_token: action.data.id_token,
      profile: { ...action.data.profile },
      refresh_token: action.data.refresh_token,
      scope: action.data.scope,
      session_state: action.data.session_state,
      name: action.data.profile.name,
      email: action.data.profile.email,
    }

  case 'LOGOUT':
    // window.localStorage.clear()
    window.localStorage.removeItem('user')
    return {}

  default:
    return state
  }
}

export const userLanguageAction = (userData) => ({
  type: 'LANGUAGE',
  data: userData,
})

export const setUserAction = (userData) => ({
  type: 'LOGIN',
  data: userData,
})

export const removeUserAction = () => ({
  type: 'LOGOUT',
})
export default userReducer
