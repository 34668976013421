import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import enTr from './locales/en.json'
import fiTr from './locales/fi.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: enTr,
  },
  fi: {
    translation: fiTr,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'fi',
    debug: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    saveMissing: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
