import React, {
  useState, useEffect, useCallback, // useLayoutEffect,
} from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useTranslation } from 'react-i18next'
import {
  selectCloud,
  // urlBase64Decode,
  doPoll,
  connectHubBySite,
  setAuthenticated,
} from 'cozify-sdk'
import { isEmpty } from '../utils'
import { useLocalStorage } from '../hooks'

const useStyles = makeStyles((theme) => ({
  '@media print': {
    noPrint: {
      display: 'none !important',
    },

  },
  tableContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    height: 'auto',
  },
  '@media only screen and (max-width: 420px)': {
    tableContainer: {
      height: 200,
    },
  },
  table: {
    minWidth: 1500,
  },

  tableHeader: {
    width: 50,
    padding: '10px 4px 10px 4px',
    '&:last-child': {
      padding: '10px 4px 10px 4px',
    },
  },
  tableHeaderArea: {
    width: 20,
    padding: '10px 4px 10px 2rem',
    '&:last-child': {
      padding: '10px 4px 10px 4px',
    },
  },
  tableHeaderHub: {
    width: 80,
    padding: '10px 4px 10px 4px',
    '&:last-child': {
      padding: '10px 4px 10px 4px',
    },
  },
  tableHeaderState: {
    lineHeight: '0.8rem',
    width: 50,
    padding: '10px 4px 10px 4px',
    '&:last-child': {
      padding: '10px 4px 10px 4px',
    },
  },
  tableHeaderDevType: {
    lineHeight: '0.8rem',
    width: 50,
    padding: '10px 4px 10px 4px',
    '&:last-child': {
      padding: '10px 4px 10px 4px',
    },
  },
  cell: {
    fontSize: '0.8rem',
    width: 20,
    padding: '0px 4px 0px 4px',
    overflow: 'hidden',
  },
  cellStair: {
    fontSize: '0.8rem',
    paddingLeft: '0rem',
  },
  cellFloor: {
    fontSize: '0.8rem',
    paddingLeft: '1rem',
  },
  cellArea: {
    fontSize: '0.8rem',
    width: 50,
    padding: '0px 4px 0px 3rem',
  },
  cellHub: {
    width: 100,
    padding: '0px 4px 0px 4px',
    overflow: 'hidden',
  },
  cellState: {
    width: 50,
    padding: '0px 4px 0px 4px',
    overflow: 'hidden',
  },
  cellStateDevType: {
    width: 50,
    padding: '0px 4px 0px 4px',
    overflow: 'hidden',
  },
  row: {
    height: 50,
    overflow: 'hidden',
  },
  header: {
    color: theme.palette.text.secondary,
  },
  infoOn: {
    color: 'green',
  },
  infoOff: {
    color: 'red',
  },
  alert: {
    color: 'red',
  },
  errorIcon: {
    fontSize: 16,
    color: 'red',
    position: 'relative',
    top: theme.spacing(0),
    left: theme.spacing(0),
  },
  progress: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
  },
}))


const Building = (props) => {
  const {
    jobs, setShowJobList, showJobList, selectJob, reloadJobs, reloadBuildingJobs, setReloadBuildingJobs, building, updateJob,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [areas, setAreas] = useState([])
  const [selectedJob, setSelectedJob] = useState({})
  const [storedJob, setStoredJob] = useLocalStorage('job')
  const [hubsPolled, setHubsPolled] = useState(false)
  const [hubsToBePolled, setHubsToBePolled] = useState(false)
  const [pollingHubs, setPollingHubs] = useState(false)
  const [openStairs, setOpenStairs] = React.useState([])
  const [openFloors, setOpenFloors] = React.useState([])
  const [allDeviceTypes, setAllDeviceTypes] = useState([])
  const scrollableListRef = React.createRef()


  const selectArea = useCallback((jobId) => {
    const selectedId = jobId || selectedJob.id
    const job = jobs.find((j) => (j.id === selectedId))

    if (job) {
      if (!selectedJob.id || selectedJob.id !== job.id) {
        setSelectedJob(job)
        selectJob(job)
        setShowJobList(false)
      } else if (selectedJob.id && selectedJob.id === job.id) {
        // selectJob(job)
        // pollHubs?
        setShowJobList(false)
      }
      if (!storedJob || storedJob.id !== job.id) {
        setStoredJob(job)
      }
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(jobs),
    // eslint-disable-next-line
    JSON.stringify(selectedJob),
    // eslint-disable-next-line
    JSON.stringify(storedJob),
    selectedJob.id,
    selectJob,
    setStoredJob,
    setShowJobList,
  ])

  // When unmounting
  /*
  useLayoutEffect(() => async () => {
    if (pollingHubs) {
      setStopPollingHubs(true)
    }
  }, [pollingHubs, setStopPollingHubs])
  */

  useEffect(() => {
    // async version of connectHubByTokens
    // eslint-disable-next-line max-len
    const waitConnectHubById = async (cozifyCloudToken, cozifyHubId, siteId) => {
      if (building) {
        setAuthenticated(`${cozifyCloudToken}`)
        selectCloud(`${process.env.REACT_APP_DIRECTORY_URL}/directory/device/site/${building}/`)
        return connectHubBySite(cozifyHubId, siteId, false)
      }
    }

    // async version of doPoll
    const waitRemotePoll = async (hubId) => new Promise((resolve, reject) => {
      if (building) {
        selectCloud(`${process.env.REACT_APP_DIRECTORY_URL}/directory/device/site/${building}/`)
        doPoll(hubId, true).then((delta) => {
          if (delta && delta.polls && delta.polls[0].devices) {
            resolve(delta.polls)
          } else {
            reject(new Error('no devices'))
          }
        }).catch((error) => {
          console.error(`Hub ${hubId} remote poll failed`, error)
          reject(error)
        })
      }
    })

    const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms))


    const pollHubs = async () => {
      setPollingHubs(true)
      let devicesTypes = []
      for (let i = 0; i <= jobs.length - 1; i += 1) {
        const job = jobs[i]
        const { hub } = job
        if (hub && job.hub && job.hub.status && window.location.hash === '#/') {
          // if (hub.id === 'cd3bd9e4-7943-11e5-a567-544a168673f2') debugger
          if (openStairs.includes(job.location.stair) && openFloors.includes(job.location.stair + job.location.floor)) {
            try {
              // eslint-disable-next-line
              await sleep(250)
              // eslint-disable-next-line
              const info = await waitConnectHubById(`${hub.keys.cozifyCloudToken}`, hub.id, building)
              if (info[hub.id]) {
                if (info[hub.id].name) {
                  job.hub.name = info[hub.id].name
                }
                job.hub.version = info[hub.id].version
                job.hub.currentVersionId = info[hub.id].version
                job.hub.features = info[hub.id].features
                job.hub.connectionState = info[hub.id].connectionState
              }
              updateJob(job, selectedJob)
              // eslint-disable-next-line
              const pollResult = await waitRemotePoll(hub.id, true)                     
              let devices = {}
              pollResult.forEach((delta) => {
                switch (delta.type) {
                case 'DEVICE_DELTA': {
                  devices = Object.values(delta.devices)
                  break
                }
                case 'USER_DELTA': {
                  job.hub.users = Object.values(delta.users)
                  updateJob(job, selectedJob)
                  break
                }
                default: {
                  break
                }
                }
              })

              let connected = 0
              let online = 0
              const perType = {}
              const devicesArr = Object.values(devices)
              const newDeviceTypes = [...new Set(devicesArr.map((d) => d.type))]
              // unique type list
              devicesTypes = [...new Set([].concat(...[devicesTypes, newDeviceTypes]))]
              setAllDeviceTypes(devicesTypes)
              devices.forEach((device) => {
                if (!perType[device.type]) {
                  perType[device.type] = {
                    connected: 0,
                    online: 0,
                  }
                }
                perType[device.type].connected += 1
                connected += 1

                if (device.manufacturer && (device.manufacturer.indexOf('Fireangel') !== -1 || device.manufacturer.indexOf('Obix') !== -1 || device.manufacturer.indexOf('VflExCon') !== -1 || device.manufacturer.indexOf('Philio Technology Corp') !== -1) && device.state && device.state.lastSeen
                  && ((Date.now() - device.state.lastSeen) / (1000 * 60 * 60) < 25)) {
                  online += 1
                  perType[device.type].online += 1
                } else if (device.state && device.state.lastSeen
                  && ((Date.now() - device.state.lastSeen) / (1000 * 60 * 60) < 1)) {
                  online += 1
                  perType[device.type].online += 1
                }
              })
              job.hub.connected = true
              job.hub.devices = {
                connected,
                online,
                perType,
              }
            } catch (error) {
              if (error.message === 'doPoll error: No Hub connection') {
                job.hub.connected = false
              }
              console.info(`Hub ${hub.id} connection failed`, error)
            }
          }
        }
      }

      // setJobs(jobs)
      setPollingHubs(false)
    }

    if (((!isEmpty(jobs) && !hubsPolled) || reloadJobs || reloadBuildingJobs || hubsToBePolled) && !pollingHubs && !isEmpty(building)) {
      setHubsPolled(true)
      setHubsToBePolled(false)
      pollHubs()
      setPollingHubs(false)
    }
    setReloadBuildingJobs(false)
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(jobs),
    hubsPolled,
    reloadJobs,
    reloadBuildingJobs,
    hubsToBePolled,
  ])

  useEffect(() => {
    if (isEmpty(building)) {
      setShowJobList(false)
      return
    }
    console.log('Building EFFECT jobs called.. building:', building)
    const filteredAreas = jobs.map((job) => ({
      id: job.id,
      name: job.name,
      stair: job.location.stair,
      building: job.location.building,
      floor: job.location.floor,
      stairAndArea: `${job.location.stair} ${job.location.area}`,
      area: job.location.area,
      // eslint-disable-next-line no-nested-ternary
      hubId: job.hub ? job.hub.id ? job.hub.id : null : null,
      // eslint-disable-next-line no-nested-ternary
      hubName: job.hub ? job.hub.name ? job.hub.name : null : null,
      // eslint-disable-next-line no-nested-ternary
      hubSerialNumber: job.hub ? job.hub.serialNo ? job.hub.serialNo : null : null,
      // eslint-disable-next-line
      hubCurrentVersionId: job.hub ? job.hub.currentVersionId ? job.hub.currentVersionId : null : null,
      // eslint-disable-next-line
      hubDesiredVersionId: job.hub ? job.hub.desiredVersionId ? job.hub.desiredVersionId : null : null,
      // eslint-disable-next-line no-nested-ternary
      hubStatus: job.hub ? job.hub.status ? job.hub.status : null : null,
      // eslint-disable-next-line no-nested-ternary
      hubConnected: job.hub ? job.hub.connected ? job.hub.connected : null : null,
      // eslint-disable-next-line no-nested-ternary
      hubDevices: job.hub ? job.hub.devices ? job.hub.devices : null : null,
      tested: '',
    }))

    /*
    jobs.forEach((job) => {
      if (job.hub && job.hub.id === 'cd3bd9e4-7943-11e5-a567-544a168673f2') debugger
    })
    */
    const sortedAreas = filteredAreas.sort((jobA, jobB) => {
      const area1 = Number(jobA.area)
      const area2 = Number(jobB.area)
      const floor1 = Number(jobA.floor)
      const floor2 = Number(jobB.floor)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(area1) || isNaN(area2) || isNaN(floor1) || isNaN(floor2) ) {
        const stairFloorAndArea1 = jobA.stair + jobA.floor + jobA.area
        const stairFloorAndArea2 = jobB.stair + jobB.floor + jobB.area
        // localeCompare could return also some other than -1,0,1
        const compVal = stairFloorAndArea1.localeCompare(stairFloorAndArea2)
        return compVal === 0 ? 0 : compVal < 0 ? -1 : 1 
      }
      if (jobA.stair.localeCompare(jobB.stair) < 0) {
        return -1
      } else if (jobA.stair.localeCompare(jobB.stair) > 0) {
        return 1
      }
      
      if (floor1 < floor2) {
        return -1
      }
      if (floor1 > floor2) {
        return 1
      }

      if (area1 > area2) {
        return 1
      }
      if (area2 > area1) {
        return -1
      }
      return 0
    })

    // selectArea()
    if (selectedJob.id) {
      const job = jobs.find((j) => {
        if (j.id === selectedJob.id) {
          return true
        }
        return false
      })
      if (job) {
        if (!openStairs.includes(job.location.stair)) {
          setOpenStairs((openStairsAll) => [...openStairsAll, job.location.stair])
        }
        const stairAndFloor = job.location.stair + job.location.floor
        if (!openFloors.includes(stairAndFloor)) {
          setOpenFloors((openFlorsAll) => [...openFlorsAll, stairAndFloor])
          setHubsToBePolled(true)
        }
        // setSelectedJob(job)
        // selectJob(job)
      } else {
        setSelectedJob({})
      }
    }    
    setAreas(sortedAreas)    
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(jobs),
    // eslint-disable-next-line
    JSON.stringify(selectedJob),
    setOpenStairs,
    setOpenFloors,
    setHubsToBePolled,
    selectJob,
  ])

  useEffect(() => {
    console.log('Building EFFECT cookie 1 -reload', reloadJobs)
    console.log('Building EFFECT cookie 2 -reloadBuilding', reloadBuildingJobs)
    if (reloadJobs || reloadBuildingJobs) {
      return
    }
    if (
      (!selectedJob.id && storedJob && storedJob.id)
      // || (selectedJob.id && storedJob.id && storedJob.id !== selectedJob.id)
    ) {
      if (storedJob.siteId === building) {
        console.log('Building EFFECT cookie 3', building)
        setTimeout(() => {
          selectArea(storedJob.id)
        }, 10)
      }
    }
  },
  // eslint-disable-next-line
  [
    // eslint-disable-next-line
    JSON.stringify(storedJob),    
    selectedJob.id,
    reloadJobs,
    reloadBuildingJobs,
  ])

  const allStatus = (devices) => {
    if (devices) {
      return (
        <Typography variant="caption" component="div" align="center" className={devices.connected === devices.online ? 'alert' : null}>
          {`${devices.connected} / ${devices.online}`}
          {/* JSON.stringify(devices.perType) */}
        </Typography>
      )
    }
    return null
  }

  const byTypeStatus = (devices, devType) => {
    if (devices && devices.perType && devices.perType[devType]) {
      return (
        <Typography variant="caption" component="div" align="center" className={devices.perType[devType].connected === devices.perType[devType].online ? 'alert' : null}>
          {`${devices.perType[devType].connected} / ${devices.perType[devType].online}`}
        </Typography>
      )
    }
    return null
  }

  let lastStair = null
  let lastFloor = null
  let extraRow = null


  const stairsHandle = (stair) => {
    if (openStairs.includes(stair)) {
      setOpenStairs(openStairs.filter((item) => item !== stair))
    } else {
      setOpenStairs((openStairsAll) => [...openStairsAll, stair])
    }
  }

  const floorsHandle = (stair, floor) => {
    const srairAndFloor = stair + floor
    if (openFloors.includes(srairAndFloor)) {
      setOpenFloors(openFloors.filter((item) => item !== srairAndFloor))
    } else {
      setOpenFloors((openFlorsAll) => [...openFlorsAll, srairAndFloor])
      setHubsToBePolled(true)
    }
  }

  return (
    <Box my={1} mx={2}>

      <Grid
        container
        className={classes.header}
        justify="center"
        alignItems="center"
        onClick={() => setShowJobList(!showJobList)}
      >
        <Grid item xs={11} className={classes.noPrint}>
          <Typography variant="h6" component="h4" gutterBottom>
            {`${t('Area')}:`}
            {selectedJob.location
              && (
                <span>
                  {' '}
                  {selectedJob.location.stair}
                  {' '}
                  {selectedJob.location.area}
                </span>
              )}
            {pollingHubs
              && (
                <CircularProgress
                  className={classes.progress}
                  disableShrink
                  size={16}
                  thickness={4}
                  color="primary"
                  variant="indeterminate"
                />
              )}
          </Typography>
        </Grid>
        <Grid item xs={1} align="right" className={classes.noPrint}>
          {!showJobList && (<ExpandMoreTwoToneIcon />)}
          {showJobList && (<ExpandLessTwoToneIcon />)}
        </Grid>
      </Grid>

      { showJobList
      && (
        <Paper className={classes.tableContainer} ref={scrollableListRef}>
          <Table className={classes.table} stickyHeader aria-label="Building table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderArea} align="left">{t('Area')}</TableCell>
                <TableCell className={classes.tableHeaderHub} align="center">{t('Hub')}</TableCell>
                <TableCell className={classes.tableHeaderState} align="center">{`${t('Devices')} / ${t('Online')}`}</TableCell>
                {allDeviceTypes.map((devType) => (
                  <TableCell key={devType} className={classes.tableHeaderDevType} align="center">{t(devType.concat('-devices'))}</TableCell>
                ))}
                {/* <TableCell>{t('Apartment type')}</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {areas.map((row) => {
                if (lastStair !== row.stair && lastFloor !== row.floor) {
                  lastStair = row.stair
                  lastFloor = row.floor
                  extraRow = (
                    <React.Fragment key={row.stairAndArea}>

                      <TableRow onClick={() => stairsHandle(row.stair)}>
                        <TableCell colSpan={allDeviceTypes.length + 3} className={classes.cellStair}>
                          <IconButton aria-label="expand row" size="small">
                            {openStairs.includes(row.stair) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                          <Typography variant="caption">
                            {`${t('Stair')} ${row.stair}`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {openStairs.includes(row.stair) && (
                        <TableRow onClick={() => floorsHandle(row.stair, row.floor)}>
                          <TableCell colSpan={allDeviceTypes.length + 3} className={classes.cellFloor}>
                            <IconButton aria-label="expand row" size="small">
                              {openFloors.includes(row.stair + row.floor) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                            <Typography variant="caption">
                              {`${t('Floor')} ${row.floor}`}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )
                } else if (!extraRow && lastFloor !== row.floor) {
                  lastFloor = row.floor
                  if (!openStairs.includes(row.stair)) {
                    return null
                  }
                  extraRow = (
                    <TableRow onClick={() => floorsHandle(row.stair, row.floor)}>
                      <TableCell colSpan={allDeviceTypes.length + 3} className={classes.cellFloor}>
                        <IconButton aria-label="expand row" size="small">
                          {openFloors.includes(row.stair + row.floor) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <Typography variant="caption">
                          {`${t('Floor')} ${row.floor}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                } else {
                  extraRow = null
                }
                
                return (
                  <React.Fragment
                    key={row.stairAndArea}
                  >
                    {extraRow}
                    {openStairs.includes(row.stair) && openFloors.includes(row.stair + row.floor) && (
                      <TableRow
                        className={classes.row}
                        key={row.id}
                        selected={selectedJob.id === row.id}
                        onClick={() => selectArea(row.id)}
                      >

                        <TableCell className={classes.cellArea} component="th" scope="row">
                          {row.stairAndArea}
                          {/* ' '
                          (
                          {row.floor}
                          {row.stair}
                          {row.area}
                          )
                          */}
                        </TableCell>

                        {/* <TableCell align="right">{row.apartmentType}</TableCell> */}
                        {row.hubId && (
                          <TableCell align="center" className={classes.cellHub} onClick={() => selectArea(row.id)}>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            <Typography variant="caption" className={!pollingHubs ? row.hubConnected ? classes.infoOn : classes.infoOff : null} component="span">
                              {`${row.hubName || row.hubSerialNumber || row.hubId} `}
                              <br />
                            </Typography>

                            {(!row.hubDesiredVersionId || row.hubDesiredVersionId === row.hubCurrentVersionId)
                      && (
                        <Typography variant="caption" component="span">
                          {`${row.hubCurrentVersionId} - ${row.hubStatus}`}
                          {row.hubConnected}
                        </Typography>
                      )}
                            {(row.hubDesiredVersionId && row.hubDesiredVersionId !== row.hubCurrentVersionId)
                      && (
                        <Typography variant="caption" component="span">
                          {`${row.hubCurrentVersionId} / ${row.hubDesiredVersionId} - ${row.hubStatus}`}
                        </Typography>
                      )}
                          </TableCell>
                        )}
                        {!row.hubId && (
                          <TableCell align="center" className={classes.cellHub}>
                            -
                          </TableCell>
                        )}

                        {row.hubId && (
                          <TableCell align="center" className={classes.cellState}>
                            {allStatus(row.hubDevices)}
                            {!pollingHubs && !row.hubConnected && (
                              <ErrorOutlineIcon className={classes.errorIcon} />
                            )}
                          </TableCell>
                        )}
                        {!row.hubId && (
                          <TableCell align="center" className={classes.cellState}>
                      &nbsp;
                          </TableCell>
                        )}

                        {allDeviceTypes.map((devType) => (
                          <TableCell key={`${row.id}-${devType}`} className={classes.cellStateDevType} align="center">
                            {byTypeStatus(row.hubDevices, devType)}
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Box>
  )
}

Building.propTypes = {
  jobs: PropTypes.any.isRequired,
}


export default Building
