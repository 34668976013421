import React, { useState, useEffect } from 'react'
import { connect, Provider } from 'react-redux'
import {
  HashRouter as Router,
  Route, Redirect,
} from 'react-router-dom'

// eslint-disable-next-line import/no-extraneous-dependencies
import { store as cozifyStore } from 'cozify-sdk'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'


import './App.css'
import Header from './components/Header'
import Login from './components/Login'
import Home from './components/Home'
import Planning from './components/Planning'
import Notification from './components/Notification'
import Devices from './components/Devices'
import appStore from './appStore'


const useStyles = makeStyles(() => ({
  '@media print': {
    noPrint: {
      display: 'none !important',
    },
  },
  root: {
    flexGrow: 1,
  },
  container: {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: '100%',
  },
}))

const App = (props) => {
  const { user } = props
  const classes = useStyles()
  const { i18n } = useTranslation()
  const [job, setJob] = useState({})
  const [planning, setPlanning] = useState(false)
  useEffect(() => {
    setPlanning(process.env.REACT_APP_PLANNING)
    console.log('App EFFECT')
  },
  [
    setPlanning,
  ])

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Box my={0}>
        <div className={classes.root}>

          <Router>
            <div>
              <Provider store={appStore}>
                <Header job={job} planning={planning} />
              </Provider>

              <Route
                path="/Login"
                render={() => (
                  <Provider store={appStore}>
                    <Login planning={planning} setJob={setJob} />
                  </Provider>
                )}
              />

              <Route
                exact
                path="/"
                render={() => (user.access_token
                  ? (
                    <Provider store={appStore}>
                      <Home job={job} setJob={setJob} />
                    </Provider>
                  )
                  : <Redirect to="/login" />)}
              />

              <Route
                path="/planning"
                render={() => (user.access_token
                  ? (
                    <Provider store={appStore}>
                      <Planning
                        job={job}
                        locale={i18n.language}
                        token={user.access_token}
                      />
                    </Provider>
                  )
                  : <Redirect to="/login" />)}
              />

              <Route
                path="/devices"
                render={() => (user.access_token
                  ? (
                    <Provider store={cozifyStore}>
                      <Devices
                        job={job}
                        locale={i18n.language}
                        token={user.access_token}
                      />
                    </Provider>
                  )
                  : <Redirect to="/login" />)}
              />

            </div>
          </Router>

          <Provider store={appStore}>
            <Notification />
          </Provider>
        </div>
      </Box>
    </Container>
  )
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)
export default ConnectedApp
