
import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { NOTIFICATION_TYPES } from '../reducers/notificationsReducer'

export default function UserDialog(props) {
  const [open, setOpen] = React.useState(true)
  // eslint-disable-next-line react/destructuring-assignment
  const message = props.message || 'Ok?'
  // eslint-disable-next-line react/destructuring-assignment
  const description = props.description || ''
  // eslint-disable-next-line react/destructuring-assignment
  const cancelText = props.cancelText || 'Cancel'
  // eslint-disable-next-line react/destructuring-assignment
  const okText = props.okText || 'Ok'
  
  const { type, cancel, ok } = props

  const handleCancel = () => {
    if (cancel) cancel()
    setOpen(false)
  }

  const handleOk = () => {
    if (ok) ok()
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancel && type === NOTIFICATION_TYPES.CONFIRMATION
          && (
            <Button onClick={handleCancel} color="primary">
              {cancelText}
            </Button>
          )}
          <Button onClick={handleOk} color="primary" autoFocus>
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
