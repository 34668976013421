import {
  NOTIFICATION_TYPES, notificationAction, clearNotificationAction,
} from '../reducers/notificationsReducer'
import i18n from '../i18n'
import appStore from '../appStore'

const askConfirmation = ({
  message, ok, okText, cancel, cancelText, timeout = 5000,
}) => {  
  appStore.dispatch(notificationAction({
    type: NOTIFICATION_TYPES.CONFIRMATION,
    name: message,
    message: i18n.t(message),
    ok: () => { appStore.dispatch(clearNotificationAction(message)); if (ok) ok() },
    cancel: () => { appStore.dispatch(clearNotificationAction(message)); if (cancel) cancel() },
    okText: i18n.t(okText),
    cancelText: i18n.t(cancelText),
  }))
  if (timeout !== -1) {
    setTimeout(() => {
      appStore.dispatch(clearNotificationAction(message))
    }, timeout)
  }
}

const showInfo = ({
  message, ok, okText, cancel, cancelText, timeout = 5000,
}) => {
  appStore.dispatch(notificationAction({
    type: NOTIFICATION_TYPES.INFORMATION,
    name: message,
    message: i18n.t(message),
    ok: () => { appStore.dispatch(clearNotificationAction(message)); if (ok) ok() },
    cancel: () => { appStore.dispatch(clearNotificationAction(message)); if (cancel) cancel() },
    okText: i18n.t(okText),
    cancelText: i18n.t(cancelText),
  }))
  if (timeout !== -1) {
    setTimeout(() => {
      appStore.dispatch(clearNotificationAction(message))
    }, timeout)
  }
}


const showError = ({ message, timeout = 5000, ok }) => {
  appStore.dispatch(notificationAction({
    type: NOTIFICATION_TYPES.ERROR,
    name: message,
    message: i18n.t(message),
    ok: () => { appStore.dispatch(clearNotificationAction(message)); if (ok) ok() },
    cancel: () => { appStore.dispatch(clearNotificationAction(message)) },
  }))
  if (timeout !== -1) {
    setTimeout(() => {
      appStore.dispatch(clearNotificationAction(message))
    }, timeout)
  }
}

const clearNotification = ({ message, timeout }) => {
  if (timeout && timeout !== -1) {
    setTimeout(() => {
      appStore.dispatch(clearNotificationAction(message))
    }, timeout)
  } else {
    appStore.dispatch(clearNotificationAction(message))
  }
}

export {
  askConfirmation,
  showInfo,
  showError,
  clearNotification,
}
