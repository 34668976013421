import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import CachedIcon from '@material-ui/icons/Cached'
import { showError, showInfo } from '../services/notifications'
import { isEmpty } from '../utils'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  heading: {
    top: theme.typography.pxToRem(15),
    position: 'relative',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
    paddingLeft: theme.spacing(1),
  },
  iconButtonLabelTxt: {
    fontSize: 18,
    paddingLeft: '0.5rem',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  form: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  inputNum: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  button: {
    margin: theme.spacing(1),
  },
  info: {
    margin: theme.spacing(1),
    marginBottom: 0,
    fontWeight: 500,
  },
  note: {
    fontSize: '.9em',
    margin: theme.spacing(1),
    color: '#333',
  },
  progress: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
  },
  progress2: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    position: 'relative',
    top: 15,
  },
  alert: {
    margin: theme.spacing(1),
    color: 'red',
  },
  hr: {
    color: '#666',
    width: '100%',
  },
}))


export default function ZWaveConfiguration(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [nodeTestingOngoing, setNodeTestingOngoing] = useState([])
  const [nodeRemoveOngoing, setNodeRemoveOngoing] = useState([])
  const [nodeParameterOngoing, setNodeParameterOngoing] = useState([])
  const [selectedNode, setSelectedNode] = useState()
  const [paramNumber, setParamNumber] = useState()
  const [paramValue, setParamValue] = useState({})
  const {
    devices, rooms, nodes, cozifyHubId,
    handleStartZWaveNetworkDetails,
    handleZWaveConfigurationDone,
    checkIsFailedZWaveNode,
    removeFailedZWaveNode,
    getZWaveNodeParameter,
    setZWaveNodeParameter,
    handleDeviceUnPair,
  } = props


  useEffect(() => {
    console.log('ZWaveConfiguration EFFECT', props)
    if (devices && rooms) {
      const deviceArr = Object.values(devices)
      deviceArr.map((device) => {
        devices[device.id].roomName = ''
        if (!isEmpty(device.room && rooms[device.room[0]] && rooms[device.room[0]].name)) {
          devices[device.id].roomName = rooms[device.room[0]].name
        }
        return true
      })
    }
  })

  const handleCheckIsFailedZWaveNode = async (node) => {
    try {
      const reply = await checkIsFailedZWaveNode(cozifyHubId, node.nodeId)
      setNodeTestingOngoing(nodeTestingOngoing.filter((item) => item !== node.nodeId))
      if (reply) {
        // eslint-disable-next-line no-param-reassign
        node.isFailed = true
        showError({ message: 'Z-wave node is Failed' })
      } else {
        // eslint-disable-next-line no-param-reassign
        node.isFailed = false
        showInfo({ message: 'Z-wave node is not Failed' })
      }
    } catch (error) {
      setNodeTestingOngoing(nodeTestingOngoing.filter((item) => item !== node.nodeId))
      showError({ message: 'Z-wave node test failed' })
    }
  }

  const handleRemoveZWaveNode = async (node) => {
    try {
      const reply = await removeFailedZWaveNode(cozifyHubId, node.nodeId)
      setNodeRemoveOngoing(nodeRemoveOngoing.filter((item) => item !== node.nodeId))
      if (reply) {
        showInfo({ message: 'Z-wave node removed' })
      } else {
        showError({ message: 'Z-wave node remove failed' })
      }
      handleStartZWaveNetworkDetails()
    } catch (error) {
      setNodeRemoveOngoing(nodeRemoveOngoing.filter((item) => item !== node.nodeId))
      showError({ message: 'Z-wave node remove failed' })
    }
  }

  const handleTest = async (event) => {
    event.preventDefault()
    nodes.map((node) => {
      if (selectedNode === node.nodeId) {
        setNodeTestingOngoing((nodesAll) => [...nodesAll, node.nodeId])
        handleCheckIsFailedZWaveNode(node)
      }
      return true
    })
    // handleZWaveConfigurationDone()
  }


  const handleRemove = async (event) => {
    event.preventDefault()
    nodes.map((node) => {
      if (selectedNode === node.nodeId) {
        setNodeRemoveOngoing((nodesAll) => [...nodesAll, node.nodeId])
        handleRemoveZWaveNode(node)
      }
      return true
    })
    // handleZWaveConfigurationDone()
  }

  const handleCancel = () => {
    handleZWaveConfigurationDone()
  }

  const handleChange = (event) => {
    setSelectedNode(event.target.value)
    setParamValue({})
    setReadyToSubmit(true)
  }

  const handleParamNumberChange = (event) => {
    setParamNumber(event.target.value)
  }

  const handleParamValueChange = (paramData, value) => {
    const newVal = { ...paramValue }
    newVal[paramData.parameter] = value
    setParamValue(newVal)
  }

  const handleGetParameter = async (paramNo) => {
    setNodeParameterOngoing((nodesAll) => [...nodesAll, selectedNode])
    try {
      const reply = await getZWaveNodeParameter(cozifyHubId, selectedNode, Number(paramNo))
      setNodeParameterOngoing(nodeParameterOngoing.filter((item) => item !== selectedNode))
      if (reply.nodeId && reply.parameter && reply.value !== null) {
        nodes.map((node) => {
          if (node.nodeId === reply.nodeId) {
            // eslint-disable-next-line no-param-reassign
            node.parameters[`${reply.parameter}`] = reply
            const newVal = { ...paramValue }
            newVal[reply.parameter] = reply.value
            setParamValue(newVal)
          }
          return true
        })
        setParamNumber('')
      } else {
        showError({ message: 'Node parameter request failed' })
      }
    } catch (error) {
      setNodeParameterOngoing(nodeParameterOngoing.filter((item) => item !== selectedNode))
      showError({ message: 'Node parameter request failed' })
    }
  }
  const handleUnpair = async (node) => {
    setNodeRemoveOngoing((nodesAll) => [...nodesAll, node.nodeId])
    await handleDeviceUnPair(devices[node.deviceId], handleStartZWaveNetworkDetails)
    setNodeRemoveOngoing(nodeRemoveOngoing.filter((item) => item !== node.nodeId))
  }

  const handleSetParameter = async (node, param, def) => {
    setNodeParameterOngoing((nodesAll) => [...nodesAll, selectedNode])
    let val = node.parameters[param].value
    if (paramValue[param]) {
      val = paramValue[param]
    }
    let size = 0
    size = node.parameters[param].size
    try {
      await setZWaveNodeParameter(cozifyHubId, node.nodeId, Number(param), Number(size), def, Number(val))
      setNodeParameterOngoing(nodeParameterOngoing.filter((item) => item !== selectedNode))
      if (def) {
        setParamNumber(Number(param))
        handleGetParameter(param)
      }
    } catch (error) {
      setNodeParameterOngoing(nodeParameterOngoing.filter((item) => item !== selectedNode))
      showError({ message: 'Node parameter set failed' })
    }
  }

  return (
    <div className={classes.container}>

      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={1} direction="row" justify="center">


          <Grid item xs={12}>
            <IconButton
              edge="end"
              aria-label={t('Refresh')}
              classes={{ label: classes.iconButtonLabel }}
              onClick={handleStartZWaveNetworkDetails}
              className={classes.menuIcon}
            >
              <CachedIcon />
              <div className={classes.iconButtonLabelTxt}>
                {t('Refresh')}
              </div>
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption" className={classes.note} gutterBottom>
              {t('Z-Wave Configurationg intro')}
            </Typography>
          </Grid>


          <Grid item xs={12}>
            <Grid container spacing={1} direction="row" justify="left">

              <Grid item xs={12} sm={6} md={4} lg={3}>

                <RadioGroup aria-label="nodes" name="nodes" value={selectedNode} onChange={handleChange}>

                  {nodes.map((node) => (
                    <Paper key={node.nodeId} className={classes.paper}>
                      <FormControlLabel
                        control={(
                          <Radio
                            value={node.nodeId}
                            color="primary"
                            className={classes.checkbox}
                          />
                        )}
                        label={node.deviceId && devices[node.deviceId] ? `${devices[node.deviceId].roomName} ${devices[node.deviceId].name}` : `${node.genericClassName} - ${node.specificClassName}`}
                      />

                      <div>
                        <Typography variant="caption" component="span" className={classes.note}>
                          {`${t('Node id')}: ${node.nodeId}`}
                        </Typography>
                        <Typography variant="caption" component="span" className={classes.note}>
                          {`${t('Status')}: ${node.status}`}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="caption" component="span" className={classes.note}>
                          {`${t('Listening')}: ${node.listening}`}
                        </Typography>
                        <Typography variant="caption" component="span" className={classes.note}>
                          {`${t('Security')}: ${node.securityKeys}`}
                        </Typography>
                      </div>
                      {node.isFailed && (
                        <div>
                          <Typography variant="caption" component="span" className={classes.alert}>
                            {`${t('Z-wave node is Failed')}`}
                          </Typography>
                        </div>
                      )}

                      {selectedNode === node.nodeId && Object.keys(node.parameters).length > 0 && (
                        <Grid container spacing={1} direction="row" justify="left">
                          <Grid item xs={12}>
                            <Typography className={classes.info} variant="body2" gutterBottom>
                              {`${t('Configuration paramameters')}:`}
                            </Typography>
                            <Typography variant="caption" component="span" className={classes.note}>
                              {t('Set configuration paramameter instruction')}
                            </Typography>
                          </Grid>

                        </Grid>
                      )}

                      {selectedNode === node.nodeId && Object.keys(node.parameters).length > 0 && Object.entries(node.parameters).map((param) => (

                        <Grid key={param[0]} container spacing={1} direction="row" justify="left">

                          <Grid item xs={12}>
                            <Typography className={classes.heading}>
                              { `Param: ${param[1].parameter}, size ${param[1].size} [byte]` }
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id={`params-${node.nodeId}`}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              onChange={(event) => { handleParamValueChange(param[1], event.target.value) }}
                              type="number"
                              autoFocus
                              value={paramValue[param[0]]}
                              placeholder={t('Parameter')}
                              className={classes.inputNum}
                              inputProps={{
                                'aria-label': t('Parameter'),
                              }}
                            />
                          </Grid>
                          {!isEmpty(nodeParameterOngoing) && (
                            <Grid item xs={6}>
                              <CircularProgress
                                className={classes.progress2}
                                disableShrink
                                size={16}
                                thickness={4}
                                color="primary"
                                variant="indeterminate"
                              />
                            </Grid>
                          )}
                          {isEmpty(nodeParameterOngoing) && (
                            <Grid item xs={3}>
                              <Button size="small" color="primary" type="button" onClick={(e) => { handleSetParameter(node, param[1].parameter, false) }} variant="contained" className={classes.button}>{t('Ok')}</Button>
                            </Grid>
                          )}
                          {isEmpty(nodeParameterOngoing) && (
                            <Grid item xs={3}>
                              <Button size="small" color="secondary" type="button" onClick={(e) => { handleSetParameter(node, param[1].parameter, true) }} variant="contained" className={classes.button}>{t('Reset')}</Button>
                            </Grid>
                          )}
                        </Grid>

                      ))}

                      {selectedNode === node.nodeId && (
                        <Grid container spacing={1} direction="row" justify="left">
                          <Grid item xs={12}>
                            <Typography className={classes.info} variant="body2">
                              {`${t('Get configuration paramameter')}:`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption" component="span" className={classes.note}>
                              {`${t('Get configuration paramameter instruction')}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id={`params-${node.nodeId}`}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              onChange={handleParamNumberChange}
                              type="number"
                              autoFocus
                              value={paramNumber || ''}
                              placeholder={t('Parameter')}
                              className={classes.inputNum}
                              inputProps={{
                                'aria-label': t('Parameter'),
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            {isEmpty(nodeParameterOngoing) && (<Button size="small" color="primary" type="button" onClick={() => handleGetParameter(paramNumber)} variant="contained" className={classes.button}>{t('Ok')}</Button>)}
                            {!isEmpty(nodeParameterOngoing) && (
                              <CircularProgress
                                className={classes.progress2}
                                disableShrink
                                size={16}
                                thickness={4}
                                color="primary"
                                variant="indeterminate"
                              />
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            &nbsp;
                          </Grid>
                          <hr className={classes.hr} />

                          <Grid item xs={12}>
                            {(!isEmpty(nodeTestingOngoing) || !isEmpty(nodeRemoveOngoing))
                            && (
                              <CircularProgress
                                className={classes.progress}
                                disableShrink
                                size={16}
                                thickness={4}
                                color="primary"
                                variant="indeterminate"
                              />
                            )}

                            {(isEmpty(nodeTestingOngoing) && isEmpty(nodeRemoveOngoing))
                            && (
                              <Typography variant="caption" className={classes.info} gutterBottom>
                                {t('Z-Wave Configurationg intro 2')}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Button size="small" id="test" color="primary" disabled={!readyToSubmit || !isEmpty(nodeTestingOngoing)} type="button" variant="contained" onClick={handleTest} className={classes.button}>{t('Test')}</Button>
                            <Button size="small" id="remove" color="secondary" disabled={!readyToSubmit || !isEmpty(nodeRemoveOngoing)} type="button" onClick={handleRemove} variant="contained" className={classes.button}>{t('Remove if failed')}</Button>
                            {devices[node.deviceId] && (
                              <Button size="small" id="remove" color="secondary" disabled={!readyToSubmit || !isEmpty(nodeRemoveOngoing)} type="button" onClick={() => handleUnpair(node)} variant="contained" className={classes.button}>{t('Remove device')}</Button>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Paper>
                  ))}
                </RadioGroup>
                {/* <Grid item xs={12}>
                      <Typography className={classes.heading}>
                        {`${JSON.stringify(node)}`}
                      </Typography>
                    </Grid>
                      */}
                <Grid item xs={12}>
                  <Button size="small" color="primary" type="button" variant="contained" onClick={handleCancel} className={classes.button}>{t('Back')}</Button>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

        </Grid>
      </form>
    </div>
  )
}
